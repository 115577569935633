<template>
  <div>
     
      
        <swiper :options="swiperOption">
        <swiper-slide >
             <div class="bg-slide swiper-lazy" style="background:url('@/assets/fotos/img-slide-1.jpg') no-repeat center top">
               <div class="container">
               

                
               <div class="text-slide-l1 slide-in-bottom">
                  <!-- <span class="slide-in-bottom">
                    {{$t('slides.operador')}}
                  </span><br> -->
                  <br>
                  <span class="slide-in-bottom">
                    {{$t('slides.foco')}}
                  </span>
                   
                 </div>
                 <div class="slide-cuadro">
                 <div class="text-slide-cuadro-amarillo  slide-in-bottom-1">
                    {{$t('slides.estandares')}}
                    <span></span>
                 </div>   
                <div class="text-slide-cuadro-naranja  slide-in-bottom-2">
                   <strong>+160.000 m<strong class='supidc1'>2</strong> </strong>  {{$t('slides.metros')}}
                    <!-- <span></span> -->
                   
                    <span></span>
                 </div>     
                 </div>
               
             
               </div>
            
                
             </div>
                  

        </swiper-slide>
        <swiper-slide >
             <div class="bg-slide swiper-lazy" :style="'background:url(' + img2 + ') no-repeat  center top'">
                     <div class="slide-cuadro-2 slide-in-bottom">
               
                <div class="text-slide-cuadro-naranja">
                   {{$t('slides.buscamos')}}
                    <span></span>
                 </div>     
                 </div>
             </div>
                  

        </swiper-slide>
         <swiper-slide >
             <div class="bg-slide swiper-lazy" :style="'background:url(' + img3 + ') no-repeat top center'">
                      <div class="slide-cuadro-2 slide-in-bottom-2">
               
                <div class="text-slide-cuadro-naranja">
                  {{$t('slides.sectores')}}
                    <span></span>
                 </div>     
                 </div>
             </div>
                  

        </swiper-slide>
         <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev swiper-button-white hidden-xs-only" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-white hidden-xs-only" slot="button-next"></div>
      </swiper>
  </div>
</template>

<script>

import img1 from '@/assets/fotos/img-slide-1.jpg'
import img2 from '@/assets/fotos/img-slide-2.jpg'
import img3 from '@/assets/fotos/img-slide-3.jpg'
export default {
  components: {
 
  },
   data() {
      return {
          img1:img1,
          img2:img2,
          img3:img3,
        swiperOption: {
           
          spaceBetween: 30,
          centeredSlides: true,
          effect: 'fade',
            loop: true,
            watchOverflow: true,
            // speed: 500,
          autoplay: {
            delay: 15000,
            disableOnInteraction: false
          },
            pagination: {
            el: '.swiper-pagination',
             clickable: true
          },
            navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        }
      }
    }
}
</script>

<style lang="scss">

.supidc1 { 
    vertical-align:super;            /*superindice*/ 
    font-size:16px;
} 
.swiper-pagination-bullet-active {
  background-color: #FFF !important;
}

.bg-slide{
    width: 100%;
    background-size: cover !important;
    height: calc(100vh - 100px);
    margin-top: 0px;
    background-repeat: no-repeat;
    z-index: 1;
    position: relative;
    background:url('~@/assets/fotos/base2.jpg') no-repeat  center top
      // background-attachment: fixed;

}
.text-slide-l1{
 
  position: absolute;
  bottom: 10px;
  color:white;
  font-size: 2.5vw;
  // text-transform: uppercase;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 80px;
  margin-left: 20px;
   


}
.text-slide-l1 span:after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: $naranja;
  transform-origin: bottom left;
  -ms-transform: skew(-20deg, 0deg);
  -webkit-transform: skew(-20deg, 0deg);
  transform: skew(-20deg, 0deg);
  border-radius: 4px;
   padding: 0px 5px;
}

.text-slide-l1 span{
   background: $naranja;
   border-radius: 4px;
  padding: 10px 15px;

  position: relative;
  color: #FFF;
 


}

.slide-cuadro{
  position: absolute;
  top: 29.5%;
  right: 8%;
}
.slide-cuadro-2{
 position: absolute;
  top: 53%;
  right: 8%;
}
.text-slide-cuadro-amarillo{
 
   background: $amarillo;
   border-radius: 4px;
  padding: 30px 30px;
   width: 300px;
 position: relative;
  color: #FFF;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 26px;
  border-bottom-left-radius: 0px;



}
.text-slide-cuadro-amarillo span{
  display: block;
  border-bottom: 3px solid $naranja;
  width:80px;
  margin-top: 20px;
} 
.text-slide-cuadro-amarillo:after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: $amarillo;
  transform-origin: bottom left;
  -ms-transform: skew(-20deg, 0deg);
  -webkit-transform: skew(-20deg, 0deg);
  transform: skew(-20deg, 0deg);
  border-radius: 4px;
   padding: 0px 5px;
}

.text-slide-cuadro-naranja{
 
   background: $naranja;
   border-radius: 4px;
  padding: 30px 40px;
   width: 460px;
   position: relative;
  color: #FFF;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 26px;
 border-top-left-radius: 0px;


}
.text-slide-cuadro-naranja span{
  display: block;
  border-bottom: 3px solid $amarillo;
  width:80px;
  margin-top: 20px;
} 
.text-slide-cuadro-naranja:after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: $naranja;
  transform-origin: bottom left;
  -ms-transform: skew(-20deg, 0deg);
  -webkit-transform: skew(-20deg, 0deg);
  transform: skew(-20deg, 0deg);
  border-radius: 4px;
   padding: 0px 5px;
}

@media screen and (max-width: 1440px) {

.supidc1 { 
    vertical-align:super;            /*superindice*/ 
    font-size:14px;
} 
  .text-slide-l1{
   font-size: 34px;
   line-height: 1.9;
   margin-bottom: 80px;
}

.text-slide-cuadro-amarillo{
  padding: 30px 40px;
   width: 210px;
  font-size: 20px;
}
.slide-cuadro{
  position: absolute;
  top: 25.5%;
  right: 8%;
}
.slide-cuadro-2{
 position: absolute;
  top: 48%;
  right: 8%;
}
.text-slide-cuadro-naranja{
  padding: 30px 40px;
   width: 360px;
  font-size: 20px;
}


}

@media screen and (max-width: 992px) {
  .supidc1 { 
    vertical-align:super;            /*superindice*/ 
    font-size:16px;
} 
  .text-slide-l1{
   font-size:30px;
   line-height: 2.1;
   bottom: 50px;
}
.slide-cuadro{
  position: absolute;
  top: 35%;
  right: 8%;
}
.text-slide-cuadro-amarillo{
  padding: 20px 20px;
   width: 180px;
  font-size: 18px;
}

.text-slide-cuadro-naranja{
  padding: 20px 20px;
   width: 320px;
  font-size: 18px;
}

}

@media screen and (max-width: 768px) {
  .supidc1 { 
    vertical-align:super;            /*superindice*/ 
    font-size:12px;
} 
.bg-slide{
    
    height: calc(100vh - 40px);
  

}
  .text-slide-l1{
   font-size:18px;
   line-height: 2.5;
   bottom: -20px;
   margin-left: 0px;
   left: 0;
}
.text-slide-l1 span{
  padding: 10px 3px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.slide-cuadro{
  position: absolute;
  top: 28%;
  right: 8%;
}
.slide-cuadro-2{
  position: absolute;
  top: 55%;
  right: 8%;
}
.text-slide-cuadro-amarillo{
  padding:15px;
   width: 50%;
  font-size: 16px;
}

.text-slide-cuadro-naranja{
  padding:15px;
   width: 70%;
  font-size: 16px;
}
 
}
@media (max-width: 330px) and (max-height: 620px) {
  .supidc1 { 
    vertical-align:super;            /*superindice*/ 
    font-size:10px;
} 
  .bg-slide{
    
    height: calc(100vh - 40px);
  

}
  .text-slide-l1{
   font-size:18px;
   line-height: 2.5;
   bottom: -40px;
   margin-left: 0px;
   left: 0;
}
.text-slide-l1 span{
  padding: 10px 3px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.slide-cuadro{
  position: absolute;
  top: 28%;
  right: 8%;
}
.slide-cuadro-2{
  position: absolute;
  top: 55%;
  right: 8%;
}
.text-slide-cuadro-amarillo{
  padding:10px;
   width: 50%;
  font-size: 14px;
}

.text-slide-cuadro-naranja{
  padding:10px;
   width: 70%;
  font-size: 14px;
}
}
@media (max-width: 320px) and (max-height: 480px) {
  .supidc1 { 
    vertical-align:super;            /*superindice*/ 
    font-size:10px;
} 
  .bg-slide{
    
    height: calc(100vh - 20px);
  

}
  .text-slide-l1{
   font-size:18px;
   line-height: 2.5;
   bottom: -40px;
   margin-left: 0px;
   left: 0;
}
.text-slide-l1 span{
  padding: 10px 3px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.slide-cuadro{
  position: absolute;
  top: 18%;
  right: 8%;
}
.slide-cuadro-2{
  position: absolute;
  top: 45%;
  right: 8%;
}
.text-slide-cuadro-amarillo{
  padding:10px;
   width: 50%;
  font-size: 14px;
}

.text-slide-cuadro-naranja{
  padding:10px;
   width: 70%;
  font-size: 14px;
}
}
@media	only screen and (min-resolution: 120dpi) {
  .text-slide-l1{
  font-size: 18px;
  line-height: 2.6;
}

}
.slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-in-bottom-1 {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.8s both;
	animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.8s both;
}
.slide-in-bottom-2 {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.2s both;
	animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.2s both;
}
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

</style>