<template>
  <div style="position:relative">
    <div class="container log-contacto w80-2">
      <h1>
        {{ $t('contacto.linea1') }}
        <br />
        <span>{{ $t('contacto.linea2') }}</span>
      </h1>
      <p>{{ $t('contacto.linea3') }}</p>
      <p v-html="$t('contacto.intro')"></p>

      <el-row class="log-form">
        <el-col :xs="24" class="hidden-sm-and-up">
          <div class="enviado" v-if="tipo === 'enviado'">
            <img src="@/assets/SVG/logo.svg" class="enviado-img" alt="Login Farma" />
            <h2>
              {{ $t('contacto.form.enviado_l1') }}
              <br />
              <small>{{ $t('contacto.form.enviado_l2') }}</small>
            </h2>
            <a href="https://www.linkedin.com/company/loginfarma/" target="_blank" class="menu-boton-mobile-mail">
              <img src="@/assets/SVG/ig.svg" alt="instagram Login Farma" />
            </a>
            <a href="https://www.linkedin.com/company/loginfarma/" target="_blank" class="menu-boton-mobile-mail">
              <img src="@/assets/SVG/in.svg" alt="linkedin Login Farma" />
            </a>
            <a href="https://www.linkedin.com/company/loginfarma/" target="_blank" class="menu-boton-mobile-mail">
              <img src="@/assets/SVG/yt.svg" alt="youtube Login Farma" />
            </a>

            <h4>{{ $t('contacto.form.enviado_l3') }}</h4>
          </div>
          <span class="separador-form"></span>
          <h2 style="font-size:30px; margin:0">{{ $t('contacto.form.titulo_mensaje') }}</h2>

          <span class="separador-form"></span>
          <el-form :model="form" ref="form" :label-position="'top'" v-if="tipo !== 'enviado'">
            <el-form-item prop="nombre" :label="$t('contacto.form.nombre')" :rules="[
          { required: true, message: $t('contacto.form.require'), trigger: 'blur' }
        ]">
              <el-input v-model="form.nombre"></el-input>
            </el-form-item>
            <el-form-item prop="email" :label="$t('contacto.form.email')" :rules="[
          { required: true, message: $t('contacto.form.require'), trigger: 'blur' },
          { type: 'email', message: $t('contacto.form.email_format'), trigger: ['blur', 'change'] }
        ]">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item>
              <h2>
                {{ $t('contacto.form.titulo_cv') }}
                <br />
                {{ $t('contacto.form.subtitulo_cv') }}
              </h2>
              <el-upload class="upload-demo" action="https://caba33.suizoargentina.com.ar/logisticaWeb/uploadCv"
                :on-preview="handlePreview" :before-upload="beforeUpload" :limit="1" :on-exceed="handleExceed"
                :auto-upload="false" :on-success="enviarMailCv" ref="uploadCv">
                <el-button type="warning">{{ $t('contacto.form.archivo') }}</el-button>
                <div slot="tip" class="el-upload__tip">{{ $t('contacto.form.archivo_tipo') }}</div>
              </el-upload>
            </el-form-item>
            <el-form-item v-if="tipo === 'mensaje'" prop="mensaje" :label="$t('contacto.form.mensaje')">
              <el-input type="textarea" :rows="10" v-model="form.mensaje"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="warning" class="boton" :loading="procesando"
                @click="submitForm('form')">{{ $t('contacto.form.boton') }}</el-button>
            </el-form-item>
          </el-form>
          <span class="separador-form"></span>
          <h3>
            <!-- <strong>{{$t('contacto.direccion.sede')}}</strong> -->
          </h3>
          <h3>{{ $t('contacto.direccion.linea1') }} {{ $t('contacto.direccion.linea2') }}
            {{ $t('contacto.direccion.linea3') }}</h3>
          <h3>
            <img src="@/assets/SVG/icon-tel.svg" alt="tel Login Farma" />
            {{ $t('contacto.direccion.telefono') }}
          </h3>
          <h3>
            <img src="@/assets/SVG/icon-mail.svg" style="height:15px" alt="mail Login Farma" />
            {{ $t('contacto.direccion.mail') }}
          </h3>
        </el-col>
        <el-col :md="10" :sm="10" class="hidden-xs-only">
          <h2 @click="tipo = 'mensaje'">
            {{ $t('contacto.form.titulo_mensaje') }}
            <i :class="{ 'color-azul': tipo === 'mensaje', 'color-blanco': tipo === 'cv' }"
              class="el-icon-caret-right"></i>
          </h2>
          <h2 @click="tipo = 'cv'">
            {{ $t('contacto.form.titulo_cv') }}
            <i :class="{ 'color-azul': tipo === 'cv', 'color-blanco': tipo === 'mensaje' }"
              class="el-icon-caret-right"></i>
            <br />
            <span>{{ $t('contacto.form.subtitulo_cv') }}</span>
          </h2>
          <span class="separador-form"></span>
          <h3 style="margin-top:130px">
            <!-- <strong>{{$t('contacto.direccion.sede')}}</strong> -->
          </h3>
          <h3>{{ $t('contacto.direccion.linea1') }}</h3>
          <h3>{{ $t('contacto.direccion.linea2') }}</h3>
          <h3>{{ $t('contacto.direccion.linea3') }}</h3>

          <h3>
            <img src="@/assets/SVG/icon-mail.svg" style="height:15px" alt="mail Login Farma" />
            {{ $t('contacto.direccion.mail') }}
          </h3>



          <a href="https://wa.me/5491128230307" target="_blank" rel="noopener noreferrer"> <img
              src="@/assets/SVG/ws.svg" style="height:20px; margin-top:1px" alt="Phone Login Farma" />{{
          $t('contacto.direccion.phone') }}</a>



        </el-col>
        <el-col :md="{ span: 10, offset: 2 }" :sm="{ span: 10, offset: 2 }" class="hidden-xs-only">
          <div class="enviado" v-if="tipo === 'enviado'">
            <img src="@/assets/SVG/logo.svg" class="enviado-img" alt="Login Farma" />
            <h2>
              {{ $t('contacto.form.enviado_l1') }}
              <br />
              <small>{{ $t('contacto.form.enviado_l2') }}</small>
            </h2>
            <a href="https://www.linkedin.com/company/loginfarma/" target="_blank" class="menu-boton-mobile-mail">
              <img src="@/assets/SVG/ig.svg" alt="instagram Login Farma" />
            </a>
            <a href="https://www.linkedin.com/company/loginfarma/" target="_blank" class="menu-boton-mobile-mail">
              <img src="@/assets/SVG/in.svg" alt="linkedin Login Farma" />
            </a>
            <a href="https://www.linkedin.com/company/loginfarma/" target="_blank" class="menu-boton-mobile-mail">
              <img src="@/assets/SVG/yt.svg" alt="youtube Login Farma" />
            </a>

            <h4>{{ $t('contacto.form.enviado_l3') }}</h4>
          </div>
          <el-form :model="form" ref="form" :label-position="'top'" v-if="tipo !== 'enviado'">
            <el-form-item prop="nombre" :label="$t('contacto.form.nombre')" :rules="[
          { required: true, message: $t('contacto.form.require'), trigger: 'blur' }
        ]">
              <el-input v-model="form.nombre"></el-input>
            </el-form-item>
            <el-form-item prop="email" :label="$t('contacto.form.email')" :rules="[
          { required: true, message: $t('contacto.form.require'), trigger: 'blur' },
          { type: 'email', message: $t('contacto.form.email_format'), trigger: ['blur', 'change'] }
        ]">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item>
              <el-upload v-if="tipo === 'cv'" class="upload-demo"
                action="https://caba33.suizoargentina.com.ar/logisticaWeb/uploadCv" :on-preview="handlePreview"
                :before-upload="beforeUpload" :limit="1" :on-exceed="handleExceed" :auto-upload="false" ref="uploadCv"
                :on-success="enviarMailCv">
                <el-button type="warning">{{ $t('contacto.form.archivo') }}</el-button>
                <div slot="tip" class="el-upload__tip">{{ $t('contacto.form.archivo_tipo') }}</div>
              </el-upload>
            </el-form-item>
            <el-form-item v-if="tipo === 'mensaje'" prop="mensaje" :label="$t('contacto.form.mensaje')" :rules="[
          { required: true, message: $t('contacto.form.require'), trigger: 'blur' }
        ]">
              <el-input type="textarea" :rows="10" v-model="form.mensaje"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="warning" class="boton" :loading="procesando"
                @click="submitForm('form')">{{ $t('contacto.form.boton') }}</el-button>
            </el-form-item>
          </el-form>
          <!-- <img src="@/assets/SVG/logo.svg" class="footer-logo" alt="logo-footer" /> -->
        </el-col>
      </el-row>
    </div>

    <!-- <div class="footer-lateral hidden-xs-only"></div> -->
    <div class="footer-img">
      <!-- <img src="@/assets/SVG/footer-img.svg" alt="logo-footer" /> -->
      <img src="@/assets/SVG/logo.svg" alt="Login Farma" />
      <br />
      <h3>LOG-IN FARMA S.R.L © - {{ new Date().getFullYear() }}

        <br>
        <small>
          <a href="https://log-in.com.ar/sala-de-prensa/" :class="{ 'menuActivo': menuActivo == 'sala-de-prensa' }"
            target="_blank">{{ $t('header.prensaFooter') }}</a>

        </small>

      </h3>


    </div>
  </div>
</template>
<!-- Global site tag (gtag.js) - Google Ads: 614534980 -->
<script async src="https://www.googletagmanager.com/gtag/js?id=AW-614534980"></script>
<script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-614534980'); </script>
<script>
import { HTTP } from "@/axios.js";
export default {
  data() {
    return {
      form: {
        email: "",
        nombre: "",
        mensaje: ""
      },
      tipo: "mensaje",
      procesando: false,
      menuActivo:''
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `The limit is 1, you selected ${
          files.length
        } files this time, add up to ${files.length + fileList.length} totally`
      );
    },
    beforeUpload(file) {
      const isPdf = file.type === "application/pdf";
      const isDoc = file.type === "application/msword";
      const isDocx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      const isLt1M = file.size / 1024 / 1024 < 1;
      console.log(file.type);
      console.log(isPdf);
      console.log(isDoc);
      console.log(isDocx);
      console.log(isLt1M);

      if (!isPdf && !isDoc && !isDocx) {
        // alert('Avatar picture must be JPG format!');
        this.$message.error(this.$t("contacto.form.archivo_tipo"));
      }
      if (!isLt1M) {
        this.$message.error(this.$t("contacto.form.archivo_tipo"));
      }
      return (isPdf || isDoc || isDocx) && isLt1M;
    },

    enviarMailCv(response, file, fileList) {
      let url = response.url;
      let cvMsj =
        'Puede descargar el CV haciendo click: <a href="' +
        url +
        '" >' +
        url +
        "</a> ";

      HTTP.post(
        "/enviarMail",
        null,
        {
          params: {
            tipo: this.tipo,
            nombre: this.form.nombre,
            correo: this.form.email,
            mensaje: cvMsj
          }
        },
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      ).then(res => {
        this.form = {};
        this.tipo = "enviado";
        this.procesando = false;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.procesando = true;

          if (this.tipo === "cv") {
            this.$refs.uploadCv.submit();
          } else {
            HTTP.post(
              "/enviarMail",
              null,
              {
                params: {
                  tipo: this.tipo,
                  nombre: this.form.nombre,
                  correo: this.form.email,
                  mensaje: this.form.mensaje
                }
              },
              {
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
              }
            ).then(res => {
                this.$gtm.trackEvent({
                // event: null, // Event type [default = 'interaction'] (Optional)
                category: 'Contacto',
                action: 'click',
                label: 'Mensaje enviado',
                value: 5000,
                // noninteraction: false // Optional
              });
              this.form = {};
              this.tipo = "enviado";
              this.procesando = false;
            });
          }

          // console.log("recaptcha clicked");
          // this.$recaptchaLoaded().then(() => {
          //   console.log("recaptcha loaded");
          //   this.$recaptcha("log-in-contacto").then(token => {
          //     console.log(token); // Will print the token
          //   });
          // });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    removeDomain(item) {
      var index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
    },
    addDomain() {
      this.dynamicValidateForm.domains.push({
        key: Date.now(),
        value: ""
      });
    }
  }
};
</script>
<style scoped>
.grecaptcha-badge {
  display: block !important;
}
</style>
<style lang="scss">
.enviado {
  padding: 30px;
  background: #f1f1f1;
  margin: 10px auto;
  text-align: center;
}

.enviado h2 {
  text-align: center !important;
}

.enviado-img {
  height: 100px;
  display: block;
  margin: 20px auto;
}

.footer-logo {
  // position: absolute;
  height: 120px;
  // right: 10%;
  float: right;
}

.footer-lateral {
  background: $naranja;
  height: 900px;
  position: absolute;
  width: 30px;
  top: 0;
  right: 0;
}

.footer-img {
  text-align: center;
  background: $amarillo;
  font-family: "Roboto";
  font-weight: 100 !important;
  color: $naranja;
  padding: 10px;
}

.footer-img img {
  display: block;
  height: 80px;
  margin: 0 auto;
  margin-top: 20px;
}

.color-azul {
  color: $azul !important;
}

.color-blanco {
  color: white !important;
}

.log-form form {
  height: 650px;
}

.log-form h2 {
  font-weight: 500;
  margin-bottom: 40px;
  text-align: right;
  cursor: pointer;
}

.log-form h3 {
  font-weight: 300;
  margin: 0px;
  text-align: right;
  cursor: pointer;
  line-height: 1.3;
  padding-right: 35px;
  font-size: 20px;
}

.log-form a {
  font-weight: 300;
  margin: 0px;
  text-align: right;
  cursor: pointer;
  line-height: 1.3;
  padding-right: 35px;
  font-size: 20px;
  float: right;
  text-decoration: none;
}

.log-form h3>img {
  height: 20px;
}

.log-form h2>span {
  padding-right: 35px;
}

.separador-form {
  border-bottom: 2px solid $naranja;
  margin: 80px auto;
  display: block;
}

.w80-2 {
  width: 80%;
}

.log-contacto {
  font-family: "Roboto";
  color: $azul;
  max-width: 1170px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}

.log-contacto h1 {
  text-align: left;
  margin-top: 200px;
  font-size: 50px;
}

.log-contacto p {
  font-size: 18px;
}

.log-contacto h1>span {
  padding-left: 20px;
}

.punto {
  background: $naranja !important;
  width: 10px;
  height: 10px;

  display: inline-block;
}

.log-form {
  margin-top: 80px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0px !important;
  color: $azul;
  font-size: 22px;
  font-weight: bold;
}

.el-input__inner {

  background-color: #e6e6e6;
  background-image: none;
  border-radius: 2px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: $azul;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

.el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  color: $azul;
  background-color: #e6e6e6;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.el-button--warning {
  color: #fff;
  background-color: $naranja !important;
  border-color: $naranja !important;
}

.boton {
  width: 50%;
  // float:right;
}

.menu-boton-mobile-mail img {
  width: 20%;
  padding: 10px;
  display: inline-block;
  text-align: center;
  margin: 20px auto;
}

@media screen and (max-width:768px) {
  .menu-boton-mobile-mail img {
    width: 20%;
    padding: 10px;
    display: inline-block;
    text-align: center;
    margin: 20px auto;
  }

  .separador-form {
    margin: 20px auto;
  }

  .log-form h2 {
    font-weight: 500;
    margin-bottom: 0px;
    text-align: left;
    cursor: pointer;
    font-size: 16px;
    // width: 50%;
    display: inline-block;
    line-height: 1.2;
  }

  .log-form h2>span {
    padding-right: 0px;
  }

  .log-form h2>i {
    display: none;
  }

  .footer-img {
    text-align: center;
    background: $amarillo;
    font-family: "Roboto";
    font-weight: 100 !important;
    color: $naranja;
    padding: 10px;
    margin-top: 120px;
  }

  .footer-img img {
    display: block;
    height: 60px;
    margin: 0 auto;
    margin-top: 20px;
  }

  .log-form form {
    height: auto;
  }

  .log-contacto h1 {
    text-align: left;
    margin-top: 10px;
    font-size: 24px;
  }

  .log-contacto p {
    font-size: 16px;
  }

  .log-contacto h1>span {
    padding-left: 20px;
  }

  .log-form {
    margin-top: 10px;
  }

  .boton {
    width: 100%;
    // float:right;
  }

  .el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0px !important;
    color: $azul;
    font-size: 18px;
    font-weight: bold;
  }
}
</style>