import axios from 'axios'

export const baseUrl = `https://caba33.suizoargentina.com.ar/logisticaWeb`

// export const imgUrl = ``
export const HTTP = axios.create({
    baseURL: baseUrl,
    headers: {
      
    }
})
