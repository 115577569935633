<template>
  <div class="home">
      <el-backtop target=""></el-backtop>
      <!-- <div class="mensaje">
        <span></span>
        <div class="container">
          <h1 class="hidden-xs-only">

  Hoy más que nunca
           <strong>#quedateencasa</strong>
           para que nosotros podamos llegar. 
          </h1>
           <h1 class="hidden-sm-and-up" >
 Hoy más que nunca<br>
           <strong>#quedateencasa</strong>
           <br>
            para que nosotros podamos llegar.
          </h1>
          
        <img src="@/assets/casa-log.png" alt="Login Farma">
        </div>
      </div> -->
      <log-slides id="home" v-if="load" />
      <!-- <div class="bg-slide"  v-if="!load" ></div> -->
      <div class="separador" v-if="load"></div>
      <log-nosotros id="nosotros" v-if="load" />
      <log-mapa v-if="load" />
      <log-objetivos v-if="load" />
      <log-galeria v-if="load" />
      <log-servicios id="servicios" v-if="load" />
      <log-contacto id="contacto" v-if="load" />
      


  </div>
</template>

<script>
// @ is an alias to /src
import LogSlides from '@/components/LogSlides'
import LogNosotros from '@/components/LogNosotros'
import LogMapa from '@/components/LogMapa'
import LogObjetivos from '@/components/LogObjetivos'
import LogGaleria from '@/components/LogGaleria'
import LogServicios from '@/components/LogServicios'
import LogContacto from '@/components/LogContacto'

export default {
  name: 'home',
  data() {
    return {
      load: true
    }
  },
  components: {
     LogSlides,
     LogNosotros,
     LogMapa,
     LogObjetivos,
     LogGaleria,
     LogServicios,
     LogContacto
  },
  created() {
    setTimeout(() => {
       this.load = true
    }, 500);
   
  }
}
</script>
<style lang="scss">
.bg-slide{
    width: 100%;
    background-size: cover !important;
    height: calc(100vh - 100px);
    margin-top: 0px;
    background-repeat: no-repeat;
    z-index: 1;
    position: relative;
    background:url('~@/assets/fotos/base2.jpg') no-repeat  center top
      // background-attachment: fixed;

}
.separador{
  height: 120px;
  background: $amarillo;
}
.mensaje{
  background: $amarillo;
  height: 80px;
  border-left: 10px solid $naranja;
}

.mensaje h1{
 float: left;
 color: white;
 font-weight: 500;
 padding: 0;
 margin: 0;
 margin-top: 20px;
 text-align: right;
}
.mensaje img{
  height: 60px;
  display: block;
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}
@media screen and (max-width: 1280px) {
  .mensaje h1{
 font-size: 2.3vw;
}
  
}

@media (min-width: 768px) and (max-width: 996px) {
  .mensaje h1{
 font-size: 2vw;
}


}

@media screen and (max-width: 768px) {
  .mensaje h1{
 font-size: 16px;
 margin-top: 10px;
 text-align: center;
 float: left;
 padding-left: 20px;
//  display: inline-block;
}
.mensaje{
  background: $amarillo;
  height: 80px;
  border-left: 10px solid $naranja;
  padding-top: 60px;
}

}
@media screen and (max-width: 360px) {
  .mensaje h1{
 font-size: 16px;
 margin-top: 10px;
 text-align: center;
 float: left;
 padding-left: 20px;
//  display: inline-block;
}
.mensaje img{
  height: 40px;
  display: block;
  float: right;
  margin-top: 20px;
  margin-right: 10px;
}
.mensaje{
  background: $amarillo;
  height: 80px;
  border-left: 10px solid $naranja;
  padding-top: 60px;
}

}
</style>
