<template>
  <div>
      
       <VueFixedHeader
      @change="updateFixedStatus"
      :threshold="fixedStatus.threshold"
      :headerClass="fixedStatus.headerClass"
      :fixedClass="fixedStatus.fixedClass"
      :hideScrollUp="fixedStatus.hideScrollUp"
     
        >
      <nav >
        <!-- <transition name="fade"> -->
        <div  v-if="!fixedStatus.headerIsFixed">
          <log-menu-normal class="hidden-xs-only" />
          <log-menu-reducido class="hidden-sm-and-up" />
           <!-- <log-menu-reducido /> -->

        </div>
        
        <div  v-if="fixedStatus.headerIsFixed">
          <log-menu-reducido />
        </div>
        <!-- </transition> -->
      </nav>
      
    </VueFixedHeader>
      
  </div> 
</template>

<script>
import VueFixedHeader from 'vue-fixed-header'
import LogMenuNormal from '@/components/LogMenuNormal'
import LogMenuReducido from '@/components/LogMenuReducido'


export default {
  components: {
    VueFixedHeader,
    LogMenuNormal,
    LogMenuReducido
  },
  data() {
    return {
      fixedStatus: {
        headerIsFixed: false,
        threshold: 74,
        headerClass: 'vue-fixed-header',
        fixedClass: 'vue-fixed-header--isFixed',
        hideScrollUp: false
      }
    }
  },
  methods: {
    updateFixedStatus(next) {
      this.fixedStatus.headerIsFixed = next
      // console.log(next)
      
    }
  }
}
</script>
<style lang="scss">

nav {
  display: block;
  width: 100% !important;
  margin: 0;
  padding: 0;
  background: #fff;
 transition: 0.5s all;
}

nav.vue-fixed-header--isFixed {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.5s ease-out forwards;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-74px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}



</style>

