<template>
  <div class="menu-reducido">
    <div class="container">
      <el-row type="flex" class="row-bg" justify="end">
        <el-col :xs="4" :sm="4">
          <img src="@/assets/SVG/logo.svg" v-scroll-to="{ el: '#home', offset: 0 }" class="logo-reducido"
            @click="menuActivo = 'home'" alt="Login Farma">
        </el-col>

        <el-col :xs="{span:24, offset:0}" class="hidden-sm-and-up">
          <div class="menu-reducido-right">
            <a href="https://login.microsoftonline.com/" target="_blank" class="menu-boton-webmail-reducido"><i
                class="el-icon-message"></i></a>

            <a href="https://seguimiento.log-in.com.ar/#/" class="menu-boton-carro-reducido"><i
                class="el-icon-user-solid"></i></a>

            <a @click="menuMobile = true" class="menu-boton-carro-reducido"> <i class="el-icon-more"></i> </a>
            <img @click="switchLocale" src="@/assets/SVG/es_text.svg" class="es-reducido"
              alt="Cambiar a sitio en español - Log In Farma">
            <img @click="switchLocale" src="@/assets/SVG/en_text.svg" class="en-reducido"
              alt="Change to English website - Log In Farma">

          </div>
        </el-col>
        <el-col :sm="20" class="hidden-xs-only ">
          <div class="menu-reducido-right">
            <ul>
              <li>
                <el-dropdown @command="verCodigo">
                  <span class="el-dropdown-link" placement="top-start">
                    <a href="" :class="{ 'menuActivo': menuActivo == 'nosotros' }"
                      v-scroll-to="{ el: '#nosotros', offset: -230 }" @click="menuActivo = 'nosotros'">{{
                      $t('header.nosotros') }}</a>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="codigo">Código de Ética </el-dropdown-item>
                    <el-dropdown-item command="rse">RSE</el-dropdown-item>


                  </el-dropdown-menu>
                </el-dropdown>
                <!-- <a href="" :class="{'menuActivo': menuActivo == 'nosotros'}" v-scroll-to="{ el: '#nosotros', offset: -100 }" @click="menuActivo = 'nosotros'">{{ $t( 'header.nosotros' ) }}</a> -->
              </li>
              <li>
                <a href="" :class="{'menuActivo': menuActivo == 'servicios'}"
                  v-scroll-to="{ el: '#servicios', offset: -100 }" @click="menuActivo = 'servicios'">{{ $t(
                  'header.servicios' ) }}</a>
              </li>
              <li>
                <a href="" :class="{'menuActivo': menuActivo == 'contacto'}"
                  v-scroll-to="{ el: '#contacto', offset: -100 }" @click="menuActivo = 'contacto'">{{ $t(
                  'header.contacto' ) }}</a>
              </li>
              <li>
                <a href="https://log-in.com.ar/sala-de-prensa/" :class="{'menuActivo': menuActivo == 'sala-de-prensa'}"
                  target="_blank">{{ $t( 'header.prensa' ) }}</a>
              </li>
            </ul>
            <a href="https://login.microsoftonline.com/" target="_blank" class="menu-boton-webmail-reducido"
              style="text-decoration:none; font-size:14px"><i class="el-icon-message"></i></a>

            <a href="https://seguimiento.log-in.com.ar/#/" target="_blank" class="menu-boton-carro-reducido"
              style="text-decoration:none; font-size:14px">{{ $t( 'header.carro' ) }}</a>
            <img @click="switchLocale" src="@/assets/SVG/es_text.svg" class="es-reducido"
              alt="Cambiar a sitio en español - Log In Farma">
            <img @click="switchLocale" src="@/assets/SVG/en_text.svg" class="en-reducido"
              alt="Change to English website - Log In Farma">
            <a href="https://www.instagram.com/loginfarma" target="_blank" class="menu-boton-ig"> <img
                src="@/assets/SVG/ig.svg" alt="Instagram Login Farma"></a>
            <a href="https://www.linkedin.com/company/loginfarma/" target="_blank" class="menu-boton-in"> <img
                src="@/assets/SVG/in.svg" alt="Linkedin Login Farma"></a>
            <a href="https://www.linkedin.com/company/loginfarma/" target="_blank" class="menu-boton-yt"> <img
                src="@/assets/SVG/yt.svg" alt="Youtube Login Farma"></a>
          </div>


        </el-col>
      </el-row>
    </div>
    <el-dialog :visible.sync="menuMobile" :fullscreen="true" :show-close="false" :append-to-body="true">
      <img src="@/assets/SVG/logo.svg" v-scroll-to="{ el: '#home', offset: 0 }" class="logo-mobile"
        @click="menuActivo = 'home'" alt="">

      <el-button type="link" style="display:block; width:100%; margin-top:60px"
        :class="{'menuActivo': menuActivo == 'nosotros'}" v-scroll-to="{ el: '#nosotros', offset: -50 }"
        @click="activarMenuMobile('nosotros')">{{ $t( 'header.nosotros' ) }}</el-button>
      <br>
      <el-button type="link" style="display:block; width:100%" :class="{'menuActivo': menuActivo == 'servicios'}"
        v-scroll-to="{ el: '#servicios', offset: -100 }" @click="activarMenuMobile('servicios')">{{ $t(
        'header.servicios' ) }}</el-button>
      <br>
      <el-button type="link" style="display:block; width:100%" :class="{'menuActivo': menuActivo == 'contacto'}"
        v-scroll-to="{ el: '#contacto', offset: -100 }" @click="activarMenuMobile('contacto')">{{ $t( 'header.contacto'
        ) }}</el-button>
      <br>
      <el-button type="link"
        style="display:block; width:100%; text-transform:uppercase; background:#FAC03D; color:#E45A0F; font-weight:600"
        onClick="window.location = 'https://seguimiento.log-in.com.ar/#/'">
        <i class="el-icon-user-solid"></i>
        {{ $t( 'header.carro' ) }}
      </el-button>
      <a href="https://www.instagram.com/loginfarma" target="_blank" class="menu-boton-mobile"> <img
          src="@/assets/SVG/ig.svg" alt=""></a>
      <a href="https://www.linkedin.com/company/loginfarma/" target="_blank" class="menu-boton-mobile"> <img
          src="@/assets/SVG/in.svg" alt="ico-linkedin"></a>
      <a href="https://www.linkedin.com/company/loginfarma/" target="_blank" class="menu-boton-mobile"> <img
          src="@/assets/SVG/yt.svg" alt="ico-linkedin"></a>
      <span slot="footer" class="dialog-footer">

        <el-button type="warning" @click="menuMobile = false" style="width:100%">{{$t('header.cerrarMenu')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {EventBus} from '@/event-bus.js'
export default {
data() {
  return {
    menuActivo: 'home',
    menuMobile:false
  }
},
created(){
  EventBus.$on('menu-activo', menu =>{
            this.menuActivo = menu
            
  })
},
methods: {
   switchLocale () {
    // console.log(this.$i18n.locale)
  if (this.$i18n.locale === 'es') {
    this.$i18n.locale = 'en'
  } else {
    this.$i18n.locale = 'es'
  }
},
  verCodigo(code) {
   
    switch (code) {
      case 'codigo':
        window.open('https://log-in.com.ar/documentos/codigo_de_etica.pdf', '_blank')
        break;
      case 'rse':
        window.open('https://log-in.com.ar/archivos/RSE/RSE%20LOG-IN.pdf', '_blank')
        break;


    }

  },
  activarMenuMobile(menu) {
    this.menuActivo = menu
    // this.$scrollTo(menu, 500, options)
    setTimeout(() => {
      this.menuMobile = false
    }, 150);
    
  }
},
}
</script>

<style lang="scss">
.menu-reducido-right{
  float:right;
  margin-top: 12px;
}
.menu-boton-ig-reducido img{
  height: 15px;
  margin-top: -20px;
  display: block;
}
.menu-boton-ig-reducido{
  display: inline-block;
  transition: 500ms;
  margin-left: 20px;
  margin-right: 10px;
  
}
.menu-boton-in-reducido img{
  height: 17px;
    margin-top: -22px;
  display: block;
}
.menu-boton-in-reducido{
  display: inline-block;
   width: 5px;
  transition: 500ms;
  margin-left: -10px;
}
.menu-boton-yt-reducido img{
  height: 10px;
  display: block;
  margin-top: -16px;
}
.menu-boton-yt-reducido{
  display: inline-block;
  width: 5px;
  transition: 500ms;
  margin-left: 22px;
}
// mobiel

.menu-boton-mobile img{
  height: 50px;
  // width: 100px;
  display: block;
  margin-top: 56px;
  text-align: center;
  
}
.menu-boton-mobile{

  display: inline-block;
  width: 5px;
  transition: 500ms;
  margin: 0px 40px;
  text-align: center;
  // margin-left: 60px;
}
// 
.menu-reducido{
  height: 60px;
  background: #fff;
  -webkit-box-shadow: 0 2px 3px 0 rgba(105, 105, 105, 0.91) ;
  box-shadow: 0 2px 3px 0 rgba(105, 105, 105, 0.91) ;
      position: fixed;
    left: 0;
    right: 0;
    z-index: 99;
}

.logo-reducido{
  height: 40px;
  margin-top: 10px;
  cursor: pointer;
}

.logo-mobile{
  height: 60px;
  margin: 0px auto;
  display: block;
  cursor: pointer;
  margin-top: -50px;
}
.menu-boton-carro-reducido{
  border:1.4px solid $naranja;
  color: $naranja;
    margin-right: 10px;
    display: inline-block;
    padding:3.1px 12px;
    border-radius: 12px;
    transform: translateY(-9px);
    transition: all 0.3s;
}

.menu-boton-carro-reducido:hover{
 background: $naranja;
 color:white;
}

.menu-boton-webmail-reducido{
  border:1.4px solid $azul;
  color: $azul;
    margin-right: 10px;
    display: inline-block;
    padding:3.1px 12px;
    border-radius: 12px;
    transform: translateY(-9px);
    transition: all 0.3s;
    margin-top: 10px;
}

.menu-boton-webmail-reducido:hover{
 background: $azul;
 color:white;
}
.es-reducido{
   height: 15px;
   display: inline-block;
   margin:0 auto;
   margin-top: 0px;
   margin-left: 20px;
   padding:6.1px 4px;
   cursor: pointer;
   margin-top: -20px;

}
.en-reducido{
   height: 15px;
   display: inline-block;
   margin:0 auto;
   margin-top: 0px;
   margin-left: 10px;
   padding:6.1px 4px;
   cursor: pointer;
   margin-top: -20px;
}

.menu-reducido ul {
       list-style: none;
    padding: 0;
    display: inline-block;
    margin: 0;
    margin-top: -10px;
    transform: translateY(-8px);
    margin-right: 20px;
   
    // margin-right: 10%;
}
.menu-reducido ul > li {
    display: inline;
    padding: 10px;
}

.el-dropdown-menu {
  font-family: 'Roboto';
  // transform: translateY(-60px);
  transform: translate(38px,-30px);
  padding: 4px;
  // margin-top: -50px;
}


.menu-reducido ul>li>.el-dropdown a ,
.menu-reducido ul > li > a{
  font-family: 'Roboto';
  text-decoration: none;
  font-weight: 900;
  color: rgba(6, 64, 103, 0.6);
  text-transform: uppercase;
  line-height: 5;
  transition: all 0.5s;
  font-size: 14px;
}

.menu-reducido ul > li > a:hover, .menuActivo{
  color: $azul !important;
}

@media screen and (max-width: 768px) {
  .logo-reducido{
  height: 35px;
  padding:4px 10px;

}
.menu-reducido-right{
  float:right;
  margin-top: 15px;
}
.es-reducido{
   height: 12px;
   padding: 9px 8px;
   margin:0px;
   cursor: pointer;
   margin-top: -20px;

  

}
.en-reducido{
   height: 12px;
   padding: 9px 8px;
   margin:0px;
   cursor: pointer;
   margin-top: -20px;

  
}



}

@media (max-width: 330px) and (max-height: 620px) {
  .logo-reducido{
  height: 35px;
  padding:4px 10px;

}
.menu-reducido-right{
  float:right;
  margin-top: 18px;
}
.es-reducido{
   height: 12px;
   padding: 8px;
   margin:0px;
   cursor: pointer;

  

}
.en-reducido{
   height: 12px;
   padding:8px;
   margin:0px;
   cursor: pointer;

  
}
.menu-boton-carro-reducido{

    padding:2px 8px;
 
}



.menu-boton-webmail-reducido{
 
    padding:2px 8px;
  
}



}
</style>