<template>
  <div>
    <div class="servicios-amarillo">
      <div class="container w80">
        <div class="servicios-titulo">
          <span>{{$t('servicios.titulo')}}</span>
        </div>
        <span class="linea-naranja"></span>
        <div class="servicios-intro">
          <h2>{{$t('servicios.subtitulo')}}</h2>
          <p>
            {{$t('servicios.intro')}}
            <img src="@/assets/SVG/flecha-down.svg" alt="Logistica" />
          </p>
        </div>
        <el-row>
          <el-col :md="12">
            <div class="servicios-logistica">
              <img src="@/assets/fotos/servicios-1.png" alt="Operaciones logísticas farmacéuticas" />
              <h3>{{$t('servicios.logistica.texto_imagen')}}</h3>
            </div>
            <div class="servicios-logistica-textos">
              <h4>{{$t('servicios.logistica.titulo')}}</h4>
              <ul>
                <li v-for="sl in $t('servicios.logistica.texto')" :key="sl">{{sl}}</li>
              </ul>
              <!-- <p>{{$t('servicios.logistica.texto')}}</p> -->
            </div>
          </el-col>
          <el-col :md="12">
            <div class="servicios-infra">
              <img src="@/assets/fotos/servicios-2.png" alt="3PL Farmacéutica" />
              <h3>{{$t('servicios.infraestructura.texto_imagen')}}</h3>
            </div>
            <div class="servicios-infra-textos">
              <h4>{{$t('servicios.infraestructura.titulo')}}</h4>
              <ul>
                <li v-for="si in $t('servicios.infraestructura.texto')" :key="si">{{si}}</li>
              </ul>
              <!-- <p>{{$t('servicios.infraestructura.texto')}}</p> -->
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="{span:12, offset:12}">
            <div class="servicios-consulta" @click="activarMenu('contacto')">
              <i class="el-icon-arrow-down"></i>
              <strong>{{$t('servicios.texto_consulta')}}</strong>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <log-parallax />
  </div>
</template>

<script>
import LogParallax from "@/components/LogParallax";
import { EventBus } from "@/event-bus.js";
export default {
  components: {
    LogParallax
  },
  methods: {
    activarMenu(menu) {
      this.$scrollTo("#" + menu, 500, { offset: -100 });
      EventBus.$emit("menu-activo", menu);
    }
  }
};
</script>

<style lang="scss" scoped>
.w80 {
  width: 80%;
}
.servicios-consulta {
  float: right;
  cursor: pointer;
  margin: 40px;
}
.servicios-consulta i {
  color: $naranja;
  font-size: 30px;
  font-weight: 900;
  padding: 4px 12px;
}
.servicios-consulta strong {
  background: $naranja;
  padding: 4px 12px;
  font-size: 30px;
  color: white;
}
.servicios-logistica-textos h4,
.servicios-infra-textos h4 {
  font-size: 24px;
}
.servicios-logistica-textos ul,
.servicios-infra-textos ul {
  margin: 0;
  padding: 16px;
}
.servicios-logistica-textos ul > li,
.servicios-infra-textos ul > li {
  font-size: 20px;
  padding: 2px 0px;
  font-weight: 400;
}
.servicios-logistica-textos {
  padding-right: 50px;
  color: $azul;
}
.servicios-infra-textos {
  padding-left: 80px;
  color: $azul;
}
.servicios-logistica,
.servicios-infra {
  color: $azul;
  position: relative;
}
.servicios-logistica h3 {
  // text-transform: uppercase;
  color: $azul;
  margin-top: -80px;
  font-size: 50px;
  padding: 0px 30px;
  font-weight: 900;
  font-style: italic;
}
.servicios-infra h3 {
  // text-transform: uppercase;
  color: $azul;
  margin-top: -80px;
  margin-left: 90px;
  font-size: 50px;
  padding: 0px 30px;
  font-weight: 900;
  font-style: italic;
}
.servicios-logistica img {
  width: 100%;
  z-index: -1;
}
.servicios-infra img {
  width: 100%;
  z-index: -1;
}
.servicios-intro {
  color: $azul;
  padding: 10px 0px;
  margin: 0 auto;
  margin-bottom: 30px;
}
.servicios-intro h2 {
  font-size: 30px;
  line-height: 1.2;
}
.servicios-intro p {
  font-size: 22px;
  line-height: 1.2;
}
.servicios-intro img {
  height: 10px;
}
.linea-naranja {
  display: block;
  width: 10%;
  margin-top: 50px;
  margin-left: 30px;
  border-bottom: 4px solid $naranja;
  margin-bottom: 10px;
}
.servicios-amarillo {
  background: $amarillo;
  height: 500px;
  width: 100%;
  position: relative;
  margin: 0 auto;
  // padding: 10px;
}
.servicios-titulo {
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
  -webkit-transform: skewX(-15deg);
  transform: skewX(-15deg);
  width: 90%;
}
.servicios-titulo span:after {
  content: " ";
  position: absolute;
  display: block;
  width: 90%;
  height: 90%;
  top: 0;
  left: 0;
  z-index: -1;
  background: $naranja;
  transform-origin: bottom left;

  border-radius: 4px;
  padding: 0px 5px;
}

.servicios-titulo span {
  background: $naranja;
  border-radius: 4px;
  padding: 15px;

  position: relative;
  color: #fff;
}

@media screen and (max-width: 1440px) {
  .servicios-logistica-textos h4,
  .servicios-infra-textos h4 {
    font-size: 22px;
  }
  .servicios-logistica-textos ul > li,
  .servicios-infra-textos ul > li {
    font-size: 18px;
  }

  .servicios-infra h3 {
    margin-left: 60px;
    font-size: 40px;
    margin-top: -60px;
  }
  .servicios-logistica h3 {
    margin-top: -60px;
    font-size: 40px;
  }

  .servicios-intro p {
    font-size: 20px;
  }
}

@media screen and (max-width: 1136px) {
  .servicios-logistica-textos h4,
  .servicios-infra-textos h4 {
    font-size: 20px;
  }
  .servicios-logistica-textos ul > li,
  .servicios-infra-textos ul > li {
    font-size: 18px;
  }

  .servicios-infra h3 {
    margin-left: 60px;
    font-size: 34px;
    margin-top: -50px;
  }
  .servicios-logistica h3 {
    margin-top: -50px;
    font-size: 34px;
  }

  .servicios-intro p {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .w80 {
    width: 100%;
  }
  .servicios-consulta {
    float: right;
    cursor: pointer;
    margin: 40px;
    width: 90%;
  }
  .servicios-consulta i {
    color: $naranja;
    font-size: 20px;
    font-weight: 900;
    padding: 4px 12px;
  }
  .servicios-consulta strong {
    background: $naranja;
    padding: 4px 12px;
    font-size: 20px;
    color: white;
    width: 90%;
  }
  .servicios-logistica-textos h4,
  .servicios-infra-textos h4 {
    font-size: 20px;
    width: 90%;
    margin: 10px auto;
  }
  .servicios-logistica-textos ul > li,
  .servicios-infra-textos ul > li {
    font-size: 16px;
    width: 90%;
    margin: 10px auto;
  }
  .servicios-logistica-textos {
    padding-right: 0px;
    color: $azul;
  }
  .servicios-infra-textos {
    padding-left: 0px;
    color: $azul;
  }
  .servicios-logistica,
  .servicios-infra {
    color: $azul;
    position: relative;
  }
  .servicios-logistica h3 {
    margin-top: -40px;
    font-size: 30px;
  }
  .servicios-infra h3 {
    margin-top: -40px;
    margin-left: 10px;
    font-size: 30px;
  }
  .servicios-logistica img {
    width: 100%;
  }
  .servicios-infra img {
    width: 100%;
  }
  .servicios-intro {
    color: $azul;
    padding: 10px;
    margin-bottom: 10px;
  }
  .servicios-intro h2 {
    font-size: 24px;
  }
  .servicios-intro p {
    font-size: 18px;
    // width: 90% !important;
  }

  .linea-naranja {
    width: 20%;
  }
  .servicios-amarillo {
    background: $amarillo;
    height: 100%;
  }
  .servicios-titulo {
    font-size: 24px;
    width: 90%;
    margin: 0 auto;
  }
}
</style>