import 'babel-polyfill'  

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import i18n from './i18n'
import 'element-ui/lib/theme-chalk/display.css';
import AOS from 'aos'
import 'aos/dist/aos.css'
// import SvgTransition from 'vue-svg-transition';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueScrollTo from 'vue-scrollto'
import 'swiper/dist/css/swiper.css'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueGtm from 'vue-gtm';


Vue.use(VueGtm, {
  id: ['AW-614534980', 'UA-149027097-1'], // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
 
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional) 

});
Vue.use(VueScrollTo)
Vue.use(VueReCaptcha, {
  siteKey: '6LfolrkUAAAAAMcXYLqb0fvWfnYEgG9cjqUShs97'
})

// Vue.use(SvgTransition);
Vue.use(VueAwesomeSwiper, /* { default global options } */)


Vue.config.productionTip = false
import {
  Icon,
  Row,
  Col,
  Form,
  FormItem,
  Upload,
  Input,
  Dialog,
  Backtop,
  Message,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from 'element-ui';


Vue.use(Icon);
Vue.use(Col);
Vue.use(Row);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Upload);
Vue.use(Input);
Vue.use(Dialog);
Vue.use(Backtop);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.prototype.$message = Message;


new Vue({
  router,
  i18n,
  created () {
    AOS.init({
      once: true
    })
  },
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
   components: { App }
}).$mount('#app')
