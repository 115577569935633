<template>
  <div>
    <!-- <div class="linea-header">
          <a href="https://login.microsoftonline.com/" target="_blank" class="menu-boton-webmail">{{ $t( 'header.webmail') }}</a>   
          <a href="https://log-in.com.ar/" target="_blank" class="menu-boton-carro"><i class="el-icon-shopping-cart-2"></i> {{ $t( 'header.carro' ) }}</a>   
          <a href="https://www.linkedin.com/company/loginfarma/" target="_blank" class="menu-boton-ig"> <img src="@/assets/SVG/ig.svg" class="es-reducido" alt="ico-in"></a>
          <a href="https://www.linkedin.com/company/loginfarma/" target="_blank" class="menu-boton-in"> <img src="@/assets/SVG/in.svg" class="en-reducido" alt="ico-in"></a>
          <a href="https://www.linkedin.com/company/loginfarma/" target="_blank" class="menu-boton-yt"> <img src="@/assets/SVG/yt.svg" class="en-reducido" alt="ico-in"></a>

      </div> -->
    <div class="menu-home">
      <div class="container">
        <el-row type="flex" class="row-bg" justify="end">
          <el-col :xs="4" :sm="4">
            <img src="@/assets/SVG/logo.svg" v-scroll-to="{ el: '#home', offset: 0 }" class="logo-home"
              @click="menuActivo = 'home'" alt="Login Farma">
          </el-col>


          <el-col :sm="20">
            <div style="float:right; margin-top:15px">
              <ul>
                <li>
                  <el-dropdown @command="verCodigo">
                    <span class="el-dropdown-link" placement="top-start">
                      <a href="" :class="{ 'menuActivo': menuActivo == 'nosotros' }"
                        v-scroll-to="{ el: '#nosotros', offset: -230 }" @click="menuActivo = 'nosotros'">{{
                        $t('header.nosotros') }}</a>
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="codigo">Código de Ética </el-dropdown-item>
                      <el-dropdown-item command="rse">RSE</el-dropdown-item>


                    </el-dropdown-menu>
                  </el-dropdown>
                </li>
                <li>
                  <a href="" :class="{ 'menuActivo': menuActivo == 'servicios' }"
                    v-scroll-to="{ el: '#servicios', offset: -100 }" @click="menuActivo = 'servicios'">{{ $t(
                    'header.servicios') }}</a>
                </li>
                <li>
                  <a href="" :class="{ 'menuActivo': menuActivo == 'contacto' }"
                    v-scroll-to="{ el: '#contacto', offset: -100 }" @click="menuActivo = 'contacto'">{{ $t(
                    'header.contacto') }}</a>
                </li>
                <li>
                  <a href="https://log-in.com.ar/sala-de-prensa/"
                    :class="{ 'menuActivo': menuActivo == 'sala-de-prensa' }" target="_blank">{{ $t('header.prensa')
                    }}</a>
                </li>
              </ul>
              <a href="https://login.microsoftonline.com/" target="_blank" class="menu-boton-webmail"><i
                  class="el-icon-message"></i></a>

              <a href="https://seguimiento.log-in.com.ar" target="_blank" class="menu-boton-carro"
                style="text-decoration:none; font-size:16px">{{ $t('header.carro') }}</a>
              <img @click="switchLocale" src="@/assets/SVG/es_text.svg" class="es"
                alt="Cambiar a sitio en español - Log In Farma">
              <img @click="switchLocale" src="@/assets/SVG/en_text.svg" class="en"
                alt="Change to English web site - Log In Farma">
              <a href="https://www.instagram.com/loginfarma" target="_blank" class="menu-boton-ig"> <img
                  src="@/assets/SVG/ig.svg" class="es-reducido" alt="Instagram Login Farma"></a>
              <a href="https://www.linkedin.com/company/loginfarma/" target="_blank" class="menu-boton-in"> <img
                  src="@/assets/SVG/in.svg" class="en-reducido" alt="Linkedin Login Farma"></a>
              <a href="https://www.linkedin.com/company/loginfarma/" target="_blank" class="menu-boton-yt"> <img
                  src="@/assets/SVG/yt.svg" class="en-reducido" alt="Youtube Login Farma"></a>
            </div>


          </el-col>
        </el-row>
      </div>

    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js'
export default {
  data() {
    return {
      menuActivo: 'home',
      menuMobile: false
    }
  },
  created() {
    EventBus.$on('menu-activo', menu => {
      this.menuActivo = menu

    })
  },
  methods: {
    switchLocale() {
      console.log(this.$i18n.locale)
      if (this.$i18n.locale === 'es') {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'es'
      }
    },
    verCodigo(code)
    {
      
      switch (code) {
        case 'codigo':
          window.open('https://log-in.com.ar/documentos/codigo_de_etica.pdf', '_blank')
          break;
        case 'rse':
          window.open('https://log-in.com.ar/archivos/RSE/RSE%20LOG-IN.pdf', '_blank')
          break;
      
       
      }
    
    },
    activarMenuMobile(menu) {
      this.menuActivo = menu
      // this.$scrollTo(menu, 500, options)
      setTimeout(() => {
        this.menuMobile = false
      }, 150);

    },
    activarMenu(menu) {
      this.menuActivo = menu
      EventBus.$emit('menu-activo', menu)

    }
  },
}
</script>

<style lang="scss">
.menu-boton-carro {
  border: 1.4px solid $naranja;
  color: $naranja;
  margin-right: 10px;
  display: inline-block;
  padding: 3.1px 12px;
  border-radius: 12px;
  transform: translateY(-9px);
  transition: all 0.3s;
}

.menu-boton-carro:hover {
  background: $naranja;
  color: white;
}
.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #e7aa81;
    color: #000;
}

.menu-boton-webmail {
  border: 1.4px solid $azul;
  color: $azul;
  margin-right: 10px;
  display: inline-block;
  padding: 3.1px 12px;
  border-radius: 12px;
  transform: translateY(-9px);
  transition: all 0.3s;
}

.menu-boton-webmail:hover {
  background: $azul;
  color: white;
}

.es {
  height: 15px;
  display: inline-block;
  margin: 0 auto;
  margin-top: -20px;
  margin-left: 20px;
  padding: 6.1px 4px;
  cursor: pointer;

}

.en {
  height: 15px;
  display: inline-block;
  margin: 0 auto;
  margin-top: -20px;
  margin-left: 10px;
  padding: 6.1px 4px;
  cursor: pointer;
}

.menu-boton-ig img {
  height: 15.5px;

  margin: 0px;
  padding: 6px 4px;
  margin-top: -20px;


}

.menu-boton-in img {
  height: 17px;
  margin: 0px;
  padding: 7px 4px;
  margin-top: -20px;


}

.menu-boton-yt img {
  height: 13px;
  margin: 0px;
  padding: 7px 4px;
  margin-top: -20px;


}

.menu-boton-ig {
  position: relative;
  transition: 500ms;
  margin: 0px;
  padding: 0px;
  display: inline-block;
  margin-left: 10px;
}

.menu-boton-in {
  position: relative;
  transition: 500ms;
  margin: 0px;
  padding: 0px;
  display: inline-block;
}

.menu-boton-yt {
  position: relative;
  transition: 500ms;
  margin: 0px;
  padding: 0px;
  display: inline-block;
}


.menu-home {
  height: 100px;
  background: #fff;
  -webkit-box-shadow: 0 1px 1px 0 rgba(94, 94, 94, 0.91);
  box-shadow: 0 1px 1px 0 rgba(94, 94, 94, 0.91);
  position: relative;
  z-index: 99;
}

.logo-home {
  height: 60px;
  margin-top: 20px;
  cursor: pointer;
}


.menu-home ul {
  list-style: none;
  padding: 0;
  display: inline-block;
  margin: 0;
  margin-top: 10px;
  transform: translateY(-8px);
  margin-right: 20px;
  position: relative;

  // margin-right: 10%;
}

.menu-home ul>li {
  display: inline;
  padding: 10px;
  position: relative;
}

.el-dropdown-menu {
  font-family: 'Roboto';
  // transform: translateY(-60px);
  transform: translate(38px,-30px);
  padding: 4px;
  // margin-top: -50px;
}

.menu-home ul>li>a,
.menu-home ul>li>.el-dropdown a {
  font-family: 'Roboto';
  text-decoration: none;
  font-weight: 900;
  color: rgba(6, 64, 103, 0.6);
  text-transform: uppercase;
  line-height: 5;
  transition: all 0.5s;
  font-size: 14px;
  position: relative;
}

.menu-home ul>li>a:hover,
.menuActivo {
  color: $azul !important;
  position: relative;
}

@media screen and (max-width: 768px) {
  .logo-home {
    height: 35px;

  }




}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

  .menu-boton-ig {
    position: relative;
    transition: 500ms;
    margin: 0px;
    padding: 0px;
    display: inline;
    margin-left: 10px;
  }

  .menu-boton-in {
    position: relative;
    transition: 500ms;
    margin: 0px;
    padding: 0px;
    display: inline;
  }

  .menu-boton-yt {
    position: relative;
    transition: 500ms;
    margin: 0px;
    padding: 0px;
    display: inline;
  }

}</style>