<template>
    <div>

        <svg-transition :ref="refe" trigger="none" class="puntos-svg">

            <svg slot="initial">
                <g id="circle" data-name="Capa 2">
                    <g id="Capa_1-2" data-name="Capa 1">
                        <circle class="cls-1" cx="10.22" cy="10.22" r="10.22"></circle>
                    </g>
                </g>
            </svg>
            <svg>
                <g id="flecha" data-name="Capa 2">
                    <g id="Capa_1-2" data-name="Capa 1">
                        <polygon class="cls-1" points="0 0 0 20.44 10.22 29.37 20.44 20.44 20.44 0 0 0"></polygon>
                    </g>
                </g>
            </svg>
        </svg-transition>
    </div>
</template>

<script>
import SvgTransition from '@/components/SvgTransition.vue'
import { EventBus } from '@/event-bus'

export default {
    props: ['refe', 'cantidad'],
    components: {
        SvgTransition
    },
    data() {
        return {
            selectPunto: '',
            size: {
                width: 30,
                height: 50
            }

        }
    },
    mounted() {

    },
    created() {
        // this.$refs[this.refe].performTransition();

        EventBus.$on('ir-al-punto', puntos => {


            puntos.map((punto, index) => {
                let icon = 'icon-' + index
                if (this.$refs[icon] && this.$refs[icon].status.interacted) {

                    this.$refs[icon].performTransition();
                }

            })
            setTimeout(() => {
                puntos.map((punto, index) => {
                    let icon = 'icon-' + index
                    if (punto.activo && this.$refs[icon]) {
                        this.$refs[icon].performTransition();
                        // console.log(this.$refs[icon].status);
                    }
                })
            }, 10);

        });

    }
}
</script>

<style>
.cls-1 {
    fill: #094068;
}

.puntos-svg {
    width: 25px;
    height: 25px;
    margin-bottom: 20px;

}

@media screen and (max-width: 1440px) {
    .puntos-svg {
        width: 25px;
        height: 25px;
        margin-bottom: 20px;

    }
}

@media screen and (max-width: 1180px) {
    .puntos-svg {
        width: 25px;
        height: 25px;
        margin-bottom: 20px;

    }
}
</style>