<template>
  <div class="objetivos">
    <!-- <div class="objetivos-decorador-amarillo hidden-sm-and-up"></div> -->
    <div class="objetivos-fondo-derecha hidden-xs-only"></div>
    <div class="container">
      <div class="objetivos-objetivo">
        <h1>{{$t('objetivos.objetivo.titulo')}}</h1>
        <p>{{$t('objetivos.objetivo.texto')}}</p>

        <!-- <h1>{{$t('objetivos.medio.titulo')}}</h1>
        <p>{{$t('objetivos.medio.texto')}}</p> -->

        <el-row :gutter="30">
          <el-col :md="8">
            <div class="objetivos-icon">
              <img src="@/assets/SVG/mision.svg" class="icon-mision" alt="mision Login Farma" />
              <h2>{{$t('objetivos.mision.titulo')}}</h2>
              <p>{{$t('objetivos.mision.texto')}}</p>
            </div>
          </el-col>
          <el-col :md="8">
            <div class="objetivos-icon">
              <img src="@/assets/SVG/vision.svg" alt="vision Login Farma" />
              <h2>{{$t('objetivos.vision.titulo')}}</h2>
              <p>{{$t('objetivos.vision.texto')}}</p>
            </div>
          </el-col>
          <el-col :md="8">
            <div class="objetivos-icon">
              <img src="@/assets/SVG/valores.svg" alt="valores Login Farma" />
              <h2>{{$t('objetivos.valores.titulo')}}</h2>

              <p>
                <span v-for="v in $t('objetivos.valores.texto')" :key="v">
                  {{v}}
                  <br />
                </span>
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.icon-mision {
  height: 85px !important;
  padding: 7.5px;
}
.objetivos-objetivo {
  position: absolute;
  top: -300px;
  left: 0;
  right: 0;
  margin: 20px auto;
  text-align: center;
  width: 45%;
}
.objetivos-objetivo h1 {
  line-height: 1;
  margin-top: 140px;
  font-size: 40px;
}
.objetivos-objetivo h1 > small {
  font-size: 16px;
  font-weight: 300;
}
.objetivos-medio > p,
.objetivos-objetivo > p {
  font-style: italic;
  font-size: 22px;
  font-weight: 300;
  padding: 0px 25px;
}
.objetivos-icon {
  margin-top: 130px;
}
.objetivos-icon p {
  font-style: italic;
  font-size: 18px;
  font-weight: 300;
  
}
.objetivos-icon h2 {
  color: $naranja;
}
.objetivos-icon img {
  height: 100px;
}
.objetivos {
  height: 600px;
  position: relative;
  font-family: "Roboto";
  margin: 0 auto;
  text-align: center;
  color: $azul;
}
.objetivos-fondo-derecha {
  -webkit-clip-path: polygon(97% 0, 100% 0, 100% 100%, 73% 100%);
  clip-path: polygon(97% 0, 100% 0, 100% 100%, 73% 100%);
  background-image: url("~@/assets/fondo/triangle.png");
  height: 140vh;
  margin-top: -200px;
}
@media	only screen and (min-resolution: 120dpi) {

  .objetivos {
    height: 800px;
  
  }


}
@media screen and (max-width: 1441px) {
  .icon-mision {
    height: 80px !important;
    padding: 5.5px;
  }
  .objetivos-fondo-derecha {
    height: 150vh;
  }
  .objetivos-objetivo h1 {
    line-height: 1;
    margin-top: 100px;
    font-size: 36px;
  }
  .objetivos-objetivo h1 > small {
    font-size: 14px;
  }
  .objetivos-objetivo > p {
    font-size: 16px;
  }
  .objetivos-icon {
    margin-top: 100px;
  }
  .objetivos-icon p {
    font-size: 18px;
  }
  .objetivos-icon h2 {
    color: $naranja;
  }
  .objetivos-icon img {
    height: 90px;
  }
  .objetivos {
    height: 450px;
    position: relative;
    font-family: "Roboto";
    margin: 100px auto;
    text-align: center;
    color: $azul;
  }
}

@media screen and (max-width:1366px) {
    .objetivos-objetivo {
 
  width:60%;
}
}

@media screen and (max-width: 768px) {
  .objetivos-objetivo {
    position: relative;

    margin: 0 auto;
    text-align: center;
    width: 90%;
    margin-top: 900px;
    margin-bottom: 20px;
  }
  .objetivos-objetivo h1 {
    font-size: 30px;
  }
  .objetivos-objetivo h1 > small {
    font-size: 16px;
  }
  .objetivos-medio > p,
  .objetivos-objetivo > p {
    font-size: 18px;
  }
  .objetivos-icon {
    margin-top: 30px;
  }
  .objetivos-icon p {
    font-size: 18px;
  }
  .objetivos-icon h2 {
    color: $naranja;
  }
  .objetivos-icon img {
    height: 100px;
  }
  .objetivos {
    height: 900px;
    position: relative;
    font-family: "Roboto";
    margin: 100px auto;
    text-align: center;
    color: $azul;
  }
  .objetivos-fondo-derecha {
    -webkit-clip-path: polygon(97% 0, 100% 0, 100% 100%, 73% 100%);
    clip-path: polygon(97% 0, 100% 0, 100% 100%, 73% 100%);
    background-image: url("~@/assets/fondo/triangle.png");
    height: 150vh;
    margin-top: -200px;
  }

  .objetivos-decorador-amarillo {
    background: $amarillo;
    height: 50px;
    width: 90%;
    margin-top: -200px;
    position: absolute;
    right: 20px;
    top: 0;
    -webkit-transform: skewX(-15deg);
    transform: skewX(-15deg);
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .objetivos-fondo-derecha {
    display: none;
  }
}

@media (min-width: 768px) and (max-height: 667px) {
  .objetivos {
    height: 700px;
    position: relative;
    font-family: "Roboto";
    margin: 500px auto;
    text-align: center;
    color: $azul;
  }
}
</style>