<template>
  <div>

    <div class="mapa-mobile hidden-sm-and-up">
      <div class="mapa-texto-top">
        <div class="mapa-titulo" :class="{ 'en-text': $i18n.locale === 'en' }">
          <span>{{ $t('mapa.titulo') }}</span>
        </div>
        <div class="mapa-alcance">
          <span>{{ $t('mapa.alcance') }}</span>

        </div>
      </div>

      <div class="mapa-mobile-punto-activo" v-if="puntoActivo">
        <div @click="irBotonMovil('prev')" class="boton-mapa"><i class="el-icon-caret-left"></i></div>
        <div @click="irBotonMovil('next')" class="boton-mapa"> <i class="el-icon-caret-right"></i> </div>
        <transition name="fade">
          <div class="mapa-punto-activo" v-show="puntoActivo">
            <h1>
              {{ puntoActivo.linea1 }}<br>
              <strong>
                {{ puntoActivo.linea2 }}
              </strong>
            </h1>
            <span class="linea-blanca" style="margin: 10px 0px;"></span>
            <h3>
              {{ puntoActivo.direccion }}
            </h3>
          </div>
        </transition>

      </div>
      <div class="mapa-svg">
        <mapa />
        <!-- <div class="mapa-decorador-amarillo"></div> -->

      </div>
      <div class="mapa-custodia" @click="activarMenu('servicios')">
        <span class="linea-naranja"></span>
        <h3>{{ $t('mapa.custodia.intro') }}</h3>
        <strong>{{ $t('mapa.custodia.linea1') }}</strong>
        <br>
        <strong>{{ $t('mapa.custodia.linea2') }} </strong> <i class="el-icon-arrow-down"></i>

      </div>
    </div>
    <div class="mapa hidden-xs-only">
      <div class="mapa-fondo-arriba"></div>
      <div class="mapa-fondo-abajo"></div>
      <div class="mapa-svg">
        <mapa />
        <div class="mapa-decorador-amarillo hidden-xs-only"></div>

      </div>
      <div class="mapa-texto-top">
        <div class="mapa-titulo">
          <span>{{ $t('mapa.titulo') }}</span>
        </div>
        <div class="mapa-alcance">
          <span>{{ $t('mapa.alcance') }}</span>

        </div>
        <div class="mapa-puntos">
          <div class="marcadores">
            <a v-for="(punto, index) in $t('mapa.puntos')" :key="punto.linea2" @click="irAlPunto(index)">
              <icon-mapa class="icon-map" :refe="'icon-' + index" :cantidad="$t('mapa.puntos')" />
            </a>
          </div>

          <transition name="slide">
            <div class="mapa-punto-activo" v-if="puntoActivo">
              <h1>
                {{ puntoActivo.linea1 }}<br>
                <strong>
                  {{ puntoActivo.linea2 }}
                </strong>
              </h1>
              <span class="linea-blanca"></span>
              <h3>
                {{ puntoActivo.direccion }}
              </h3>
            </div>
          </transition>


        </div>

      </div>
      <div class="mapa-custodia" @click="activarMenu('servicios')">
        <span class="linea-naranja"></span>
        <h3>{{ $t('mapa.custodia.intro') }}</h3>
        <div>
          <strong>{{ $t('mapa.custodia.linea1') }}</strong>
          <br>
          <strong>{{ $t('mapa.custodia.linea2') }} </strong> <i class="el-icon-arrow-down"></i>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import mapa from "@/components/mapa"
import iconMapa from '@/components/iconMapa'
import { EventBus } from '@/event-bus.js'
export default {
  components: {
    mapa,
    iconMapa,

  },
  data() {
    return {
      puntoActivo: undefined,
      puntos: undefined,
      puntoMapaMobile: 0
    }
  },
  created() {
    this.irAlPunto(0)
    EventBus.$on("ir-al-punto-activo", punto => {
      this.puntoActivo = undefined
      setTimeout(() => {
        this.puntoActivo = punto
      }, 10);
    });


    // setInterval(() => {
    //   this.irBotonMovil('next')
    // }, 5000);
  },

  methods: {
    irBotonMovil(direccion) {

      var total = this.$t('mapa.puntos').length ? this.$t('mapa.puntos').length : 0;
      if (direccion === 'next') {
        
        this.puntoMapaMobile = this.puntoMapaMobile + 1;
        if (this.puntoMapaMobile > total - 1) {
          this.puntoMapaMobile = 0
        }
      }
      else {
        this.puntoMapaMobile = this.puntoMapaMobile - 1;
        if (this.puntoMapaMobile < 0) {
          this.puntoMapaMobile = total - 1
        }
      }

      // setTimeout(() => {


      // }, 50);

      setTimeout(() => {
        this.irAlPunto(this.puntoMapaMobile)
        // console.log('totla ', this.$t('mapa.puntos'))
        console.log('this.puntoMapaMobile ', this.puntoMapaMobile)
      }, 150);


    },
    irAlPunto(index) {

      this.puntoActivo = undefined
      let total = this.$t('mapa.puntos');
      let puntos = this.$t('mapa.puntos').map((punto, i) => {
        if (i == index) {
          punto.activo = true
          setTimeout(() => {
            this.puntoActivo = punto
          }, 10);

        } else {
          punto.activo = false
        }

        return punto;
      })
      setTimeout(() => {
        EventBus.$emit('ir-al-punto', puntos);
      }, 100);

    },
    activarMenu(menu) {
      console.log(menu)
      this.$scrollTo('#' + menu, 500, { offset: -100 })
      EventBus.$emit('menu-activo', menu)

    }
  }
}
</script>

<style lang="scss">
.linea-naranja {
  display: block;
  width: 15%;
  border-bottom: 4px solid $naranja;
}

.mapa-custodia {
  position: relative;
  top: -50%;
  margin-bottom: 20px;
  left: 20%;
  width: 25%;

}

.mapa-custodia h3 {
  font-size: 30px;
  font-weight: 100;
  color: $naranja;
}

.mapa-custodia strong {
  font-size: 25px;
  padding: 2px 8px;
  font-weight: 500;
  background: $naranja;
  color: white;
  line-height: 1.5;
  text-transform: uppercase;
  cursor: pointer;
}

.mapa-custodia i {
  color: $naranja;
  background: white;
  font-weight: 700;
  padding: 2px 8px;
  font-size: 30px;
  cursor: pointer;
}

.mapa-decorador-amarillo {
  background: $amarillo;
  height: 100px;
  width: 60%;
  margin-top: 160px;
  position: absolute;
  right: -10px;
  -webkit-transform: skewX(-15deg);
  transform: skewX(-15deg);
}

.marcadores {
  transform: translateX(0px);
}

.mapa-puntos {
  position: absolute;
  margin-top: 150px;
  // margin-left: -20px;
  transition: all 0.3s;
  margin-left: -131px;
}

.mapa-puntos a>img,
.icon-map {
  height: 20px;
  cursor: pointer;
  display: inline-block;
  // margin-left: 5px;
}

.mapa {
  height: 200vh;
  position: relative;
  font-family: 'Roboto';
  margin-top: 0px;

}

.mapa-mobile {
  height: 100vh;
  position: relative;
  font-family: 'Roboto';

}

.mapa-fondo-arriba {
  -webkit-clip-path: polygon(0 0, 0% 100%, 20% 100%);
  clip-path: polygon(0 0, 0% 100%, 20% 100%);
  background-image: url('~@/assets/fondo/triangle.png');
  height: 100vh;
  margin-top: 0px;
}

.mapa-fondo-abajo {
  -webkit-clip-path: polygon(0 0, 0% 100%, 20% 0);
  clip-path: polygon(0 0, 0% 100%, 20% 0);
  background-image: url('~@/assets/fondo/triangle.png');
  height: 100vh;
  margin-top: 0px;
}

.mapa-svg {
  position: absolute;
  top: 0;
  // left: 50%;
  right: 10%;
  width: 30%;
}

.mapa-texto-top {
  position: absolute;
  top: 40px;
  margin-bottom: 20px;
  left: 20%;

}

.mapa-titulo {
  font-size: 45px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
  -webkit-transform: skewX(-15deg);
  transform: skewX(-15deg);
}

.mapa-titulo span:after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: $naranja;
  transform-origin: bottom left;

  border-radius: 4px;
  padding: 0px 5px;
}

.mapa-titulo span {
  background: $naranja;
  border-radius: 4px;
  padding: 15px;

  position: relative;
  color: #FFF;



}

.mapa-punto-activo h1 {
  font-weight: 300;
  margin: 0;
  padding: 0;
  font-size: 34px;
  text-transform: uppercase;
}

.mapa-punto-activo h3 {
  font-weight: 300;
  margin: 0;
  padding: 0;
  font-size: 20px;
}

.linea-blanca {
  display: block;
  width: 15%;
  border-bottom: 4px solid #FFF;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mapa-punto-activo {

  background: $amarillo;
  border-radius: 4px;
  padding: 25px 15px;
  width: 500px;

  position: absolute;
  color: white;


  font-family: 'Roboto';

  line-height: 1.2;
  margin-top: -20px;
  margin-left: -20px;



}

.mapa-punto-activo:after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: $amarillo;
  transform-origin: bottom left;
  -ms-transform: skew(-20deg, 0deg);
  -webkit-transform: skew(-20deg, 0deg);
  transform: skew(-20deg, 0deg);
  border-radius: 4px;
  padding: 0px 10px;
}


.mapa-alcance {


  -webkit-transform: skewX(-15deg);
  transform: skewX(-15deg);



}

.mapa-alcance span:after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: white;
  transform-origin: bottom left;

  border-radius: 4px;
  padding: 0px 5px;
}

.mapa-alcance span {
  background: white;
  border-radius: 4px;
  padding: 15px;

  margin-top: 30px;
  display: block;
  float: right;
  border: 1px solid;
  font-size: 28px;
  position: relative;
  color: $naranja;



}

@media screen and (max-width:1440px) {

  .mapa-fondo-arriba {

    margin-top: 0px;
  }

  .mapa-svg {
    position: absolute;
    top: 0px;
    left: 65%;
    right: 0;
    width: 30%;
  }

  .mapa-texto-top {
    position: absolute;
    top: 30px;
    margin-bottom: 20px;
    left: 20%;

  }

  .mapa-titulo {
    font-size: 40px;

  }

  .mapa-alcance span {
    font-size: 22px;
  }

  .mapa-punto-activo {
    width: 400px;
  }

  .mapa-punto-activo h3 {

    font-size: 16px;
  }

  .mapa-punto-activo h1 {

    font-size: 30px;

  }


  .mapa-punto-activo {

    padding: 25px 15px;

  }

  .mapa {

    margin-top: 100px;

  }

}

@media (min-width:768px) and (max-height:650px) {



  .mapa-fondo-arriba {

    margin-top: 250px;
  }

}

@media screen and (max-width:1024px) {

  .marcadores {
    transform: translateX(0px);
  }

  .mapa-fondo-arriba {

    margin-top: 100px;
  }

  .mapa-svg {
    position: absolute;
    top: 0px;
    left: 60%;
    right: 0;
    width: 30%;
  }

  .mapa-texto-top {
    position: absolute;
    top: 30px;
    margin-bottom: 20px;
    left: 10%;

  }

  .mapa-titulo {
    font-size: 40px;

  }

  .mapa-punto-activo h1 {

    font-size: 20px;

  }

  .mapa-punto-activo h3 {

    font-size: 16px;

  }

  .mapa-puntos {

    margin-left: 30px;
  }

  .mapa-punto-activo {

    padding: 15px;
    width: 350px;
    color: white;

    margin-left: 0px;
    // width: 60%;
  }


}

@media screen and (max-width:768px) {
  .en-text {
    font-size: 21px !important;
  }

  .boton-mapa {
    border: 1.4px solid $naranja;
    color: $naranja;
    margin-right: 10px;
    display: inline-block;
    padding: 3.1px 12px;
    border-radius: 6px;
    transform: translateY(-7.5px);
    transition: all 0.3s;
    z-index: 2;
    position: relative;
  }

  .boton-mapa:hover {
    background: $naranja;
    color: white;
  }

  .mapa-svg {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin-top: 0;
  }

  .mapa-texto-top {
    position: relative;
    margin-bottom: 150px;
    width: 80% !important;
  }

  .mapa-titulo {
    font-size: 24px;
    -webkit-transform: skewX(0deg);
    transform: skewX(0deg);
    margin-left: 5px;
    position: relative;

  }

  .mapa-titulo span:after {
    -ms-transform: skew(-20deg, 0deg);
    -webkit-transform: skew(-20deg, 0deg);
    transform: skew(-20deg, 0deg);
  }

  .mapa-alcance {
    //  -webkit-transform:    skewX(0deg);
    //     transform:    skewX(0deg);
  }

  .mapa-alcance:after {
    -ms-transform: skew(-20deg, 0deg);
    -webkit-transform: skew(-20deg, 0deg);
    transform: skew(-20deg, 0deg);
  }

  .mapa-alcance span:after {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: white;
    transform-origin: bottom left;

    padding: 0px 5px;
  }

  .mapa-alcance span {
    padding: 15px 20px;
    margin-top: 25px;
    font-size: 18px;
    // float: left;
    position: relative;
  }

  .mapa-mobile-punto-activo {
    position: absolute;
    // bottom: -130px;
    top: 150vw;
    width: 40%;
    left: 50%;
    // padding-bottom: 500px;
  }

  .mapa-punto-activo h1 {
    font-weight: 300;
    margin: 0;
    padding: 0;
    font-size: 16px;
    text-transform: uppercase;
  }

  .mapa-punto-activo h3 {
    font-weight: 300;
    margin: 0;
    padding: 0;
    font-size: 12px;
    width: 90%;
  }

  .linea-blanca {
    display: block;
    width: 15%;
    border-bottom: 4px solid #FFF;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mapa-punto-activo {
    border-radius: 0px;
    padding: 10px;
    width: 100%;
    max-width: 100%;
    position: relative;
    margin-top: 0px;
    height: 150px;
    margin-left: 0px;



  }

  .mapa-punto-activo:after {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: $amarillo;
    transform-origin: bottom left;
    -ms-transform: skew(0deg);
    -webkit-transform: skew(0deg);
    transform: skew(0deg);
    border-radius: 0px;
    padding: 0px 5px;
  }

  .linea-naranja {
    display: block;
    width: 15%;
    border-bottom: 4px solid $naranja;
  }

  .mapa-custodia {
    position: relative;
    top: 0%;
    margin-bottom: 20px;
    left: 0%;
    width: 90%;
    z-index: 2;

  }

  .mapa-custodia h3 {
    font-size: 26px;
    padding: 10px;

  }

  .mapa-custodia strong {
    font-size: 20px;

  }

  .mapa-custodia i {
    color: $naranja;
    background: white;
    font-weight: 700;
    padding: 2px 8px;
    font-size: 24px;
    cursor: pointer;
  }

}

@media	only screen and (min-resolution: 120dpi) {

  .mapa {
    margin-top: 150px;
  }

  .mapa-decorador-amarillo {

    margin-top: 0px;

  }



}

@media (max-width: 320px) and (max-height:480px) {

  .mapa-mobile-punto-activo {
    position: absolute;
    // bottom: -130px;
    top: 170vw;
    width: 40%;
    left: 50%;
    // padding-bottom: 500px;
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

  /* IE10+ CSS styles go here */
  .mapa-fondo-arriba {
    display: none;
    -webkit-clip-path: polygon(0 0, 0% 100%, 20% 100%);
    clip-path: polygon(0 0, 0% 100%, 20% 100%);
    background-image: url('~@/assets/fondo/triangle.png');
    height: 100vh;
    margin-top: -100px;
  }

  .mapa-fondo-abajo {
    display: none;
    -webkit-clip-path: polygon(0 0, 0% 100%, 20% 0);
    clip-path: polygon(0 0, 0% 100%, 20% 0);
    background-image: url('~@/assets/fondo/triangle.png');
    height: 100vh;
    margin-top: 0px;
  }

  .mapa-custodia {
    position: absolute;
    top: 60%;
    margin-bottom: 20px;
    left: 20%;
    width: 30%;

  }
}</style>