<template>
  <div class="galeria-content">
    <div class="container w80">
      <div class="log-galeria">
        <!-- swiper1 -->
        <swiper :options="swiperOptionTop" class="gallery-top" ref="swiperTop">
          <swiper-slide :style="'background:url(' +img1+ ')'"></swiper-slide>
          <swiper-slide :style="'background:url(' +img2+ ')'"></swiper-slide>
          <swiper-slide :style="'background:url(' +img3+ ')'"></swiper-slide>
          <swiper-slide :style="'background:url(' +img4+ ')'"></swiper-slide>
          <swiper-slide :style="'background:url(' +img5+ ')'"></swiper-slide>
          <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
          <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        </swiper>
      </div>
    </div>

    <div class="container w80">
      <!-- swiper2 Thumbs -->
      <swiper
        :options="swiperOptionThumbs"
        class="gallery-thumbs hidden-xs-only"
        ref="swiperThumbs"
      >
        <swiper-slide :style="'background:url(' +img1+ ')'"></swiper-slide>
        <swiper-slide :style="'background:url(' +img2+ ')'"></swiper-slide>
        <swiper-slide :style="'background:url(' +img3+ ')'"></swiper-slide>
        <swiper-slide :style="'background:url(' +img4+ ')'"></swiper-slide>
        <swiper-slide :style="'background:url(' +img5+ ')'"></swiper-slide>
      </swiper>
    </div>
    <div class="galeria-footer"></div>
  </div>
</template>

<script>
import img1 from "@/assets/fotos/foto_1.jpg";
import img2 from "@/assets/fotos/foto_2.jpg";
import img3 from "@/assets/fotos/foto_3.jpg";
import img4 from "@/assets/fotos/foto_4.jpg";
import img5 from "@/assets/fotos/foto_5.jpg";

export default {
  data() {
    return {
      img1: img1,
      img2: img2,
      img3: img3,
      img4: img4,
      img5: img5,
      swiperOptionTop: {
        spaceBetween: 20,
        effect: "fade",
        loop: true,
        loopedSlides: 5, //looped slides should be the same
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      swiperOptionThumbs: {
        spaceBetween: 20,
        slidesPerView: 5,
        touchRatio: 0.2,
        centeredSlides: true,
        loop: true,
        loopedSlides: 5, //looped slides should be the same
        slideToClickedSlide: true
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper;
      const swiperThumbs = this.$refs.swiperThumbs.swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    });
  }
};
</script>

<style lang="scss" scoped>
.w80 {
  width: 80%;
}

.galeria-footer {
  background: $amarillo;
  height: 200px;
  margin-top: -100px;
}
.log-galeria {
  height: 800px;
}
.galeria-content {
  margin: 0 auto;
  // margin-top: 400px;
}
.swiper-container {
  background-color: transparent;
}
.swiper-slide {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: repeat-x !important;
  
// object-fit: fill
}
.gallery-top {
  height: 100% !important;
  width: 100%;
  margin: 0 auto;
  border: 2px #333;
}
.gallery-thumbs {
  height: 200px !important;
  box-sizing: border-box;
  padding: 10px 0;
  margin-top: 50px;
}
.gallery-thumbs .swiper-slide {
  width: 20% !important;
  height: 100%;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}
.gallery-thumbs .swiper-slide-active {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  filter: grayscale(0%);
}

@media screen and (max-width: 1476px) {
  .log-galeria {
    height: 500px;
  }
}

@media screen and (max-width:1366px) {
    .gallery-thumbs {

    margin-top: 50px;
    
    
  }
}
@media screen and (max-width: 768px) {
  .w80 {
    width: 100%;
  }
  .log-galeria {
    height: 50vh;
    margin-top: 600px;
    // margin-bottom: 15vw;
  }
  .galeria-content {
    margin: 0 auto;
  }
}

@media screen and (max-height: 667px) {
  .log-galeria {
    height: 300px;
    margin-top: -500px;
    margin-bottom: 15vw;
  }
  .galeria-content {
    margin: 0 auto;
    margin-top: 700px;
  }
}

@media (min-width: 768px) and (max-height: 667px) {
  .log-galeria {
    height: 50vw;
    margin-top: -500px;
    margin-bottom: 15vw;
  }
  .galeria-content {
    margin: 0 auto;
    margin-top: 0px;
  }
      .gallery-thumbs {

    margin-top: -100px;
    
    
  }
}
</style>