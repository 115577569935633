<template>
  <div>
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 856 1666.1"
      style="enable-background: new 0 0 856 1666.1"
      xml:space="preserve"
    >
      <g>
        <line class="st0" x1="240.5" y1="1536.2" x2="240.5" y2="1534.9" />
        <line class="st1" x1="240.3" y1="1531.5" x2="240.2" y2="1528.1" />
        <polyline
          class="st0"
          points="240.2,1526.5 240.1,1525.1 241.4,1525.1 	"
        />
        <line class="st1" x1="244.8" y1="1525" x2="248.2" y2="1524.8" />
        <line class="st0" x1="249.9" y1="1524.8" x2="251.2" y2="1524.7" />
        <path class="st2" d="M269.6,1614.2c-0.5-24.4-10.2-68.6-26.1-85.9" />
        <line class="st0" x1="185.2" y1="1617.2" x2="184.3" y2="1616.2" />
        <line class="st1" x1="182" y1="1613.8" x2="179.6" y2="1611.4" />
        <line class="st1" x1="180.9" y1="1606" x2="183.3" y2="1603.6" />
        <line class="st0" x1="184.6" y1="1602.5" x2="185.5" y2="1601.5" />
        <path class="st3" d="M266.1,1623c-25.2-15.8-65-15.4-84.4-13.8" />
        <path class="st0" d="M179,1609.5l-1.3,0.1" />
      </g>
      <g>
        <line class="st0" x1="180.1" y1="1363.2" x2="180.1" y2="1361.9" />
        <line class="st1" x1="179.9" y1="1358.5" x2="179.8" y2="1355.1" />
        <polyline class="st0" points="179.8,1353.5 179.7,1352.1 181,1352.1 	" />
        <line class="st1" x1="184.4" y1="1352" x2="187.8" y2="1351.8" />
        <line class="st0" x1="189.5" y1="1351.8" x2="190.8" y2="1351.7" />
        <path class="st2" d="M209.2,1441.2c-0.5-24.4-10.2-68.6-26.1-85.9" />
        <line class="st0" x1="124.8" y1="1444.2" x2="123.9" y2="1443.2" />
        <line class="st1" x1="121.6" y1="1440.8" x2="119.2" y2="1438.4" />
        <line class="st1" x1="120.5" y1="1433" x2="122.9" y2="1430.6" />
        <line class="st0" x1="124.2" y1="1429.5" x2="125.1" y2="1428.5" />
        <path class="st3" d="M205.7,1450c-25.2-15.8-65-15.4-84.4-13.8" />
        <path class="st0" d="M118.6,1436.5l-1.3,0.1" />
      </g>
      <g>
        <line class="st0" x1="206" y1="1093.3" x2="206" y2="1092.1" />
        <line class="st1" x1="205.8" y1="1088.7" x2="205.7" y2="1085.2" />
        <polyline
          class="st0"
          points="205.7,1083.7 205.6,1082.2 206.9,1082.2 	"
        />
        <line class="st1" x1="210.3" y1="1082.2" x2="213.7" y2="1081.9" />
        <line class="st0" x1="215.4" y1="1081.9" x2="216.7" y2="1081.8" />
        <path class="st2" d="M235.1,1171.3c-0.5-24.4-10.2-68.6-26.1-85.9" />
        <line class="st0" x1="150.7" y1="1174.3" x2="149.8" y2="1173.3" />
        <line class="st1" x1="147.5" y1="1170.9" x2="145.1" y2="1168.6" />
        <line class="st1" x1="146.4" y1="1163.2" x2="148.8" y2="1160.8" />
        <line class="st0" x1="150.1" y1="1159.7" x2="151" y2="1158.7" />
        <path class="st3" d="M231.6,1180.2c-25.2-15.8-65-15.4-84.4-13.8" />
        <path class="st0" d="M144.5,1166.7l-1.3,0.1" />
      </g>
      <g>
        <line class="st0" x1="286.1" y1="974" x2="286.1" y2="972.7" />
        <line class="st1" x1="285.9" y1="969.3" x2="285.8" y2="965.9" />
        <polyline class="st0" points="285.8,964.3 285.7,962.9 287,962.9 	" />
        <line class="st1" x1="290.4" y1="962.8" x2="293.8" y2="962.6" />
        <line class="st0" x1="295.5" y1="962.6" x2="296.8" y2="962.5" />
        <path class="st2" d="M315.2,1052c-0.5-24.4-10.2-68.6-26.1-85.9" />
        <line class="st0" x1="230.8" y1="1055" x2="229.9" y2="1054" />
        <line class="st1" x1="227.6" y1="1051.6" x2="225.2" y2="1049.2" />
        <line class="st1" x1="226.5" y1="1043.8" x2="228.9" y2="1041.4" />
        <line class="st0" x1="230.2" y1="1040.3" x2="231.1" y2="1039.3" />
        <path class="st3" d="M311.7,1060.8c-25.2-15.8-65-15.4-84.4-13.8" />
        <path class="st0" d="M224.6,1047.3l-1.3,0.1" />
      </g>
      <g class="st4">
        <path
          class="st5"
          d="M199.8,893.4c-0.4,4.3-2.9,6.7-7,7c-3.9,0-7-3.2-6.9-7c0-0.1,0-0.3,0-0.4c0.4-4.1,2.9-6.6,7.2-6.4
		S199.4,889.5,199.8,893.4z"
        />
        <path
          class="st5"
          d="M346.5,952.3c-0.2,3.5-2.6,6-6.8,6.1c-4.2,0.1-6.8-2.3-7.2-6.5c-0.3-3.8,3.2-7.3,7.1-7.2
		C343.5,944.8,346.4,947.7,346.5,952.3z"
        />
        <path
          class="st5"
          d="M515.2,434.5c-3.9-0.7-6.7-2.7-6.6-7.1c0.1-4.3,2.8-6.6,6.8-6.9c3.7-0.3,7.4,3.8,7.1,7.3
		C522,432,519.6,434.4,515.2,434.5z"
        />
        <path
          class="st5"
          d="M193.1,1177.5c4.2,0.6,6.7,2.9,6.6,7.1c-0.1,4.1-2.7,6.5-6.8,6.7c-3.8,0.1-6.8-2.8-7-6.5c0-0.3,0-0.5,0-0.7
		C186.4,1179.9,188.9,1177.8,193.1,1177.5z"
        />
        <path
          class="st5"
          d="M662.5,434.5c-4-0.4-6.8-2.3-7.1-6.5s2.3-6.7,6.2-7.3c3.7-0.5,7.9,3.1,7.5,6.7
		C668.9,431.5,666.9,434,662.5,434.5z"
        />
        <path
          class="st5"
          d="M815.8,253.3c-0.5,3.5-2.3,6.3-6.8,6.4c-4.2,0.1-7.4-2.9-7-7.1c0.3-4.1,2.5-6.6,6.9-6.6
		C812.9,246.2,815.8,249.1,815.8,253.3z"
        />
        <path
          class="st5"
          d="M199.8,660.7c-0.4,4.1-2.8,6.8-7,6.7s-6.9-2.7-6.8-6.9s2.7-6.8,6.9-6.7S199.5,656.4,199.8,660.7z"
        />
        <path
          class="st5"
          d="M522.6,543.8c-0.3,3.8-1.9,6.7-6.5,6.9c-4.3,0.3-7.6-2.5-7.4-6.5s2.3-6.9,6.7-6.9
		C519.4,537.3,522,539.4,522.6,543.8z"
        />
        <path
          class="st5"
          d="M515.1,391.6c4.2,0.5,7,2.2,7.4,6.5c0.3,4.1-2.7,7.1-6.9,7.1c-4.4,0-6.8-2.4-6.8-6.5
		C508.7,394.6,511,392.1,515.1,391.6z"
        />
        <path
          class="st5"
          d="M610.4,806.4c-0.3,3.8-2.5,6.4-6.7,6.6c-4.2,0.2-6.9-2.4-7-6.7s2.5-7,6.7-7.1
		C607.5,799.1,610,801.5,610.4,806.4z"
        />
        <path
          class="st5"
          d="M332.6,806.3c0.3-4.6,2.8-6.8,6.8-7.1c3.9-0.3,7.2,3.2,6.9,7.2c-0.3,4.2-3,6.6-7.1,6.4
		C335.2,812.6,332.9,810.2,332.6,806.3z"
        />
        <path
          class="st5"
          d="M661.5,391.7c4.8,0.2,8.2,3.6,7.6,7.3c-0.5,3.9-2.9,6.6-7.3,6.2c-4.2-0.3-6.8-2.7-6.6-6.9
		C655.5,394.2,657.9,391.9,661.5,391.7z"
        />
        <path
          class="st5"
          d="M317,806.7c-0.3,3.8-2.9,6.2-7,6.1c-4.2-0.1-6.7-2.7-6.7-6.9s2.8-6.5,6.8-6.9
		C313.9,798.8,317.1,802.3,317,806.7z"
        />
        <path
          class="st5"
          d="M170.3,805.9c-0.1,4.3-2.5,6.9-6.7,7s-6.8-2.5-7-6.7c-0.3-4.4,2.7-6.9,6.7-7.1
		C167.4,799,170,801.5,170.3,805.9z"
        />
        <path
          class="st5"
          d="M663.6,770.2c3.1,0.2,5.5,2.7,5.5,6.8s-2.4,6.7-6.8,6.8c-3.9,0.1-7-2.9-7.2-6.7c0-0.1,0-0.3,0-0.4
		C655.4,772.9,658.6,770.1,663.6,770.2z"
        />
        <path
          class="st5"
          d="M317,951.7c-0.3,4-2.5,6.5-6.8,6.8c-3.9,0.3-7.3-3.3-7-7.1c0.3-4.3,3-6.7,7-6.6
		C314.2,944.8,316.8,947.3,317,951.7z"
        />
        <path
          class="st5"
          d="M538.1,601.2c0.2-3.3,2.6-5.5,6.8-5.9c3.8-0.4,7,3.1,6.9,7.2c-0.1,4.5-3,6.3-7,6.6
		C540.9,609.5,538.1,606.3,538.1,601.2z"
        />
        <path
          class="st5"
          d="M199.8,951.3c-0.2,4.3-2.5,6.9-6.8,7c-4.2,0.1-6.9-2.3-7.1-6.6c-0.2-4.3,2.5-7,6.7-7.1
		C196.8,944.6,199.5,947,199.8,951.3z"
        />
        <path
          class="st5"
          d="M802,224.5c0.2-4.9,3.6-8.1,7.5-7.6c4.3,0.5,6.7,3.3,6.4,7.3s-2.9,6.8-7.1,6.5
		C804.7,230.5,802.3,227.9,802,224.5z"
        />
        <path
          class="st5"
          d="M426.9,725.6c-3.6-0.5-6.1-2.7-6.5-6.8c-0.1-3.8,2.9-6.9,6.8-7c0.2,0,0.3,0,0.5,0c4.1,0.5,6.7,2.7,6.7,7.1
		C434.6,722.6,431.1,725.7,426.9,725.6z"
        />
        <path
          class="st5"
          d="M252,770.2c3.6,0.2,6.1,2.2,6.4,6.5c0.3,3.6-2.4,6.8-6,7.1c-0.3,0-0.6,0-1,0c-4.2-0.2-6.6-2.5-6.8-6.8
		C244.7,773,247.5,770.3,252,770.2z"
        />
        <path
          class="st5"
          d="M456.6,741c4.3,0.3,6.8,2.4,6.9,6.7s-2.9,7.3-7,7s-6.8-2.7-6.7-6.9C450.1,743.5,452.7,741.3,456.6,741z"
        />
        <path
          class="st5"
          d="M229.1,1359.4c-0.5,4.1-2.7,6.6-7,6.5s-6.7-2.5-6.8-6.6c-0.1-3.7,2.8-6.8,6.5-6.9c0.3,0,0.5,0,0.8,0
		C226.9,1352.6,228.8,1355.3,229.1,1359.4z"
        />
        <path
          class="st5"
          d="M317,1097.3c-0.4,3.8-2.6,6.7-6.9,6.6s-6.8-2.7-6.8-6.9c-0.1-4.2,2.8-6.8,6.8-6.9S316.8,1092.8,317,1097.3z"
        />
        <path
          class="st5"
          d="M479.2,747.6c0.3-3.8,2.4-6.4,6.7-6.6c4.2-0.2,6.8,2.4,7.1,6.4c0.3,3.7-2.4,7-6.1,7.3c-0.3,0-0.5,0-0.8,0
		C482,754.5,479.7,752.2,479.2,747.6z"
        />
        <path
          class="st5"
          d="M368.6,289c-3.8-0.7-6.7-2.7-6.8-7c-0.1-3.9,3.6-7.4,7.4-6.9c4.1,0.5,6.8,2.9,6.5,7.1
		C375.6,286.4,373.1,288.7,368.6,289z"
        />
        <path
          class="st5"
          d="M133.9,550.8c-4.2-0.4-6.9-2.8-6.7-7c0.2-3.9,2.8-6.7,7.1-6.5s6.8,2.7,6.8,7
		C141,548.5,138.1,550.6,133.9,550.8z"
        />
        <path
          class="st5"
          d="M199.8,515.8c-0.3,3.5-2.7,5.9-6.8,5.9s-6.9-2.4-7-6.5c-0.1-4.1,2.4-6.7,6.6-7.1
		C196.4,507.6,199.7,511,199.8,515.8z"
        />
        <path
          class="st5"
          d="M193.3,1323.2c3.9,0.6,6.5,2.8,6.2,7.1c-0.2,4.2-2.7,6.5-6.8,6.7c-3.8,0-6.9-3-6.9-6.8c0-0.2,0-0.4,0-0.5
		C186.5,1325.5,188.9,1323.4,193.3,1323.2z"
        />
        <path
          class="st5"
          d="M163.5,1323.2c4.3,0.6,6.8,2.7,6.8,7.1s-2.9,6.8-7,6.6c-4.2-0.2-6.8-2.7-6.7-7.1
		C156.7,1325.5,159.4,1323.5,163.5,1323.2z"
        />
        <path
          class="st5"
          d="M515.5,129.6c4.1,0.5,6.8,2.7,7.1,6.8c0.3,3.8-3.6,7.5-7.3,7.1c-4.1-0.5-6.7-2.7-6.8-7
		C508.6,132.7,511.6,129.7,515.5,129.6z"
        />
        <path
          class="st5"
          d="M809.6,289c-4.6,0-8-3.3-7.6-7.2c0.3-3.9,2.7-6.2,6.6-6.6s7.6,3.3,7.4,7C815.5,286.2,813.3,288.6,809.6,289z"
        />
        <path
          class="st5"
          d="M134.7,1294.1c3.6,0.5,6.5,2.5,6.4,6.8c-0.1,4.1-2.4,6.6-6.7,6.9c-3.9,0.2-7.1-2.8-7.2-6.7c0-0.1,0-0.2,0-0.4
		C127.5,1296.8,130.8,1294,134.7,1294.1z"
        />
        <path
          class="st5"
          d="M303.3,746.9c1-3.9,3.3-6.2,7.6-5.9c4.3,0.4,6.4,3.2,6,7.4c-0.3,4.5-3.3,6.4-7.4,6
		C305.3,754,303.1,751.3,303.3,746.9z"
        />
        <path
          class="st5"
          d="M515.5,580.1c-3.9-0.5-6.7-2.5-6.8-6.7c-0.2-4.2,2.4-6.7,6.6-7.1c3.9-0.4,7.8,3.3,7.4,6.9
		C522.2,577.3,519.9,579.8,515.5,580.1z"
        />
        <path
          class="st5"
          d="M508.4,282.1c0.4-4.1,2.8-6.9,6.8-7c4-0.1,6.8,2.4,7.3,6.5c0.3,3.8-3.3,7.6-7,7.3
		C511.4,288.6,509.1,286.4,508.4,282.1z"
        />
        <path
          class="st5"
          d="M221.1,1220.2c-3-0.5-5.7-2.5-5.9-6.7c-0.2-4,3-7,7.3-6.9c4.5,0.2,6.7,3.1,6.6,7
		C229,1217.5,226.7,1220.4,221.1,1220.2z"
        />
        <path
          class="st5"
          d="M574,580.1c-3.8-0.3-6.4-2.3-6.7-6.6c-0.3-3.8,3.2-7.5,7.1-7.1c4,0.4,6.8,2.6,6.8,6.9
		C581.3,576.9,578,580,574,580.1z"
        />
        <path
          class="st5"
          d="M493.2,515.4c-0.4,3.8-2.7,6.3-6.9,6.3c-4.2,0-6.8-2.5-6.9-6.7c-0.3-3.8,3.3-7.5,7.1-7.1
		C490.7,508.4,492.9,510.8,493.2,515.4z"
        />
        <path
          class="st5"
          d="M514.1,100.6c5.3-0.1,8.6,2.9,8.3,6.9c-0.3,4.1-2.6,6.7-6.8,6.8s-6.8-2.4-6.9-6.6
		C508.6,103.6,510.8,100.9,514.1,100.6z"
        />
        <path
          class="st5"
          d="M368.7,929.4c-3.9-0.3-6.5-2.5-6.9-6.6c-0.3-3.9,2.9-7.1,7.1-7.1c4.4,0.1,6.8,2.7,6.8,6.8
		C375.8,926.5,373.4,929.1,368.7,929.4z"
        />
        <path
          class="st5"
          d="M427.9,580.1c-4.3-0.4-6.9-2.5-7.3-6.7c-0.3-3.8,3.3-7.5,7.2-7.1c4.1,0.4,6.8,2.7,6.8,6.9
		C434.5,577.3,431.8,579.6,427.9,580.1z"
        />
        <path
          class="st5"
          d="M367.9,1657c-3.3-0.2-5.9-2.4-6-6.5s2.3-6.7,6.6-7.1c4-0.3,7.8,3.1,7.4,6.9
		C375.6,1654.3,373.2,1656.8,367.9,1657z"
        />
        <path
          class="st5"
          d="M170.4,951.9c-0.3,3.9-2.6,6.4-6.8,6.6c-3.8,0-6.9-3.1-6.9-6.9c0-0.2,0-0.3,0-0.5c0.3-4.4,3.3-6.6,7.1-6.4
		C167.5,944.9,170.3,947.4,170.4,951.9z"
        />
        <path
          class="st5"
          d="M163.6,741c4.2,0.5,6.7,2.6,6.7,7c0,4-3.4,7.4-7.3,6.9c-4.1-0.5-6.6-3-6.4-7.2
		C156.9,743.4,159.5,741.3,163.6,741z"
        />
        <path
          class="st5"
          d="M251.4,915.6c4.3,0.3,6.8,2.4,6.9,6.6s-2.3,6.7-6.4,7c-3.6,0.4-6.8-2.3-7.2-5.9c0-0.4,0-0.6,0-1
		C244.8,918,247.4,916.1,251.4,915.6z"
        />
        <path
          class="st5"
          d="M310,900.3c-4.2-0.6-6.8-2.7-6.7-7.1c0.1-4.4,2.6-6.6,6.8-6.7c4.2-0.1,7,3,6.8,7.1
		C316.8,898.2,313.9,899.9,310,900.3z"
        />
        <path
          class="st5"
          d="M163.3,900.3c-4.2-0.5-6.8-2.8-6.6-7.1s2.7-6.7,7-6.6s6.7,2.7,6.6,7C170.2,897.9,167.3,899.9,163.3,900.3z"
        />
        <path
          class="st5"
          d="M515.5,857.4c4.2,0.5,6.8,2.6,7.1,6.8c0.1,3.8-2.9,6.9-6.7,6.9c-0.2,0-0.4,0-0.6,0c-4.2-0.4-6.7-2.7-6.6-7
		C508.8,859.9,511.5,857.8,515.5,857.4z"
        />
        <path
          class="st5"
          d="M434.6,864.5c-0.9,3.8-2.8,6.5-7.1,6.6c-3.9,0.1-7.4-3.6-6.9-7.2c0.5-4,3-6.6,7.3-6.3
		C432.1,857.8,434.3,860.3,434.6,864.5z"
        />
        <path
          class="st5"
          d="M229.1,865.1c-0.5,3.8-2.9,6.1-7.2,6c-4-0.1-7.2-3.8-6.6-7.6c0.6-4.1,3.2-6.2,7.4-6
		C226.7,857.8,229.4,861,229.1,865.1z"
        />
        <path
          class="st5"
          d="M258.4,196c-0.3,3.2-2.8,5.5-6.9,5.6c-4.2,0.1-6.9-3.2-6.7-7.4c0.3-4.4,3-6.2,7.2-6.1
		C255.9,188.1,258.5,191,258.4,196z"
        />
        <path
          class="st5"
          d="M397.9,871.2c-4.2-0.6-6.4-2.8-6.5-7.1c-0.1-3.7,2.8-6.7,6.5-6.8c0.3,0,0.6,0,1,0c4.2,0.5,6.5,3,6.1,7.3
		C404.8,869,402.1,870.8,397.9,871.2z"
        />
        <path
          class="st5"
          d="M258.5,864.4c-0.4,3.9-2.5,6.6-6.8,6.6s-6.7-2.4-6.8-6.6c-0.2-4.1,3-7.5,7-7.1
		C255.9,857.7,258.1,860.1,258.5,864.4z"
        />
        <path
          class="st5"
          d="M551.8,835.1c-0.3,4.2-2.8,6.9-6.9,6.9c-4.2,0-6.8-2.5-6.8-6.8s2.6-6.7,6.8-6.8
		C549.3,828.3,551.2,831.2,551.8,835.1z"
        />
        <path
          class="st5"
          d="M398.2,828.2c4.1,0.4,6.7,2.5,6.8,6.6c0.2,4.1-2.4,6.7-6.4,7.2s-7.3-2.8-7.3-6.9
		C391.4,830.8,393.9,828.8,398.2,828.2z"
        />
        <path
          class="st5"
          d="M405.1,195.7c-0.2,3.3-2.6,5.6-6.8,6s-7.1-3-6.9-7.1c0.2-4.4,2.7-6.4,6.9-6.5
		C402.3,188,405.1,190.8,405.1,195.7z"
        />
        <path
          class="st5"
          d="M244.8,833.7c0.3-3.1,2.8-5.3,6.9-5.3c4.3,0,6.8,2.7,6.8,6.8s-2.5,6.7-6.8,7
		C247.6,842.3,244.7,839,244.8,833.7z"
        />
        <path
          class="st5"
          d="M141.2,835.2c-0.4,4.1-2.7,6.7-6.9,6.8s-6.7-2.6-7.1-6.6c-0.4-3.8,3.2-7.3,7.2-7.1
		C138.8,828.5,140.7,831.2,141.2,835.2z"
        />
        <path
          class="st5"
          d="M486.2,201.6c-4.2-0.5-6.9-2.7-6.8-7s2.6-6.7,6.9-6.6s6.8,2.6,6.8,6.8C492.9,199.1,490.3,201.3,486.2,201.6z"
        />
        <path
          class="st5"
          d="M163.9,1468.8c4.2,0.5,6.6,2.8,6.5,7.1c-0.1,4.2-2.9,6.7-7,6.6s-6.8-2.7-6.6-7.1
		C156.9,1471.1,159.5,1469.1,163.9,1468.8z"
        />
        <path
          class="st5"
          d="M544.3,201.6c-3.4-0.5-6.1-2.4-6.1-6.8c-0.2-3.6,2.6-6.7,6.1-6.9c0.3,0,0.6,0,1,0c4.2,0.4,6.5,2.7,6.5,7
		C551.7,198.7,548.6,201.6,544.3,201.6z"
        />
        <path
          class="st5"
          d="M639.9,486.2c-0.6,3.8-2.6,6.5-7,6.4s-6.7-2.6-6.9-6.7c-0.2-3.7,2.7-6.8,6.4-6.9c0.3,0,0.5,0,1,0
		C637.4,479.3,639.5,481.8,639.9,486.2z"
        />
        <path
          class="st5"
          d="M309.8,609.2c-4.1-0.6-6.7-2.7-6.5-7.1c0.2-4.1,2.5-6.6,6.8-6.6s7.1,2.9,6.8,7.1
		C316.6,606.9,314,608.8,309.8,609.2z"
        />
        <path
          class="st5"
          d="M632.9,188c4,0.4,6.8,2.3,6.8,6.6c0.1,4.3-2.3,6.7-6.5,7c-3.7,0.3-6.9-2.4-7.3-6c0-0.3,0-0.5,0-0.7
		C626.2,190.5,628.7,188.4,632.9,188z"
        />
        <path
          class="st5"
          d="M361.9,805.6c0.1-3.8,2.7-6,6.8-6.5c3.7-0.5,7.4,3.3,7.2,7.1c-0.3,3.8-2.5,6.3-6.4,6.7S362,810,361.9,805.6z"
        />
        <path
          class="st5"
          d="M229.1,1388.8c-0.6,3.5-2.7,6.3-7,6.1c-4.2-0.2-6.8-2.7-6.8-7s2.9-6.7,7-6.7S228.9,1384.1,229.1,1388.8z"
        />
        <path
          class="st5"
          d="M193.2,230.8c-4-0.2-6.8-2-7.2-6.2c-0.3-4.2,1.8-6.8,6-7.6c3.8-0.7,7.5,2.6,7.8,6.5
		C199.9,227.7,197.4,229.8,193.2,230.8z"
        />
        <path
          class="st5"
          d="M193.5,1352.3c3.7,0.4,6.2,2.6,6.2,6.7s-2.4,6.6-6.7,6.9c-3.9,0.2-7.8-3.4-7.3-7.1
		C186.4,1355,188.6,1352.4,193.5,1352.3z"
        />
        <path
          class="st5"
          d="M111.8,1359c-0.3,4.4-2.5,6.9-6.8,6.9s-6.8-2.3-7-6.5c-0.3-3.7,2.5-6.8,6.1-7c0.3,0,0.6,0,1,0
		C109.5,1352.5,111.3,1355.2,111.8,1359z"
        />
        <path
          class="st5"
          d="M280.6,1307.8c-4-0.5-6.7-2.8-6.8-6.9c-0.1-4,2.4-6.7,6.8-6.9c4-0.2,7.5,3.3,7.2,7.1
		C287.5,1305.2,285.1,1307.6,280.6,1307.8z"
        />
        <path
          class="st5"
          d="M222.2,434.5c-3.8-0.5-6.7-2.3-6.9-6.6c-0.3-4,3.1-7.6,7-7.2s6.9,2.5,6.8,6.8
		C229,431.6,226.7,434.1,222.2,434.5z"
        />
        <path
          class="st5"
          d="M346.5,223.5c-0.2,4.3-2.4,6.9-6.6,7.1c-4.2,0.2-6.8-2.1-7.2-6.3c-0.3-4,2.9-7.6,6.8-7.4
		C343.6,217.1,346,219.5,346.5,223.5z"
        />
        <path
          class="st5"
          d="M514.6,725.6c-3.7-1-6.2-3.2-6-7.5c0.2-3.7,3.4-6.4,7-6.2c0.3,0,0.6,0.1,1,0.1c4.2,0.7,6.4,3.4,5.9,7.6
		C522,723.8,519,725.7,514.6,725.6z"
        />
        <path
          class="st5"
          d="M221.9,1249.7c-4-0.6-6.6-2.9-6.7-7.1c-0.1-4.3,2.8-6.5,6.8-7c3.7-0.4,7.3,3.5,7,7.3
		C228.7,1247.2,226.2,1249.4,221.9,1249.7z"
        />
        <path
          class="st5"
          d="M368.3,434.4c-3.4-0.5-6.2-2.4-6.4-6.7c-0.1-4.3,2.6-6.6,6.6-7.1c3.8-0.5,7.4,3.3,7.3,7.1
		C375.7,431.6,372.4,434.6,368.3,434.4C368.4,434.4,368.4,434.4,368.3,434.4z"
        />
        <path
          class="st5"
          d="M463.7,224.9c-0.5,3.4-2.9,6-7,5.8s-6.8-2.7-6.6-7s2.8-6.5,6.8-6.9C460.5,216.6,463.9,220.3,463.7,224.9z"
        />
        <path
          class="st5"
          d="M639.7,778c-0.4,3.3-2.8,5.7-7,5.7s-6.6-2.7-6.8-6.7c-0.2-3.9,3.2-7.1,7.3-6.8
		C637.2,770.4,639.9,773.3,639.7,778z"
        />
        <path
          class="st5"
          d="M428.2,434.5c-4.6-0.1-8.1-3.7-7.6-7.3c0.5-4,3.1-6.7,7.3-6.4c4.2,0.3,6.8,2.8,6.7,7.1
		C434.3,431.8,431.8,434.2,428.2,434.5z"
        />
        <path
          class="st5"
          d="M258.5,340.8c-0.1,3.7-2.4,6-6.6,6.2c-4.2,0.2-6.8-2-7.1-6.3s2.4-7.1,6.8-7.2
		C255.6,333.5,258.4,336.2,258.5,340.8z"
        />
        <path
          class="st5"
          d="M193.1,1206.8c3.7,0.2,6.4,2.3,6.6,6.5s-2.2,6.7-6.4,7.1c-3.9,0.4-8-3-7.5-6.7
		C186.1,1209.7,188.2,1207,193.1,1206.8z"
        />
        <path
          class="st5"
          d="M575.2,434.4c-4.8,0.1-8.2-3.2-8-7.1c0.3-4.1,2.9-6.8,7-6.7c3.9,0.2,6.9,2.6,6.8,6.9
		C581.4,431.1,578.7,434.2,575.2,434.4z"
        />
        <path
          class="st5"
          d="M332.7,78.6c0.3-4.8,2.9-7.1,6.9-7.1s6.7,2.5,6.8,6.8c0.2,3.7-2.7,6.8-6.2,7c-0.3,0-0.5,0-0.9,0
		C335.1,84.7,332.9,82.3,332.7,78.6z"
        />
        <path
          class="st5"
          d="M252.1,741c3.4,0.5,6,2.5,6.2,6.7c0.2,4.4-2.6,6.5-6.5,7.1s-7.1-2.8-7.1-7C244.8,743.9,247.6,741,252.1,741z"
        />
        <path
          class="st5"
          d="M545.3,783.8c-4.2-0.4-7-2.1-7.3-6.5s2.2-6.9,6.5-7.1c4.3-0.3,6.9,2.1,7.1,6.3
		C551.8,780.7,549.6,783.3,545.3,783.8z"
        />
        <path
          class="st5"
          d="M398.1,783.8c-4.2-0.6-6.8-2.7-6.6-7.1c0.2-4.4,2.7-6.6,7-6.5s6.7,2.7,6.6,6.9
		C405,781.3,402.3,783.6,398.1,783.8z"
        />
        <path
          class="st5"
          d="M405.1,340.6c-0.3,4-2.7,6.5-6.8,6.5s-6.8-2.2-6.8-6.6c-0.3-3.6,2.4-6.7,6-7c0.3,0,0.8,0,1,0
		C402.7,333.8,404.9,336.1,405.1,340.6z"
        />
        <path
          class="st5"
          d="M463.7,661.4c-0.3,3.4-2.6,6-6.8,6c-4.2,0-6.8-2.6-6.8-6.8s2.7-6.8,6.8-6.9
		C461.2,653.6,463.3,656.4,463.7,661.4z"
        />
        <path
          class="st5"
          d="M133.8,1162.3c-4.2-0.5-6.7-3-6.6-7.1c0.1-4,2.9-6.7,7.1-6.6s6.9,2.7,6.7,7
		C140.7,1159.6,138.4,1162.2,133.8,1162.3z"
        />
        <path
          class="st5"
          d="M252,624.7c3.6,0.1,6.1,2.1,6.4,6.3c0.2,4.2-1.9,6.8-6.1,7.1c-3.6,0.5-6.9-2-7.4-5.6c0-0.4-0.1-0.6-0.1-1
		C244.7,627.5,247.4,624.8,252,624.7z"
        />
        <path
          class="st5"
          d="M111.8,776.7c-0.3,4.4-2.6,6.8-6.8,7s-6.8-2.2-7-6.4c-0.3-3.6,2.3-6.8,5.8-7.1c0.3,0,0.8,0,1.1,0
		C109.3,770.3,111.3,772.8,111.8,776.7z"
        />
        <path
          class="st5"
          d="M625.9,747.4c0.3-3.5,2.4-6.2,6.8-6.4c4.3-0.2,6.8,2.3,7.1,6.4c0.3,4.1-2.3,6.8-6.2,7.3
		C629.5,755.3,626.1,751.9,625.9,747.4z"
        />
        <path
          class="st5"
          d="M111.8,456.4c-0.2,4.7-2.8,7-6.8,7.1c-4.1,0.1-6.8-2.2-6.9-6.5c-0.2-4.1,3-7.6,6.9-7.1
		C109.1,450.2,111.5,452.6,111.8,456.4z"
        />
        <path
          class="st5"
          d="M493.1,660.8c-0.5,4.2-3,6.7-7.2,6.5s-6.8-2.7-6.6-7.1c0.3-4.3,2.9-6.7,7.1-6.5
		C490.7,654,492.9,656.5,493.1,660.8z"
        />
        <path
          class="st5"
          d="M258.5,719c-0.5,3.8-2.6,6.5-6.8,6.5s-6.7-2.4-6.8-6.6c-0.3-3.6,2.4-6.8,6-7.1c0.3,0,0.6,0,1,0
		C255.9,712.1,258,714.7,258.5,719z"
        />
        <path
          class="st5"
          d="M391.3,631.3c0.4-4.2,2.7-6.7,7-6.6c4.3,0.1,6.8,2.6,6.7,6.9c-0.1,4.3-2.6,6.7-6.9,6.7
		C393.8,638.2,391.6,635.6,391.3,631.3z"
        />
        <path
          class="st5"
          d="M516,259.8c-4.2-0.2-7-2.2-7.2-6.4c-0.2-4,1.8-6.9,6.1-7.2c4.1-0.4,7.6,2.7,7.5,6.6
		C522.3,256.9,520.3,259.4,516,259.8z"
        />
        <path
          class="st5"
          d="M281.3,1162.3c-4.5-0.2-7.2-2.5-7.3-6.6s2.3-6.8,6.5-7.2c3.8-0.4,7.6,3.1,7.4,6.9
		C287.6,1159.4,285.4,1161.8,281.3,1162.3z"
        />
        <path
          class="st5"
          d="M545.9,682.7c3.4,0.5,6,2.7,5.9,7c-0.1,4.2-2.8,6.5-6.8,6.8c-4.1,0.3-7.1-3.2-6.8-7.2
		C538.6,685.3,541.3,682.6,545.9,682.7z"
        />
        <path
          class="st5"
          d="M398.6,682.6c4,0.5,6.5,2.9,6.4,7.1c-0.1,4.1-2.7,6.5-6.8,6.8c-4.2,0.3-7.1-3.1-6.8-7.2
		C391.7,684.9,394.4,683,398.6,682.6z"
        />
        <path
          class="st5"
          d="M596.6,660.5c0.3-4.2,2.8-6.5,6.8-6.9c3.7-0.4,7.4,3.5,6.9,7.2c-0.4,4-2.8,6.5-7.1,6.5
		C599.6,667.3,596.5,664.2,596.6,660.5z"
        />
        <path
          class="st5"
          d="M486.9,347.2c-4.5-0.2-7.1-2.4-7.5-6.4c-0.3-3.7,2.3-6.9,6-7.1c0.3,0,0.6,0,1,0c4.2,0.1,6.8,2.4,6.9,6.6
		C493.3,344.4,490.7,346.5,486.9,347.2z"
        />
        <path
          class="st5"
          d="M545.2,624.7c4.2,0.5,6.7,2.7,6.5,7s-2.6,6.8-6.9,6.5c-4.2-0.2-6.8-2.7-6.7-7
		C538.1,626.9,540.9,624.9,545.2,624.7z"
        />
        <path
          class="st5"
          d="M222.3,289c-3.8-0.5-6.7-2.2-7-6.5c-0.3-3.9,3-7.6,6.9-7.3c4.2,0.3,6.9,2.5,6.9,6.7
		C229.1,286,226.8,288.5,222.3,289z"
        />
        <path
          class="st5"
          d="M281.3,114.3c-4.5-0.5-7.4-2.6-7.3-6.8c0-4,2.5-6.9,6.8-6.9c4.2,0,6.9,2.5,7,6.7
		C288,111.5,285.1,113.6,281.3,114.3z"
        />
        <path
          class="st5"
          d="M346.5,1097.9c-0.3,3.6-2.7,6.1-6.9,6c-4.2-0.1-6.8-2.4-6.9-6.6c-0.2-3.8,3.4-7.4,7.2-7
		C343.9,1090.7,346.4,1092.9,346.5,1097.9z"
        />
        <path
          class="st5"
          d="M199.8,1097.6c-0.4,4-2.9,6.5-7.1,6.3c-4.2-0.2-6.8-2.7-6.7-7c0.1-4.2,2.9-6.7,7-6.6
		C197.2,1090.5,199.6,1093,199.8,1097.6z"
        />
        <path
          class="st5"
          d="M170.3,1097.3c-0.3,3.9-2.4,6.4-6.7,6.7c-3.8,0.1-7-2.8-7.1-6.6c0-0.2,0-0.4,0-0.5c0.2-4.3,3.1-6.7,6.9-6.7
		C167.2,1090.2,170.2,1092.8,170.3,1097.3z"
        />
        <path
          class="st5"
          d="M426.9,289c-3.7-0.5-6.2-2.8-6.4-7c-0.1-4.1,2.8-6.5,6.8-7c3.7-0.4,7.4,3.7,7.2,7.2
		C434.1,286.2,430.8,289.1,426.9,289z"
        />
        <path
          class="st5"
          d="M691.5,347.2c-4-0.5-6.7-2.5-6.8-6.8c-0.2-3.6,2.6-6.7,6.1-6.9c0.3,0,0.6,0,1,0c4.2,0.4,6.6,2.7,6.5,7
		S695.7,346.9,691.5,347.2z"
        />
        <path
          class="st5"
          d="M199.9,1067.8c-0.5,4-2.4,6.8-6.8,7c-4.3,0.2-6.8-2.5-7.4-6.3c-0.6-3.5,3.2-7.3,7-7.2
		C197,1061.3,199.2,1063.8,199.9,1067.8z"
        />
        <path
          class="st5"
          d="M170.6,1067.8c-0.5,4-2.4,6.8-6.8,7s-6.8-2.5-7.4-6.3c-0.6-3.5,3.2-7.3,7-7.2
		C167.8,1061.3,170,1063.8,170.6,1067.8z"
        />
        <path
          class="st5"
          d="M199.9,1038.7c-0.5,4-2.4,6.8-6.8,7c-4.3,0.2-6.8-2.5-7.4-6.3c-0.6-3.5,3.2-7.3,7-7.2
		C197,1032.2,199.2,1034.6,199.9,1038.7z"
        />
        <path
          class="st5"
          d="M346.5,1039.3c-0.3,3.7-2.5,6.1-6.7,6.5c-3.8,0.2-6.9-2.7-7.2-6.4c0-0.2,0-0.5,0-0.6c0.2-4.2,2.7-6.5,6.8-6.8
		C343.2,1031.9,346.4,1035.1,346.5,1039.3z"
        />
        <path
          class="st5"
          d="M581.4,282.1c-0.6,3.9-2.8,6.6-7,6.9c-3.8,0.3-7.5-3.4-7.2-7.1c0.4-4.1,3-6.8,7.1-6.7
		C578.3,275.2,580.9,277.8,581.4,282.1z"
        />
        <path
          class="st5"
          d="M281,696.5c-4.4-0.4-6.9-2.8-6.9-6.9s2.5-6.6,6.8-6.8c3.8-0.2,6.9,2.7,7.1,6.4c0,0.2,0,0.5,0,0.6
		C287.5,694,285,696.1,281,696.5z"
        />
        <path
          class="st5"
          d="M538.1,456.2c0.2-3.8,2.7-6,6.8-6.4c3.8-0.4,7.1,3.1,6.9,7.1c-0.1,4.4-2.9,6.5-6.9,6.5
		C540.7,463.5,538.2,461,538.1,456.2z"
        />
        <path
          class="st5"
          d="M199.8,369.6c-0.2,4-2.6,6.5-6.8,6.6s-6.8-2.1-7.1-6.4c-0.3-4,3-7.6,6.9-7.2
		C197,362.8,199.5,365.1,199.8,369.6z"
        />
        <path
          class="st5"
          d="M610.5,747.7c-0.3,4.6-2.7,7-6.8,7s-6.7-2.4-6.9-6.6c-0.2-3.6,2.6-6.7,6.1-7c0.3,0,0.6,0,1,0
		C608.1,741.3,610.2,743.7,610.5,747.7z"
        />
        <path
          class="st5"
          d="M580.4,747.7c-0.3,4.6-2.7,7-6.8,7c-4.2,0-6.7-2.4-6.9-6.6c-0.2-3.6,2.6-6.7,6.1-7c0.3,0,0.6,0,1,0
		C578.2,741.3,580.2,743.7,580.4,747.7z"
        />
        <path
          class="st5"
          d="M310.4,1032c4.2,0.7,6.7,2.7,6.6,7.1c-0.1,4-3.6,7.3-7.4,6.7c-3.9-0.6-6.5-3.1-6.2-7.3
		S306.4,1032.3,310.4,1032z"
        />
        <path
          class="st5"
          d="M367.7,537.3c5.2,0,8.4,3.1,8.1,7.1s-2.7,6.5-7.1,6.3s-6.8-2.6-6.7-6.7C362.1,540,364.5,537.6,367.7,537.3z"
        />
        <path
          class="st5"
          d="M368.2,696.4c-3.7-0.4-6.2-2.6-6.1-6.9c0.1-4.3,2.6-6.6,6.8-6.8c3.6-0.3,6.8,2.5,7,6c0,0.4,0,0.7,0,1.1
		C375.4,694.1,373.1,696.4,368.2,696.4z"
        />
        <path
          class="st5"
          d="M156.5,1039.2c0.3-4.1,2.3-6.9,6.6-7.1c4.2-0.2,6.8,1.9,7.2,6.2c0.3,3.7-2.3,7-6,7.4c-0.2,0-0.4,0-0.6,0
		C159.4,1045.8,157.1,1043.3,156.5,1039.2z"
        />
        <path
          class="st5"
          d="M258.5,689.4c-0.4,4.1-2.6,6.7-6.8,7.1c-4,0.4-7.2-3-7.1-7.1c0.1-4.4,2.7-6.5,6.8-6.7
		C255.9,682.6,257.8,685.5,258.5,689.4z"
        />
        <path
          class="st5"
          d="M576.2,129.3c1.4,1.9,4.1,4.3,4.8,7.2c1,3.5-3.6,7.2-7.2,6.9c-4.2-0.5-6.7-3-6.6-7.1
		C567.4,132.1,570.2,130,576.2,129.3z"
        />
        <path
          class="st5"
          d="M346.5,370.1c-0.2,3.5-2.5,6-6.7,6c-4.2,0.1-6.8-2.1-7.1-6.4c-0.3-3.7,2.4-7,6.1-7.3c0.3,0,0.4,0,0.8,0
		C343.3,362.5,346.4,365.6,346.5,370.1z"
        />
        <path
          class="st5"
          d="M258.5,485.8c-0.5,4.5-2.9,7-7.2,6.8s-6.8-2.7-6.6-7.1s2.6-6.6,7-6.4C256.1,479.3,258.3,481.9,258.5,485.8z"
        />
        <path
          class="st5"
          d="M252.2,1002.9c3.7,0.5,6.1,2.8,6.1,7.1c0,4.2-2.3,6.7-6.8,6.7s-6.7-2.4-6.9-6.6
		C244.4,1005.8,247.6,1002.8,252.2,1002.9z"
        />
        <path
          class="st5"
          d="M141.2,980.7c-0.5,4-2.7,6.7-6.9,6.8s-6.8-2.4-7.1-6.5c-0.3-3.8,3.1-7.3,7.1-7.1
		C138.7,974.1,140.7,976.6,141.2,980.7z"
        />
        <path
          class="st5"
          d="M339.7,492.8c-4.3-0.5-6.8-2.7-7.1-6.8c-0.2-3.7,2.6-6.7,6.2-7c0.3,0,0.6,0,1,0c4.2,0.3,6.7,2.7,6.6,7
		C346.4,490.1,343.6,492.2,339.7,492.8z"
        />
        <path
          class="st5"
          d="M134.3,580.1c-4.2-0.5-6.8-2.7-7-6.9c-0.3-3.8,3.6-7.5,7.3-7c4,0.5,6.8,2.7,6.6,7.1
		C140.8,577.5,138.3,579.7,134.3,580.1z"
        />
        <path
          class="st5"
          d="M398.3,492.8c-3.8-0.6-6.8-2.3-6.8-6.7c0-4.3,2.3-6.8,6.6-7c4.2-0.2,6.8,2.3,7,6.5
		C405.2,489.9,402.6,492.1,398.3,492.8z"
        />
        <path
          class="st5"
          d="M346.5,660.6c-0.3,4-2.6,6.6-6.8,6.8c-4.2,0.2-6.8-2.2-7.2-6.4c-0.3-3.8,2.6-7,6.4-7.2c0.2,0,0.3,0,0.5,0
		C343.8,653.8,346,656.3,346.5,660.6z"
        />
        <path
          class="st5"
          d="M282,580c-4.8,0.2-8.4-3-8.1-6.9c0.3-4.2,2.9-6.7,7-6.7s6.8,2.5,6.9,6.7C288.1,576.5,285.5,579.6,282,580z"
        />
        <path
          class="st5"
          d="M493.1,486.8c-0.8,3.2-2.7,6-7,5.8c-4.2-0.2-6.7-2.7-6.8-6.8c-0.2-3.9,3.3-7,7.4-6.8
		C490.6,479.2,493.3,482.3,493.1,486.8z"
        />
        <path
          class="st5"
          d="M244.9,1561.3c0.5-3.4,3.1-5.7,7.3-5.2s6.6,3,6.1,7.2c-0.3,4.2-3.1,6.4-7.1,6.4
		C247.3,1569.7,244.4,1566.2,244.9,1561.3z"
        />
        <path
          class="st5"
          d="M551.7,485.3c-0.2,4.8-2.4,7.3-6.8,7.3c-4.2,0-6.8-2.3-6.9-6.6c-0.1-4.3,2.5-6.7,6.8-6.9
		C549.1,479,551.3,481.6,551.7,485.3z"
        />
        <path
          class="st5"
          d="M222.3,1497.8c4.1,0.1,7.4,3.4,6.9,7.3c-0.4,4.1-2.9,6.5-7.3,6.3s-6.6-2.7-6.7-6.9
		C215.2,1500.8,218.4,1497.7,222.3,1497.8C222.2,1497.8,222.2,1497.8,222.3,1497.8z"
        />
        <path
          class="st5"
          d="M251.7,26.9c-4.3-0.4-6.9-2.3-7-6.7s2.4-6.7,6.5-7.1c3.8-0.4,7.2,3.2,7.1,7.1
		C258.3,24.6,255.7,26.5,251.7,26.9z"
        />
        <path
          class="st5"
          d="M111.6,1010.6c-0.4,3.2-2.3,6-6.8,6c-3.7,0.2-6.8-2.7-6.9-6.3c0-0.3,0-0.5,0-0.8c0.3-4.3,2.9-6.3,7-6.6
		C108.7,1002.7,111.8,1006.1,111.6,1010.6z"
        />
        <path
          class="st5"
          d="M133.5,1045.7c-3.9-0.5-6.2-2.9-6.1-7.1c0.2-3.8,3.4-6.8,7.2-6.6c0.2,0,0.3,0,0.4,0c4.2,0.6,6.1,3.5,6.1,7.5
		C141,1043.2,137.5,1046,133.5,1045.7z"
        />
        <path
          class="st5"
          d="M280.9,1045.8c-4.7-0.7-7-3.2-6.9-7.3c0.1-3.8,4.2-7.1,7.8-6.4c4.1,0.8,6.5,3.6,6,7.6
		C287.5,1043.2,284.4,1046,280.9,1045.8L280.9,1045.8z"
        />
        <path
          class="st5"
          d="M398.4,26.9c-4.5-0.4-7-2.5-7-6.9s2.7-6.8,6.8-6.8c4.2,0,6.8,2.5,6.8,6.9S402.4,26.5,398.4,26.9z"
        />
        <path
          class="st5"
          d="M141.1,427.1c-0.3,4.1-2.2,6.9-6.5,7.3c-3.8,0.3-7.1-2.5-7.4-6.2c0-0.2,0-0.4,0-0.5c0.2-4.2,2.8-6.9,6.8-7
		C137.9,420.6,140.7,423,141.1,427.1z"
        />
        <path
          class="st5"
          d="M222.3,1061.3c4.5,0.3,6.7,2.7,6.6,7c-0.1,4.2-2.3,6.7-6.8,6.6c-4,0-7.4-3.4-6.9-7.1
		C215.7,1063.4,218.2,1061.3,222.3,1061.3z"
        />
        <path
          class="st5"
          d="M258.5,49.7c-0.5,3.9-3.1,6.4-7.2,6.2s-6.7-2.6-6.6-7.1c0.1-4.5,2.9-6.5,7-6.4
		C256.1,42.6,258.3,45.1,258.5,49.7z"
        />
        <path
          class="st5"
          d="M280.4,434.5c-3.7-0.4-6.4-2.7-6.5-6.8c-0.1-4.1,2.6-6.6,6.7-7.1c3.8-0.5,7.4,3.4,7.3,7.1
		C287.5,431.6,284.3,434.5,280.4,434.5z"
        />
        <path
          class="st5"
          d="M258.5,1067.9c-0.3,4.5-2.6,6.9-6.8,6.8s-6.7-2.3-6.9-6.6c-0.3-3.6,2.4-6.7,6-7c0.3,0,0.8,0,1.1,0
		C256.2,1061.4,258,1064.2,258.5,1067.9z"
        />
        <path
          class="st5"
          d="M258.5,1155.7c-0.6,4-2.7,6.7-7,6.5s-6.8-2.8-6.7-7.3c0.2-4.5,3.2-6.3,7.2-6.2
		C256.2,1148.6,258.1,1151.6,258.5,1155.7z"
        />
        <path
          class="st5"
          d="M134.4,1191.3c-4.2-0.3-6.8-2.4-7.1-6.6c-0.3-3.8,3-7.2,7.1-7.1c4.4,0.1,6.4,2.9,6.8,6.9
		C141.5,1188,138,1191.3,134.4,1191.3z"
        />
        <path
          class="st5"
          d="M398.9,42.4c4.1,0.6,6.5,2.9,6.1,7.2c-0.3,4.3-3.2,6.5-7.3,6.3s-6.6-2.9-6.2-7.2
		C391.7,44.2,394.5,42.6,398.9,42.4z"
        />
        <path
          class="st5"
          d="M192.8,420.7c4.2,0.5,6.9,2.7,6.8,7c-0.1,4.3-2.6,6.8-6.8,6.8s-6.7-2.6-6.8-6.8
		C186,423.5,188.8,421.3,192.8,420.7z"
        />
        <path
          class="st5"
          d="M163.6,1177.6c4,0.5,6.8,2.5,6.8,6.9s-2.7,6.8-6.8,6.8c-4.2,0-6.8-2.5-6.8-6.8
		C156.7,1180.1,159.4,1178.1,163.6,1177.6z"
        />
        <path
          class="st5"
          d="M104.7,1206.7c4.5,0.3,6.8,2.7,7,6.8c0.2,4.1-2.3,6.7-6.6,6.8c-3.6,0.4-6.8-2.2-7.1-5.8c0-0.4,0-0.7,0-1.2
		C98.2,1209.2,100.8,1207.1,104.7,1206.7z"
        />
        <path
          class="st5"
          d="M273.9,77.9c0.5-4,2.9-6.6,7.1-6.5s6.6,2.7,6.8,6.9c0.2,3.7-3.7,7.4-7.4,6.9C276.3,84.7,274.1,82.1,273.9,77.9
		z"
        />
        <path
          class="st5"
          d="M258.5,1213.1c-0.1,4.6-2.3,7-6.6,7.1c-3.6,0.4-6.8-2.2-7.1-5.7c0-0.4,0-0.7,0-1.2c0.1-4.3,2.6-6.5,6.8-6.6
		C256,1206.7,257.8,1209.4,258.5,1213.1z"
        />
        <path
          class="st5"
          d="M163.1,1249.6c-4.3-0.8-6.8-3.3-6.5-7.5s3.3-6.6,7.3-6.3c4,0.3,6.7,2.9,6.2,7.3
		C169.9,1247.5,167.2,1249.4,163.1,1249.6z"
        />
        <path
          class="st5"
          d="M75.6,1395.2c-3.9,0-7.4-3.6-7-7.2c0.3-4.1,3.1-6.7,7.2-6.5s6.7,2.6,6.7,7c0.1,3.7-2.9,6.8-6.6,6.8
		C75.6,1395.2,75.6,1395.2,75.6,1395.2z"
        />
        <path
          class="st5"
          d="M104.8,1395.3c-4-0.6-6.6-2.6-6.8-6.9c-0.2-3.7,2.7-6.8,6.5-7c0.3,0,0.4,0,0.8,0c4.2,0.4,6.8,2.7,6.5,7
		C111.4,1392.4,109,1394.9,104.8,1395.3z"
        />
        <path
          class="st5"
          d="M133.4,449.8c4.6,0,8.1,3.3,7.8,7.3s-2.6,6.1-6.6,6.4c-4.2,0.3-7.6-2.9-7.3-6.9
		C127.6,452.6,129.8,450.1,133.4,449.8z"
        />
        <path
          class="st5"
          d="M258.5,1417.3c-0.4,4.1-2.8,7-6.9,6.9c-4-0.1-6.8-2.3-6.8-6.8s2.4-6.8,6.8-6.9
		C255.9,1410.5,258,1413.1,258.5,1417.3z"
        />
        <path
          class="st5"
          d="M111.8,1068.5c-0.8,4.1-3,6.6-7.4,6.2s-6.7-2.9-6.4-7.1c0.3-4.2,2.9-6.5,7.2-6.3
		C109.8,1061.4,111.4,1064.5,111.8,1068.5z"
        />
        <path
          class="st5"
          d="M134.7,1453.3c-4.6-0.2-7.3-2.7-7.4-6.7s2.4-6.9,6.8-6.9c4.2,0,7,2.3,7.1,6.6
		C141.2,1450.7,138.7,1452.9,134.7,1453.3z"
        />
        <path
          class="st5"
          d="M199.8,1446.2c-0.4,4-2.3,7-6.8,7.1c-4.2,0.1-6.9-2.2-7.1-6.5s2.3-6.7,6.6-7
		C196.9,1439.5,199.2,1442.1,199.8,1446.2z"
        />
        <path
          class="st5"
          d="M375.7,107.1c-0.2,4.2-2.3,6.8-6.6,7.1c-3.6,0.3-6.8-2.4-7.2-6c0-0.3,0-0.6,0-0.9c0.2-4.1,2.3-6.7,6.8-6.7
		S375.3,103,375.7,107.1z"
        />
        <path
          class="st5"
          d="M141.2,1475.9c-0.4,4.2-3,6.6-7.1,6.6c-4.2,0-6.8-2.7-6.8-6.9c0.1-4.1,2.6-6.7,7-6.7
		S140.6,1471.7,141.2,1475.9z"
        />
        <path
          class="st5"
          d="M133.3,1511.5c-3.6-0.2-6.2-3.2-6-6.7c0-0.1,0-0.2,0-0.3c0.2-3.9,2.7-6.6,7-6.7c3.8-0.1,7.5,3.7,7,7.2
		C140.6,1509,138.2,1511.6,133.3,1511.5z"
        />
        <path
          class="st5"
          d="M456.3,114.2c-3.7-0.3-6.2-2.5-6.2-6.7s2.4-6.7,6.7-6.9c4.1-0.2,7.5,3.2,7.2,7
		C463.5,111.6,461.2,114.1,456.3,114.2z"
        />
        <path
          class="st5"
          d="M229.1,1534.5c-0.8,3.4-2.6,6.4-6.9,6.2c-4.1-0.2-6.8-2.6-6.9-6.7c-0.3-3.8,3.4-7.4,7.2-7.1
		C226.6,1527.3,228.9,1529.8,229.1,1534.5z"
        />
        <path
          class="st5"
          d="M572.9,319.1c-1.9-2.6-5.1-4.9-5.5-7.8c-0.6-3.7,2-6.7,6.5-7c4-0.1,7.3,3,7.4,6.9
		C581.3,314.7,577.8,318,572.9,319.1z"
        />
        <path
          class="st5"
          d="M405.1,456.2c-0.1,4.6-2.5,7.1-6.6,7.2c-4.1,0.2-6.8-1.9-7-6.3c-0.2-4.1,2.9-7.6,6.8-7.3
		C402.3,450,404.7,452.4,405.1,456.2z"
        />
        <path
          class="st5"
          d="M273.9,136.2c0.2-3.8,2.6-6.4,6.7-6.6s6.8,2.2,7.3,6.4c0.3,3.8-3.1,7.4-6.9,7.4
		C277,143.3,273.9,140.1,273.9,136.2z"
        />
        <path
          class="st5"
          d="M361.9,135.5c0.5-3.3,2.9-6,7-5.9c4,0.2,6.9,2.7,6.8,6.9c0,4-2.3,6.8-6.7,7C365.3,143.6,361.8,140,361.9,135.5
		z"
        />
        <path
          class="st5"
          d="M575.3,463.6c-5,0-7.4-2.3-8.1-6.4c-0.4-3.8,3-7.4,6.9-7.3c4.2,0.2,6.8,2.7,6.9,6.7
		C581.4,460.8,578.4,462.8,575.3,463.6z"
        />
        <path
          class="st5"
          d="M434.6,136.9c-1,3.9-3,6.6-7.4,6.5c-4-0.1-7.2-3.9-6.6-7.7c0.8-4.1,3.4-6.4,7.4-6S434.5,132.8,434.6,136.9z"
        />
        <path
          class="st5"
          d="M493.2,136.5c-0.5,4-2.7,6.6-6.8,6.9c-3.7,0.1-6.8-2.7-6.9-6.4c0-0.3,0-0.5,0-0.8c0.3-4.2,2.7-6.6,6.9-6.4
		C490.7,129.9,492.8,132.4,493.2,136.5z"
        />
        <path
          class="st5"
          d="M104.7,492.8c-4-0.6-6.6-2.6-6.7-6.9s2.9-7.1,7.2-6.8c4.2,0.4,6.6,2.7,6.5,7.1
		C111.5,490.5,108.8,492.3,104.7,492.8z"
        />
        <path
          class="st5"
          d="M692.1,318c-4.4-0.3-7.2-2.1-7.3-6.5s2.4-6.9,6.6-7.1c4.2-0.2,6.8,2.4,7,6.6
		C698.6,315.4,695.9,317.4,692.1,318z"
        />
        <path
          class="st5"
          d="M199.8,486.3c-0.9,3.7-2.7,6.5-7.1,6.3c-4.2-0.2-6.7-2.6-6.8-6.7c-0.1-3.7,2.8-6.7,6.5-6.9c0.3,0,0.5,0,0.9,0
		C197.4,479.4,199.6,482,199.8,486.3z"
        />
        <path
          class="st5"
          d="M332.6,515.5c0.1-4.8,2.7-7,6.8-7.4c3.8-0.5,7.2,3.1,7.1,7.1c-0.2,4.3-2.8,6.4-6.9,6.6
		C336,521.9,332.9,519.2,332.6,515.5L332.6,515.5z"
        />
        <path
          class="st5"
          d="M508.6,514.1c0.3-3.5,2.8-5.8,6.9-6c3.8-0.3,7.4,3.6,7,7.3c-0.4,3.7-2.8,6.2-6.7,6.5
		C512.1,522.2,508.5,518.8,508.6,514.1z"
        />
        <path
          class="st5"
          d="M308.4,550.7c-2.5-0.3-5.1-2.4-5.1-6.7s2.7-6.5,6.8-6.7c3.9-0.2,7.6,3.6,7.1,7.1
		C316.8,548.4,314.4,550.9,308.4,550.7z"
        />
        <path
          class="st5"
          d="M427.8,550.9c-4.6-0.2-7.1-2.7-7.1-6.9s2.6-6.8,6.9-6.7c4.2,0.1,6.9,2.6,6.9,6.8S431.8,550.5,427.8,550.9z"
        />
        <path
          class="st5"
          d="M258.4,574.3c-0.5,3.2-2.8,5.6-7,5.7c-4.1,0.1-6.9-3.2-6.6-7.4c0.3-4.4,3.3-6.1,7.2-6.3
		C255.5,566.1,258.5,569.7,258.4,574.3z"
        />
        <path
          class="st5"
          d="M551.7,574.3c-0.3,3-2.4,5.5-6.7,5.8c-3.7,0.2-6.8-2.7-6.9-6.3c0-0.3,0-0.5,0-0.8c0.2-4.4,3-6.2,7-6.5
		C548.7,566.1,551.7,569.4,551.7,574.3z"
        />
        <path
          class="st5"
          d="M457.1,609.1c-4.1-0.3-6.9-2.2-7-6.5c-0.1-4.1,2.2-6.9,6.6-7.1c4.2-0.1,6.9,2,7.1,6.4
		C463.9,606.4,461.4,608.5,457.1,609.1z"
        />
        <path
          class="st5"
          d="M170.4,631.7c-0.3,3.8-2.6,6.4-6.8,6.4s-6.8-2.3-7-6.5c-0.3-3.6,2.4-6.7,6-7.1c0.3,0,0.8,0,1,0
		C167.9,624.8,170,627.2,170.4,631.7z"
        />
        <path
          class="st5"
          d="M193.3,624.6c3.8,0.4,6.4,2.6,6.4,6.8s-2.3,6.7-6.7,6.8c-4.2,0.1-7.5-3.2-7.1-7.1
		C186.4,627,188.6,624.7,193.3,624.6z"
        />
        <path
          class="st5"
          d="M346.5,631.9c-0.4,3.3-2.4,6.1-6.8,6.2c-4.4,0.1-6.8-2.2-7.2-6.3c-0.4-3.9,2.9-7.1,6.9-7.1
		C343.4,624.7,346.4,627.4,346.5,631.9z"
        />
        <path
          class="st5"
          d="M346.5,340.3c-0.5,3.9-2.6,6.7-6.9,6.7s-6.8-2.6-6.9-6.7s2.5-6.8,6.8-6.8C343.9,333.5,346,336.1,346.5,340.3z"
        />
        <path
          class="st5"
          d="M375.7,631.6c-0.5,3.5-2.2,6.5-6.7,6.6c-3.9,0.1-7.5-3.3-7.3-7.1c0.3-4.1,2.7-6.5,7-6.5
		c3.7-0.2,6.8,2.7,6.9,6.2C375.8,631.2,375.8,631.4,375.7,631.6z"
        />
        <path
          class="st5"
          d="M456.9,624.6c4.3,0.4,6.9,2.7,6.8,7s-2.8,6.7-6.9,6.7s-6.8-2.6-6.8-6.9C450.1,627,452.7,624.9,456.9,624.6z"
        />
        <path
          class="st5"
          d="M493.2,631.6c-0.6,3.9-2.8,6.5-7,6.5s-6.7-2.5-6.9-6.6c-0.3-4.1,3-7.1,7.2-6.9
		C490.9,624.9,492.7,627.5,493.2,631.6z"
        />
        <path
          class="st5"
          d="M515,624.7c4.8,0,7.4,2.5,7.4,6.5s-2.2,6.9-6.6,7c-4.6,0.2-7.1-2.7-7.3-6.9C508.4,627,511.3,624.9,515,624.7z"
        />
        <path
          class="st5"
          d="M544.7,347.1c-4.3-0.7-6.9-2.8-6.7-7.2c0.3-4.4,3-6.5,7.2-6.2s6.6,2.7,6.4,7.1
		C551.4,345.2,548.5,346.7,544.7,347.1z"
        />
        <path
          class="st5"
          d="M156.5,660.8c0.3-4.6,2.9-7.1,6.9-7.1c4.1,0,6.9,2.6,6.8,6.9c-0.1,4.3-2.5,6.9-6.8,6.9
		S156.9,664.8,156.5,660.8z"
        />
        <path
          class="st5"
          d="M287.9,660.7c-0.4,3.8-2.7,6.4-6.8,6.6c-4.1,0.2-6.8-2.2-7.3-6.4c-0.3-3.7,3.2-7.6,6.9-7.4
		C285,653.8,287.3,656.2,287.9,660.7z"
        />
        <path
          class="st5"
          d="M633,333.5c4.1,0.5,6.7,2.4,6.8,6.7c0.2,3.7-2.7,6.7-6.2,6.9c-0.3,0-0.5,0-1,0c-4.2-0.4-6.7-2.7-6.6-7
		C626.2,335.9,628.8,333.8,633,333.5z"
        />
        <path
          class="st5"
          d="M303.3,660.8c0.2-4.6,2.7-7.1,6.8-7.1c4.1-0.1,6.8,2.4,6.9,6.7s-2.4,7-6.7,7
		C306.1,667.3,303.7,664.9,303.3,660.8z"
        />
        <path
          class="st5"
          d="M281.7,828.3c3.6,0.5,6,2.7,6.1,6.9c0,3.8-3.1,6.8-6.9,6.8c-0.2,0-0.3,0-0.5,0c-4.2-0.5-6.5-3-6.5-7.1
		C274,831,277.3,828.2,281.7,828.3z"
        />
        <path
          class="st5"
          d="M427.6,682.7c4,0.3,6.5,2.5,6.9,6.5c0.3,3.8-3.1,7.2-7.1,7.1c-4.3-0.2-6.8-2.7-6.8-6.8
		C420.5,685.6,423.2,683.1,427.6,682.7z"
        />
        <path
          class="st5"
          d="M522.5,689.8c-0.5,4.1-2.8,6.8-7.1,6.5c-4.1-0.3-6.9-2.6-6.8-6.9c0.2-4.1,2.7-6.7,7-6.6
		S522.2,685.6,522.5,689.8z"
        />
        <path
          class="st5"
          d="M280.9,725.7c-4.2-0.5-6.8-2.7-7-6.8c-0.1-3.8,2.9-7,6.8-7.1c0.2,0,0.3,0,0.5,0c4.1,0.5,6.8,2.8,6.7,7
		C287.6,722.8,285.1,725.2,280.9,725.7z"
        />
        <path
          class="st5"
          d="M405,719.5c-0.4,3.2-2.5,5.9-6.8,6c-4.1,0.2-7-3-6.8-7.2c0.2-4.4,3-6.2,7-6.5C402.2,711.7,405.1,715,405,719.5
		z"
        />
        <path
          class="st5"
          d="M662.5,754.7c-4.2-0.5-6.9-2.6-7.1-6.8c-0.2-3.8,3.6-7.4,7.3-7.1c4.2,0.4,6.7,3.1,6.6,7.1
		C669,752.1,666.3,754.4,662.5,754.7z"
        />
        <path
          class="st5"
          d="M164.5,770.2c3.6,0.3,5.7,2.7,5.7,6.9s-2.5,6.7-6.8,6.7s-6.8-2.3-6.9-6.5S159.5,770.1,164.5,770.2z"
        />
        <path
          class="st5"
          d="M340.4,770.1c3.8,0.8,6.4,3.1,6,7.3c-0.3,4-2.9,6.5-7.2,6.3c-3.7-0.1-6.7-3.2-6.6-6.9c0-0.3,0-0.5,0.1-0.7
		C333.3,771.9,336,770.1,340.4,770.1z"
        />
        <path
          class="st5"
          d="M361.9,368.8c0.3-3.6,2.7-6,6.8-6.3c3.8-0.2,7,2.7,7.2,6.5c0,0.2,0,0.5,0,0.6c-0.2,3.9-2.6,6.2-6.4,6.7
		C365.7,376.7,362,373.5,361.9,368.8z"
        />
        <path
          class="st5"
          d="M361.8,776.6c0.8-4.2,2.9-6.5,7.3-6.4c4.5,0.1,6.5,2.7,6.7,6.9c0.1,3.6-2.7,6.6-6.2,6.7c-0.3,0-0.8,0-1.1-0.1
		C364.1,783.2,362.2,780.5,361.8,776.6z"
        />
        <path
          class="st5"
          d="M199.8,807c-1,4-3.6,6.3-7.8,5.8s-6.6-3.2-6-7.5c0.5-4.3,3.4-6.4,7.5-6C197.8,799.8,199.8,802.7,199.8,807z"
        />
        <path
          class="st5"
          d="M463.7,370c-0.6,3.7-2.7,6.5-7,6.3c-4.2-0.2-6.8-2.7-6.7-7.1c0.1-4.4,2.9-6.8,7-6.7
		C461.2,362.6,463.5,365.3,463.7,370z"
        />
        <path
          class="st5"
          d="M493.1,370.4c-0.4,3.3-2.7,5.8-6.9,5.7c-4.2-0.1-6.8-2.5-6.8-6.7s2.6-6.6,6.7-7
		C489.8,362.1,493.2,365.8,493.1,370.4z"
        />
        <path
          class="st5"
          d="M508.5,369.9c0.2-4.4,2.4-6.9,6.6-7.4c3.7-0.5,7.6,3.1,7.4,7c-0.3,4-2.7,6.8-6.8,6.9
		C511.6,376.5,509.1,373.9,508.5,369.9z"
        />
        <path
          class="st5"
          d="M639.7,806.3c-0.4,3.9-2.8,6.6-7,6.6s-6.9-2.7-6.8-6.9c0.1-4.2,2.8-6.8,6.9-6.8
		C637.1,799.2,639.6,801.7,639.7,806.3z"
        />
        <path
          class="st5"
          d="M111.9,835.4c-0.8,3.9-2.9,6.6-7.1,6.6c-3.7,0-6.8-2.8-6.8-6.5c0-0.3,0-0.6,0.1-0.9c0.3-4.3,3.1-6.4,7.2-6.2
		C109.6,828.5,111.4,831.4,111.9,835.4z"
        />
        <path
          class="st5"
          d="M610.5,369.8c-0.5,3.8-2.6,6.7-6.9,6.5c-4.1-0.2-6.8-2.6-6.8-7c0.1-4.4,2.9-6.8,6.9-6.9
		C607.8,362.5,610,365.3,610.5,369.8z"
        />
        <path
          class="st5"
          d="M639.7,370.3c-0.3,3.4-2.8,5.8-6.8,5.8s-6.8-2.5-6.9-6.6c-0.2-4.1,2.5-6.6,6.6-7.1
		C636.5,362.1,639.7,365.6,639.7,370.3z"
        />
        <path
          class="st5"
          d="M655.4,368.9c0.3-3.7,2.7-6,6.8-6.4c3.8-0.3,7.4,3.5,7.1,7.2c-0.3,3.8-2.6,6.3-6.6,6.6
		S655.4,373.2,655.4,368.9z"
        />
        <path
          class="st5"
          d="M485.4,230.7c-3.6-0.6-6.1-2.8-5.9-7.1c0.2-4.2,2.8-6.5,6.9-6.7c3.8-0.2,7,3.5,6.8,7.4
		C492.8,228,489.7,230.9,485.4,230.7z"
        />
        <path
          class="st5"
          d="M508.7,222.3c1-3.3,3.3-5.8,7.5-5.3s6.6,3.5,6.1,7.4c-0.3,3.9-3,6.5-7.3,6.3
		C511.3,230.7,508.2,226.7,508.7,222.3z"
        />
        <path
          class="st5"
          d="M684.6,369.3c0.3-4,2.7-6.9,6.8-6.9s6.8,2.4,6.9,6.8s-2.4,7-6.7,6.9C687.6,376.1,685,373.8,684.6,369.3z"
        />
        <path
          class="st5"
          d="M368.5,842c-3.8-0.5-6.5-2.5-6.5-6.9c-0.1-3.7,2.9-6.7,6.6-6.8c0.3,0,0.5,0,0.9,0c4.2,0.4,6.6,2.9,6.4,7.1
		C375.6,839.7,373,841.8,368.5,842z"
        />
        <path
          class="st5"
          d="M610.5,224.3c-0.4,3.8-2.7,6.5-6.9,6.4s-6.8-2.6-6.8-6.9c0.1-4.3,2.9-6.9,6.9-6.8
		C607.7,217.1,610,219.8,610.5,224.3z"
        />
        <path
          class="st5"
          d="M633.2,230.8c-4.2-0.4-6.8-2.4-7-6.6c-0.2-4.2,2.3-6.7,6.4-7.2c3.7-0.5,7.4,3,7.4,6.9
		C639.7,228.1,637.2,230.1,633.2,230.8z"
        />
        <path
          class="st5"
          d="M581.2,835.1c-0.4,4-2.6,6.6-6.8,6.8s-6.7-2.5-7.2-6.4c-0.4-3.8,3.2-7.4,7-7.1
		C578.5,828.5,580.9,830.9,581.2,835.1z"
        />
        <path
          class="st5"
          d="M111.8,864.3c-0.5,3.8-2.4,6.6-6.8,6.8c-3.6,0.2-6.8-2.6-7-6.1c0-0.4,0-0.6,0-1c0.2-4.3,2.9-6.6,7-6.6
		C109.2,857.4,111.4,860,111.8,864.3z"
        />
        <path
          class="st5"
          d="M222,259.9c-4.2-0.5-6.8-2.7-6.7-7c0.1-4.3,2.4-6.7,6.8-6.7c4.6,0.1,6.9,2.7,6.8,7.1
		C228.9,257.9,226,259.5,222,259.9z"
        />
        <path
          class="st5"
          d="M281.1,900.2c-4.7-0.5-7.1-2.9-7.2-7.1c-0.1-3.8,3.9-7.3,7.5-6.7c4.1,0.7,6.6,3.3,6.2,7.4
		C287.4,898,284.7,900.1,281.1,900.2z"
        />
        <path
          class="st5"
          d="M280.4,246.1c4.3,0.3,7.2,2.3,7.4,6.5c0.2,4.2-2.4,6.8-6.6,7.1c-4.4,0.3-7-2.4-7.2-6.4
		C273.9,249.2,276.2,246.6,280.4,246.1z"
        />
        <path
          class="st5"
          d="M346.5,893.1c-0.3,4.4-2.7,6.9-6.8,7.1c-3.8,0.1-6.9-2.8-7-6.6c0-0.2,0-0.5,0-0.6c0.3-4.1,2.7-6.6,7-6.6
		S345.9,889.4,346.5,893.1z"
        />
        <path
          class="st5"
          d="M193.1,405.2c-4.8-0.4-7.2-2.7-7.1-7s2.8-6.6,7-6.5s6.8,2.7,6.6,7S196.8,405,193.1,405.2z"
        />
        <path
          class="st5"
          d="M398.1,886.4c4.1,0.5,6.8,2.4,6.9,6.7c0.2,3.9-3.1,7.6-6.9,7.2c-4-0.5-6.7-2.6-6.7-7
		C391.4,889,394.1,887.1,398.1,886.4z"
        />
        <path
          class="st5"
          d="M434.6,893.9c-0.4,3.5-2.5,6.1-6.8,6.2c-4.2,0.1-7-2.2-7.1-6.4c-0.1-3.9,2.2-6.8,6.4-7.2
		C430.8,886,434.4,889.3,434.6,893.9z"
        />
        <path
          class="st5"
          d="M281.2,405.3c-4.6-0.4-7.1-2.7-7.1-6.9c0-4.1,2.7-6.7,6.9-6.7c4.2,0,6.8,2.6,6.8,6.8S285,404.9,281.2,405.3z"
        />
        <path
          class="st5"
          d="M309.7,405.3c-3.6-0.4-6.4-2.3-6.4-6.6s2.4-6.7,6.6-7.1c3.8-0.3,7,2.6,7.3,6.2c0,0.2,0,0.5,0,0.6
		C316.8,402.6,314.7,405.1,309.7,405.3z"
        />
        <path
          class="st5"
          d="M105,929.4c-4.2-0.5-6.8-2.5-7-6.7c-0.3-3.6,2.5-6.7,6.1-7c0.3,0,0.6,0,1,0c4.3,0.3,6.6,2.7,6.6,6.9
		C111.6,926.9,109,928.9,105,929.4z"
        />
        <path
          class="st5"
          d="M361.9,397.9c0.9-3.8,2.9-6.3,7.3-6.1c3.7,0.1,6.7,3.1,6.6,6.8c0,0.3,0,0.5-0.1,0.8c-0.5,4.2-3.3,6.2-7.4,5.9
		C363.9,404.9,362,402.1,361.9,397.9z"
        />
        <path
          class="st5"
          d="M170.4,922.7c-0.1,3.8-2.4,6.4-6.6,6.7c-4.2,0.3-6.8-2.2-7.2-6.3c-0.4-3.5,2-6.7,5.5-7.1
		c0.3-0.1,0.9-0.1,1.2,0C167.7,915.8,170,918,170.4,922.7z"
        />
        <path
          class="st5"
          d="M427.6,405.3c-4.5-0.3-6.9-2.8-7-6.8c-0.1-3.9,2.6-6.5,6.8-6.9c3.9-0.3,7.5,3.3,7.2,7.1
		C434.1,402.8,431.6,405,427.6,405.3z"
        />
        <path
          class="st5"
          d="M405.1,922.8c-0.2,3.8-2.3,6.3-6.6,6.5c-3.6,0.4-6.8-2.2-7.1-5.7c0-0.4,0-0.8,0-1.2c0.2-4.3,2.7-6.4,6.8-6.6
		C402.1,915.5,405,918.6,405.1,922.8z"
        />
        <path
          class="st5"
          d="M111.9,980.8c-0.5,3.9-2.7,6.7-6.8,6.7c-4.2,0-6.8-2.3-7-6.6c-0.3-3.6,2.3-6.7,5.8-7.1c0.3,0,0.8,0,1.1,0
		C109.2,974.2,111.4,976.6,111.9,980.8z"
        />
        <path
          class="st5"
          d="M258.5,980.1c-0.2,4.5-2.5,7.1-6.6,7.4s-6.9-1.9-7.1-6.3c-0.2-4.5,2.3-7.1,6.7-7.3
		C255.8,973.7,257.8,976.4,258.5,980.1z"
        />
        <path
          class="st5"
          d="M281,289c-4.2-0.5-6.8-2.7-7.1-6.9c-0.3-3.8,3.4-7.4,7.2-7.1c4.1,0.5,6.8,2.7,6.7,7
		C287.6,286.3,285.1,288.6,281,289z"
        />
        <path
          class="st5"
          d="M310.4,973.8c3.9,0.1,6.2,2.5,6.7,6.6c0.3,3.8-2.6,7-6.4,7.2c-0.2,0-0.4,0-0.6,0c-4.2-0.3-6.7-2.6-6.8-6.9
		C303.1,977,306.3,974,310.4,973.8z"
        />
        <path
          class="st5"
          d="M317,224.3c-0.5,3.8-2.7,6.5-7,6.4s-6.8-2.7-6.8-7s2.9-6.8,6.9-6.8S316.8,219.7,317,224.3z"
        />
        <path
          class="st5"
          d="M457,770.1c4.2,0.3,6.6,2.6,6.8,6.7c0.1,3.8-2.9,6.9-6.7,7c-0.2,0-0.3,0-0.6,0c-4.2-0.4-6.6-2.8-6.5-7.1
		C450.1,772.5,452.7,770.4,457,770.1z"
        />
        <path
          class="st5"
          d="M362.1,658.8c0.9-3.3,3.4-5.5,7.5-5.1c4.3,0.5,6.5,3.6,6.1,7.4c-0.3,3.8-3.1,6.4-7.4,6.2
		C364.5,667.3,361.5,663.4,362.1,658.8z"
        />
        <path
          class="st5"
          d="M361.9,514.8c0.4-4.1,2.9-6.5,7-6.7c3.8-0.3,7.4,3.6,6.9,7.3c-0.5,4-3.2,6.6-7.3,6.4
		C364.5,521.6,362.1,519,361.9,514.8z"
        />
        <path
          class="st5"
          d="M573.1,595.5c4.6-0.2,8.1,3.1,8,7.1c-0.2,3.9-2.6,6.1-6.5,6.7c-3.9,0.5-7.8-3-7.4-6.8
		C567.5,598.4,569.8,595.8,573.1,595.5z"
        />
        <path
          class="st5"
          d="M574.3,770.1c4.2,0.1,7.4,3.2,7,7.1c-0.4,3.9-3,6.4-7.2,6.3c-4.2-0.1-6.5-2.8-6.8-6.9
		C567,773.2,570.5,770.1,574.3,770.1z"
        />
        <path
          class="st5"
          d="M610.5,776.8c-0.4,4.4-2.9,7.1-7,6.9c-4.1-0.2-6.9-2.6-6.8-6.9c0.2-4.3,2.7-6.7,7-6.6
		C608.1,770.3,610,772.9,610.5,776.8z"
        />
        <path
          class="st5"
          d="M346.5,49c-0.4,4-2.3,6.9-6.7,7.1c-4.3,0.2-6.8-2.3-7.3-6.3c-0.3-3.6,2.3-6.9,5.8-7.2c0.3,0,0.6-0.1,1,0
		C343.8,42.5,345.9,45,346.5,49z"
        />
        <path
          class="st5"
          d="M170.4,1271.7c-0.3,4.5-2.8,6.9-6.8,6.9c-4.1,0-6.8-2.3-6.9-6.6c-0.2-3.7,2.7-6.8,6.4-7c0.3,0,0.4,0,0.8,0
		C168.2,1265.2,170,1268,170.4,1271.7z"
        />
        <path
          class="st5"
          d="M486.9,609c-4.7,0-8-3-7.5-7.1c0.3-4.1,2.9-6.5,7.1-6.4s6.7,2.7,6.7,6.9C493.1,606.6,490.4,608.6,486.9,609z"
        />
        <path
          class="st5"
          d="M229.1,79c-0.3,3.6-2.8,6-6.8,6.1c-4.2,0.1-6.7-2.5-7-6.6c-0.2-3.8,2.8-7,6.5-7.1c0.2,0,0.3,0,0.6,0
		C226.6,71.7,228.8,74.2,229.1,79z"
        />
        <path
          class="st5"
          d="M244.8,77.3c0.3-3.4,2.9-5.9,6.8-5.8c4,0.1,6.8,2.5,6.8,6.8c0.1,4.3-2.6,6.6-6.6,7.1
		C247.9,85.8,244.7,82.2,244.8,77.3z"
        />
        <path
          class="st5"
          d="M244.7,1271.9c0.2-4.2,2.4-6.7,6.7-7c3.8-0.2,7,2.7,7.1,6.5c0,0.2,0,0.4,0,0.5c-0.3,4.2-2.9,6.7-6.9,6.7
		S244.9,1276.2,244.7,1271.9z"
        />
        <path
          class="st5"
          d="M141.1,806.2c-0.4,3.8-2.7,6.6-6.8,6.7c-4.1,0.1-6.8-2.4-7.2-6.4c-0.4-3.7,3.3-7.5,7-7.2
		C138.2,799.4,140.7,801.7,141.1,806.2z"
        />
        <path
          class="st5"
          d="M405.2,78.3c-0.5,4.2-2.8,7-7,6.9c-4.1-0.2-6.8-2.5-6.8-6.9c0.1-4.4,2.8-6.8,6.8-6.8S404.7,74.2,405.2,78.3z"
        />
        <path
          class="st5"
          d="M434.6,603c-0.4,3.5-2.7,6-6.8,6.1s-6.9-2.5-7-6.6s2.3-6.7,6.6-7.1C431.1,595.2,434.5,598.3,434.6,603z"
        />
        <path
          class="st5"
          d="M310.6,1278.7c-4.3-0.4-7.1-2.3-7.2-6.7c-0.1-4.4,2.4-6.8,6.7-7.1c4.3-0.3,6.8,2.3,7,6.5
		C317.2,1275.9,314.6,1278.1,310.6,1278.7z"
        />
        <path
          class="st5"
          d="M221.7,100.5c4.3,0.3,7,2.1,7.3,6.4c0.3,4.2-2.7,7.4-6.8,7.1c-4.2-0.3-6.8-2.4-6.8-6.7
		C215.3,103.1,217.7,101.1,221.7,100.5z"
        />
        <path
          class="st5"
          d="M251.5,100.4c4.5,0.4,6.9,2.9,6.8,7c-0.1,4-2.4,6.7-6.8,6.7c-4.2,0-6.8-2.1-6.9-6.5
		C244.6,102.9,247.5,101.1,251.5,100.4z"
        />
        <path
          class="st5"
          d="M163.4,1307.8c-4-0.6-6.8-2.6-6.8-6.9s2.4-6.7,6.8-6.7c4.4,0,6.8,2.3,6.8,6.6S167.8,1307.3,163.4,1307.8z"
        />
        <path
          class="st5"
          d="M258.5,805.8c-0.3,4-2.3,7-6.6,7.1c-4.1,0.1-6.9-2.1-7.1-6.5c-0.2-4.4,2.6-7,6.5-7.2
		C255.1,799,257.8,801.5,258.5,805.8z"
        />
        <path
          class="st5"
          d="M405.2,107.4c-0.5,3.9-2.5,6.8-6.8,6.8c-4.2-0.1-6.9-2.1-6.9-6.6s2.5-6.7,6.8-6.9S404.5,103.2,405.2,107.4z"
        />
        <path
          class="st5"
          d="M428.1,114.3c-4.8-0.2-7.4-2.7-7.4-6.8s2.3-6.9,6.8-6.8c4.2,0,6.9,2.6,7,6.7
		C434.6,111.6,431.7,113.6,428.1,114.3z"
        />
        <path
          class="st5"
          d="M287.9,806c-0.3,3.9-2.6,6.4-6.7,6.9c-3.8,0.5-7.5-3.2-7.3-7c0.3-4.2,2.9-6.8,6.9-6.8
		C284.7,799.1,287.4,801.6,287.9,806z"
        />
        <path
          class="st5"
          d="M229.2,136.3c-0.6,4.4-2.7,7-7,7.1c-4,0-7.4-3.7-6.8-7.4c0.5-4,3.1-6.6,7.3-6.3
		C226.8,129.9,229,132.5,229.2,136.3z"
        />
        <path
          class="st5"
          d="M244.7,136.4c0.1-4.3,2.9-6.7,6.8-6.8c3.9-0.1,6.8,2.3,6.9,6.6c0.2,3.8-3.2,7.4-7,7.1
		C247.2,143,244.9,140.7,244.7,136.4z"
        />
        <path
          class="st5"
          d="M405.1,136.4c-0.3,4.2-2.7,6.9-6.8,7s-6.8-2.3-6.9-6.6c-0.2-4,3-7.6,6.9-7.2S404.8,132.3,405.1,136.4z"
        />
        <path
          class="st5"
          d="M551.7,136.7c-0.4,4.2-2.9,6.8-7.1,6.7c-4.2-0.1-6.7-2.7-6.6-7.1c0.1-4.3,3-6.8,6.9-6.7
		C548.9,129.7,551.5,132.5,551.7,136.7z"
        />
        <path
          class="st5"
          d="M397.9,609.2c-3.7-0.1-6.7-3.2-6.6-6.9c0-0.3,0-0.5,0.1-0.7c0.4-4.2,3.2-6.5,7.4-6.1c4.2,0.4,6.5,2.9,6.2,7.2
		C404.8,606.4,401.6,609.3,397.9,609.2z"
        />
        <path
          class="st5"
          d="M199.9,1300.7c-0.8,4.4-2.7,7.1-7.1,7.1s-6.8-2.6-6.8-6.9s2.5-6.6,6.8-6.6
		C197.2,1294.2,199.3,1297,199.9,1300.7z"
        />
        <path
          class="st5"
          d="M339.5,595.4c4.2,0.5,6.8,2.7,6.8,6.9c0,4.1-2.3,6.6-6.7,6.8c-3.6,0.3-6.8-2.4-7.1-6c0-0.4,0-0.6,0-1
		C332.9,597.8,335.5,595.7,339.5,595.4z"
        />
        <path
          class="st5"
          d="M251.2,1307.7c-4.2-0.3-6.8-3.4-6.4-7.6c0.4-4.4,3.4-6.1,7.4-5.9c4.3,0.3,6.1,3.1,6.1,7.2
		C258.3,1305.3,255.1,1307.9,251.2,1307.7z"
        />
        <path
          class="st5"
          d="M215.3,513.7c0.3-3.2,2.9-5.4,6.9-5.6c3.8-0.2,7.3,3.7,6.8,7.4c-0.4,4.1-3.2,6.3-7.4,6.3
		C218,521.7,215.2,518.4,215.3,513.7z"
        />
        <path
          class="st5"
          d="M170.4,515.4c-0.5,3.7-2.6,6.4-6.8,6.4c-4.1-0.1-6.7-2.5-6.9-6.7c-0.3-3.8,3.4-7.6,7.1-7.1
		C167.7,508.4,170.2,510.7,170.4,515.4z"
        />
        <path
          class="st5"
          d="M508.6,804.9c0.4-3.5,2.9-5.7,7.1-5.8c3.8-0.1,7.4,3.8,6.8,7.5c-0.6,3.7-2.9,6.1-6.8,6.3
		C511.8,813.1,508.5,809.7,508.6,804.9z"
        />
        <path
          class="st5"
          d="M258.4,166.7c-0.3,3.3-2.6,5.5-6.8,5.6s-7-2.9-6.8-7.2c0.3-4.3,3-6.3,7.1-6.4
		C255.4,158.7,258.5,162.2,258.4,166.7z"
        />
        <path
          class="st5"
          d="M141.2,515.4c-0.3,3.5-2.6,6.1-6.8,6.3s-6.8-2.4-7.2-6.4c-0.4-3.6,3.2-7.3,7-7.4
		C137.7,507.9,141,511.1,141.2,515.4z"
        />
        <path
          class="st5"
          d="M574.2,813c-4.7-0.6-7.2-3.3-6.8-7.3c0.3-4,2.9-6.5,7.1-6.5c3.9,0,7.4,3.9,6.8,7.5
		C580.4,810.6,577.8,812.8,574.2,813z"
        />
        <path
          class="st5"
          d="M338.1,157.8c5.5,1.2,8.6,4.4,8.2,8.3c-0.3,3.9-2.8,6-6.8,6c-4.4-0.1-7.8-3.1-6.7-7.1
		C333.6,162.4,336.4,160.2,338.1,157.8z"
        />
        <path
          class="st5"
          d="M281.7,609.1c-5-0.2-7.4-2.5-7.8-6.6c-0.2-3.8,2.8-6.9,6.6-7.1c0.2,0,0.4,0,0.6,0c4.3,0.3,6.6,3,6.8,7
		C287.9,606.6,285.1,608.6,281.7,609.1z"
        />
        <path
          class="st5"
          d="M405.3,163.5c-0.3,6.2-2.6,8.6-6.8,8.7c-4.2,0.1-6.8-2-7-6.4c-0.2-4.1,3.1-8.1,6.8-7.1
		C401.1,159.6,403.5,162.4,405.3,163.5z"
        />
        <path
          class="st5"
          d="M170.3,690.2c-0.3,4.2-2.8,6.2-7.1,6.1c-4.3-0.1-6.8-2.6-6.6-6.9c0.2-4.3,2.8-6.6,7.1-6.5
		C168.3,682.9,170.1,685.7,170.3,690.2z"
        />
        <path
          class="st5"
          d="M258.7,1361c-1.7,1.3-3.9,4-6.6,4.8c-3.9,1.1-7.4-2.6-7.4-6.6c0.1-4.3,2.5-6.7,6.8-6.8
		C256,1352.2,257.8,1355.1,258.7,1361z"
        />
        <path
          class="st5"
          d="M199.8,748.4c-0.3,3.7-2.7,6-6.8,6.3c-3.8,0.1-6.8-2.9-6.9-6.7c0-0.2,0-0.5,0-0.6c0.3-4.1,2.8-6.5,7-6.5
		C196.8,741,199.8,744.3,199.8,748.4z"
        />
        <path
          class="st5"
          d="M515.5,172.4c-4-0.2-6.5-2.4-6.9-6.5c-0.3-3.8,3.3-7.3,7.1-7.1c4.3,0.2,6.5,2.8,6.8,6.9
		C522.7,169.2,519.4,172.4,515.5,172.4z"
        />
        <path
          class="st5"
          d="M551.7,165.6c-0.3,4.4-2.8,6.7-7.1,6.6c-4.3-0.1-6.8-2.5-6.7-6.9s2.8-6.8,6.9-6.6
		C549.1,159,551.6,161.5,551.7,165.6z"
        />
        <path
          class="st5"
          d="M163.7,1381.3c4,0.6,6.7,2.7,6.6,7.1s-2.8,6.7-6.9,6.7c-4.2,0-6.8-2.7-6.8-7S159.4,1381.7,163.7,1381.3z"
        />
        <path
          class="st5"
          d="M163.3,842c-4.3-0.5-6.8-2.7-6.7-7c0.1-4.2,2.6-6.5,6.8-6.7s7,3,6.8,7.2C170.1,840,167.2,841.5,163.3,842z"
        />
        <path
          class="st5"
          d="M192.5,1381.5c4.6,0.2,7.1,2.4,7.3,6.6s-2.6,6.9-6.8,7s-7-2.4-7.1-6.6C185.9,1384.3,188.6,1381.9,192.5,1381.5
		z"
        />
        <path
          class="st5"
          d="M199.8,194.8c-0.5,3.9-2.4,6.7-6.8,6.8c-4.4,0.1-6.9-2.6-7-6.7c-0.1-4.1,2.4-6.8,6.8-6.9
		C197,187.9,199.4,190.4,199.8,194.8z"
        />
        <path
          class="st5"
          d="M141.2,1417.6c-0.5,4-2.9,6.6-7,6.5c-4.2-0.1-6.7-2.6-6.9-6.7c-0.3-3.8,3.4-7.2,7.3-6.9
		C138.8,1410.9,140.8,1413.4,141.2,1417.6z"
        />
        <path
          class="st5"
          d="M170.4,1417.1c-0.2,4.6-2.8,7.1-6.8,7.1s-6.8-2.2-6.9-6.6c-0.2-3.8,2.7-6.9,6.5-7.1c0.2,0,0.4,0,0.6,0
		C168.2,1410.7,169.9,1413.5,170.4,1417.1z"
        />
        <path
          class="st5"
          d="M229.1,689.5c-0.3,4.5-2.9,6.7-6.9,7c-3.9,0.2-7-3.3-6.8-7.3c0.3-4.3,2.9-6.4,7.1-6.3
		C226.7,682.9,228.9,685.6,229.1,689.5z"
        />
        <path
          class="st5"
          d="M346.5,194.3c-0.5,4.4-2.5,7.2-6.9,7.2s-6.9-2.5-7-6.6s2.3-6.8,6.8-6.9C343.8,188,345.8,190.7,346.5,194.3z"
        />
        <path
          class="st5"
          d="M375.8,195c-0.5,3.7-2.3,6.5-6.8,6.7c-3.8,0.1-7.4-3.5-7.1-7.1c0.3-4.1,2.8-6.5,7-6.5c3.8,0,6.8,3,6.8,6.7
		C375.8,194.7,375.8,194.9,375.8,195z"
        />
        <path
          class="st5"
          d="M281.5,754.7c-4.7-0.4-7.3-2.5-7.5-6.7c-0.3-3.8,3.4-7.4,7.1-7.1c4.2,0.4,6.7,2.9,6.8,7
		C287.9,752.2,285.1,754.2,281.5,754.7z"
        />
        <path
          class="st5"
          d="M229.1,661.4c-0.4,3.5-2.9,6-7,5.9s-6.8-2.5-6.8-6.8s2.7-6.5,6.7-6.9S229.1,656.7,229.1,661.4z"
        />
        <path
          class="st5"
          d="M340,740.9c4.1,0.6,6.5,3,6.5,7.1c0,4-2.7,6.6-6.8,6.9c-3.7,0.1-6.8-2.8-6.9-6.5c0-0.3,0-0.5,0-0.8
		C333.1,743.3,335.5,741.2,340,740.9z"
        />
        <path
          class="st5"
          d="M573.1,201.5c-3.6-0.3-6.1-3.4-5.8-6.9l0,0c0.1-3.9,2.6-6.7,6.9-6.7c4.2,0.1,6.8,2.5,7,6.6
		C581.5,198.4,577.8,201.6,573.1,201.5z"
        />
        <path
          class="st5"
          d="M222.7,1439.7c3.9,0.4,6.2,2.6,6.2,6.8c0,4.2-2.5,6.6-6.8,6.8c-3.7,0.2-6.8-2.7-7-6.4c0-0.2,0-0.5,0-0.6
		C215.6,1442,218.1,1439.9,222.7,1439.7z"
        />
        <path
          class="st5"
          d="M663.2,201.5c-4.8,0-8.5-3.6-8.1-7.3c0.4-4.1,3-6.4,7.3-6.3c4.2,0.1,6.8,2.8,6.7,6.9
		C668.9,198.8,666.7,201.5,663.2,201.5z"
        />
        <path
          class="st5"
          d="M170.4,223.9c-0.3,3.8-2.5,6.6-6.8,6.7s-6.8-2.4-7-6.6s2.6-6.9,6.7-7.1C167.4,216.7,169.9,219.4,170.4,223.9z"
        />
        <path
          class="st5"
          d="M427.3,828.3c4.2,0.2,7,2.3,7.2,6.5s-2.4,6.9-6.6,7.1c-4.3,0.3-7-2.4-7.2-6.4
		C420.5,831.5,423,828.7,427.3,828.3z"
        />
        <path
          class="st5"
          d="M111.5,1504.7c-0.4,4.3-2.6,6.6-6.9,6.7c-4.5,0-6.5-2.7-6.6-6.8c-0.1-4.3,2.6-6.5,6.8-6.6
		C109.3,1497.9,111.1,1500.8,111.5,1504.7z"
        />
        <path
          class="st5"
          d="M281.4,230.7c-4.7-0.3-7.2-2.5-7.4-6.7c-0.3-3.8,3.3-7.5,7.1-7.1c4.2,0.4,6.8,2.9,6.7,7.1
		C287.6,228,285.1,230.2,281.4,230.7z"
        />
        <path
          class="st5"
          d="M82.4,1067.8c-0.4,3.8-1.9,6.8-6.5,7c-4,0.2-7.6-3.1-7.4-6.9c0.3-4,2.5-6.6,6.8-6.7
		C79.7,1061.2,81.8,1063.6,82.4,1067.8z"
        />
        <path
          class="st5"
          d="M362,222.6c0.4-3.3,2.9-5.6,7.1-5.7c3.9,0.1,6.8,3.2,6.8,7.1c0,0.2,0,0.4,0,0.5c-0.4,3.8-3,6-6.9,6.3
		C365.1,231.1,361.8,227.5,362,222.6z"
        />
        <path
          class="st5"
          d="M405.1,747.6c-0.2,4.8-2.9,6.9-6.8,7.2c-4,0.4-7-3-6.8-7.2s3-6.5,7-6.5C402.6,741.1,404.8,743.8,405.1,747.6z"
        />
        <path
          class="st5"
          d="M420.6,224.1c-0.1-3.8,3.3-7.5,7.1-7.1c4.2,0.4,6.8,2.7,6.8,7c0,4.2-2.8,6.5-6.8,6.9
		C424,231,420.7,227.7,420.6,224.1z"
        />
        <path
          class="st5"
          d="M156.5,601.7c1-4.1,3.3-6.5,7.6-6.1s6.4,3.1,6,7.3c-0.3,4.4-3.3,6.3-7.4,6
		C158.5,608.6,156.7,605.7,156.5,601.7z"
        />
        <path
          class="st5"
          d="M133.5,1527c5,0.3,7.6,2.7,7.5,6.9c-0.1,4.2-2.8,6.8-6.9,6.8c-4.3,0.1-6.9-2.7-6.8-6.9
		C127.1,1530.2,129.8,1527.1,133.5,1527z"
        />
        <path
          class="st5"
          d="M383.3,1490.2c-3.6-0.2-6.2-3.2-6-6.7c0-0.1,0-0.2,0-0.3c0.1-3.9,2.7-6.6,7-6.7c3.8-0.1,7.5,3.7,7,7.2
		C390.7,1487.8,388.4,1490.2,383.3,1490.2z"
        />
        <path
          class="st5"
          d="M361.7,1483.5c-0.4,4.3-2.7,6.6-6.9,6.7c-4.5,0-6.5-2.7-6.6-6.8c-0.1-4.3,2.7-6.5,6.8-6.6
		C359.4,1476.7,361.2,1479.5,361.7,1483.5z"
        />
        <path
          class="st5"
          d="M383.7,1505.7c5,0.3,7.6,2.7,7.5,6.9c-0.1,4.2-2.8,6.8-6.9,6.8c-4.3,0.1-6.9-2.7-6.8-6.9
		C377.2,1508.9,380,1505.9,383.7,1505.7z"
        />
        <path
          class="st5"
          d="M515.5,479c4.3,0.3,7,2.6,6.9,6.8c-0.1,3.9-2.3,6.8-6.8,6.9c-4,0.1-7.4-3.3-7.2-7.1
		C509,481.3,511.4,479.1,515.5,479z"
        />
        <path
          class="st5"
          d="M192.8,259.8c-4.3-0.5-6.8-2.7-6.8-7c0-4.3,2.8-6.7,6.9-6.5c4.2,0.2,6.7,2.7,6.7,7
		C199.6,257.6,196.7,259.2,192.8,259.8z"
        />
        <path
          class="st5"
          d="M192.8,288.7c-4.3-0.5-6.8-2.7-6.8-7s2.8-6.7,6.9-6.5c4.2,0.2,6.7,2.7,6.7,7
		C199.6,286.6,196.7,288.3,192.8,288.7z"
        />
        <path
          class="st5"
          d="M163.4,288.7c-4.4-0.5-6.8-2.7-6.8-7s2.8-6.7,6.9-6.5c4.2,0.2,6.7,2.7,6.7,7
		C170.2,286.6,167.3,288.3,163.4,288.7z"
        />
        <path
          class="st5"
          d="M252,246.1c4.2,0.6,6.7,3.1,6.4,7.3c-0.3,4.2-2.9,6.7-7.2,6.3s-6.6-2.8-6.4-7.1
		C244.9,248.1,247.9,246.4,252,246.1z"
        />
        <path
          class="st5"
          d="M258.5,1592.3c-0.4,4.1-2.6,6.6-7,6.5s-6.7-2.5-6.8-6.7s2.9-7.1,7.2-6.9C256.4,1585.5,258,1588.2,258.5,1592.3
		z"
        />
        <path
          class="st5"
          d="M280.8,1614.2c4.5,0.4,6.9,2.9,7,7c0,4.2-2.9,6.7-7,6.7c-4.2,0-6.8-2.7-6.8-6.9
		C274.1,1616.9,276.7,1614.6,280.8,1614.2z"
        />
        <path
          class="st5"
          d="M375.7,252.7c-0.3,4-2,6.8-6.5,7.1c-4.2,0.3-7.4-2.6-7.3-6.7c0.1-4.1,2.4-6.7,6.7-6.8
		C372.9,246.2,375.3,248.4,375.7,252.7z"
        />
        <path
          class="st5"
          d="M398.3,246.1c4.2,0.4,6.8,2.7,6.8,6.8c0,4.1-2.3,6.7-6.6,6.9c-4.2,0.2-7.2-2.7-7.1-7
		C391.5,248.3,394.2,246.6,398.3,246.1z"
        />
        <path
          class="st5"
          d="M427.9,259.7c-4.5-0.2-7.1-2.6-7.2-6.7c-0.1-4.1,2.5-6.9,6.8-6.9c4.2,0,6.9,2.4,7,6.6
		C434.6,257,431.9,259.2,427.9,259.7z"
        />
        <path
          class="st5"
          d="M258.5,1651.1c-0.1,3.5-2.5,5.8-6.8,5.9s-6.8-2.2-6.9-6.5s2.2-6.7,6.4-7.1
		C255.2,1643,258.3,1646.1,258.5,1651.1z"
        />
        <path
          class="st5"
          d="M134.7,609.1c-4.6-0.4-7.2-2.5-7.4-6.7c-0.1-3.8,2.9-6.9,6.7-7c0.2,0,0.3,0,0.5,0c4.2,0.4,6.7,3,6.7,7
		S138.3,608.8,134.7,609.1z"
        />
        <path
          class="st5"
          d="M551.9,252.4c-0.5,4.4-2.4,7.2-6.8,7.3c-4.2,0.1-6.9-2.1-7-6.5s2.3-6.8,6.6-7.1
		C549.1,245.9,551,248.8,551.9,252.4z"
        />
        <path
          class="st5"
          d="M574.3,259.8c-4.4-0.3-6.9-2.8-6.9-6.9c0-4,2.7-6.8,6.9-6.7s6.8,2.7,6.8,6.8S578.3,259.3,574.3,259.8z"
        />
        <path
          class="st5"
          d="M280.8,1657.1c-4.4-0.3-6.8-2.9-6.8-6.9s2.4-6.7,6.8-6.8c3.8-0.2,7.5,3.4,7.1,7.1
		C287.4,1654.5,284.8,1656.7,280.8,1657.1z"
        />
        <path
          class="st5"
          d="M463.7,485.7c-0.3,3.9-2.3,6.8-6.7,6.9c-4.1,0-7-2.2-7.1-6.5s2.3-6.8,6.7-6.9
		C461.1,479.1,463.3,481.2,463.7,485.7z"
        />
        <path
          class="st5"
          d="M845.1,252.6c-0.3,4.3-2.4,7-6.7,7.1c-4.2,0.2-6.8-1.9-7.1-6.2c-0.4-3.6,2-6.8,5.6-7.2c0.3,0,0.8-0.1,1.1-0.1
		C842.3,246.2,844.6,248.7,845.1,252.6z"
        />
        <path
          class="st5"
          d="M258.5,281.6c-0.2,4.5-2.4,7.1-6.6,7.3c-4.2,0.3-6.8-2.1-7.2-6.3c-0.3-4,2.8-7.6,6.8-7.4
		C255.5,275.3,257.9,277.7,258.5,281.6z"
        />
        <path
          class="st5"
          d="M170.3,863.5c-0.2,4.5-2.2,7.3-6.5,7.5s-6.9-2.1-7.2-6.4c-0.3-4.3,2.2-6.9,6.4-7.1S169.9,859.6,170.3,863.5z"
        />
        <path
          class="st5"
          d="M375.8,485.6c-0.4,3.7-1.8,6.8-6.4,7c-4.5,0.2-7.2-2.5-7.4-6.7c-0.2-4.1,2.3-6.6,6.7-6.8
		C373,479,375.3,481.2,375.8,485.6z"
        />
        <path
          class="st5"
          d="M493.2,573.6c-0.8,3.9-2.9,6.6-7.2,6.4c-4.3-0.2-6.7-2.6-6.6-6.9c0.1-4.3,2.8-6.6,6.9-6.5
		S492.8,569.3,493.2,573.6z"
        />
        <path
          class="st5"
          d="M281.9,871.1c-4.8,0.1-8.4-3.3-8.1-7.1c0.3-4,2.9-6.7,7-6.6c4.1,0.2,6.8,2.5,6.8,6.8
		C287.6,868.3,285.3,870.8,281.9,871.1z"
        />
        <path
          class="st5"
          d="M786.6,281.6c-0.3,4.3-2.4,6.9-6.5,7.4c-3.9,0.5-7.4-2.8-7.3-6.9c0.1-4.2,2.4-6.8,6.7-6.9
		C783.7,275.2,785.9,277.7,786.6,281.6z"
        />
        <path
          class="st5"
          d="M163.6,479c4.2,0.4,6.7,2.5,6.7,6.9s-2.6,6.7-6.8,6.7s-6.8-2.4-6.8-6.7C156.6,481.6,159.2,479.3,163.6,479z"
        />
        <path
          class="st5"
          d="M346.5,864.2c-0.5,4-2.5,7-6.9,6.9c-4.2,0-6.8-2.3-6.8-6.7s2.3-6.6,6.7-6.8S345.9,860.2,346.5,864.2z"
        />
        <path
          class="st5"
          d="M361.8,863.8c0.6-3.8,2.8-6.4,7-6.3c4.2,0.1,6.7,2.4,6.8,6.7c0.3,3.6-2.4,6.6-5.9,6.9c-0.4,0-0.9,0-1.3,0
		C364.4,870.7,362.1,868.3,361.8,863.8z"
        />
        <path
          class="st5"
          d="M603.6,463.5c-4.2-0.3-6.9-2.4-6.8-6.7s2.5-6.8,6.8-6.8s6.8,2.4,6.8,6.7S607.9,463.1,603.6,463.5z"
        />
        <path
          class="st5"
          d="M346.5,281.8c-0.3,4.1-2.4,6.9-6.6,7.1c-4.2,0.3-6.8-1.8-7.2-6.2s2.4-7.4,6.6-7.4S345.9,277.7,346.5,281.8z"
        />
        <path
          class="st5"
          d="M515.1,463.5c-3.7-0.2-6.2-2.4-6.6-6.5c-0.1-3.8,2.9-7.1,6.8-7.1c0.1,0,0.3,0,0.3,0c4.2,0.3,6.5,3,6.8,7
		C522.8,460.4,519.4,463.4,515.1,463.5z"
        />
        <path
          class="st5"
          d="M485.9,857.5c4.5,0.2,7,2.6,7.1,6.7c0.1,4.1-2.4,6.9-6.8,6.9s-6.8-2.3-6.9-6.7
		C479.2,859.9,481.8,857.9,485.9,857.5z"
        />
        <path
          class="st5"
          d="M192.1,318c-3.8-0.3-6.1-2.9-6.1-7c0.1-4,2.6-6.6,6.8-6.7c3.9,0,7,3.2,7,7.1c0,0.1,0,0.2,0,0.3
		C199.6,315.3,196.4,318,192.1,318z"
        />
        <path
          class="st5"
          d="M162.7,318c-3.8-0.3-6.1-2.9-6.1-7c0.1-4,2.6-6.6,6.8-6.7c3.9,0,7,3.1,7,7c0,0.1,0,0.2,0,0.3
		C170.3,315.3,167.2,318,162.7,318z"
        />
        <path
          class="st5"
          d="M133.3,318c-3.8-0.3-6.1-2.9-6.1-7c0.1-4,2.6-6.6,6.8-6.7c3.9,0,7,3.2,7,7.1c0,0.1,0,0.2,0,0.3
		C140.7,315.3,137.7,318,133.3,318z"
        />
        <path
          class="st5"
          d="M220.9,303.2c5.3,1,8.5,4.5,8.2,8.4s-2.8,6-6.8,6.3c-4.1,0.2-8.1-3.3-7-6.9
		C216.3,308.2,219.1,305.8,220.9,303.2z"
        />
        <path
          class="st5"
          d="M258.5,312c-0.2,3.5-2.7,5.9-6.8,6c-4.2,0.1-6.8-2.1-6.8-6.5c-0.1-4.2,2.9-7.3,6.9-7.1
		C255.2,304.4,258.5,307.6,258.5,312z"
        />
        <path
          class="st5"
          d="M280.1,304.3c4.6,0,8.1,3.4,7.6,7.3s-2.7,6.1-6.7,6.3c-3.7,0.4-6.9-2.3-7.2-6c0-0.4,0-0.6,0-1
		C274.3,307.1,276.6,304.5,280.1,304.3z"
        />
        <path
          class="st5"
          d="M369.1,566.3c3.8,0.3,6.5,2.3,6.8,6.6c0.2,3.7-2.7,6.9-6.4,7.1c-0.3,0-0.5,0-0.8,0c-4.1-0.4-6.7-2.6-6.8-6.9
		C361.9,569.4,364.9,566.4,369.1,566.3z"
        />
        <path
          class="st5"
          d="M545.2,857.4c4.3,0.6,6.7,3,6.4,7.3s-2.9,6.6-7.1,6.4c-4.2-0.2-6.6-2.9-6.5-7.1
		C538.1,859.5,541.3,857.8,545.2,857.4z"
        />
        <path
          class="st5"
          d="M428.4,463.5c-5.2-0.2-7.5-2.5-7.9-6.7c-0.1-3.8,2.9-6.9,6.8-7c0.2,0,0.3,0,0.4,0c4.2,0.5,6.6,3,6.6,7.1
		C434.5,461.1,431.7,463.2,428.4,463.5z"
        />
        <path
          class="st5"
          d="M398.3,318c-4.2-0.5-6.8-2.4-6.8-6.8c0-4,3.3-7.4,7.2-7c4,0.5,6.6,2.9,6.5,7.1
		C404.9,315.8,402.2,317.6,398.3,318z"
        />
        <path
          class="st5"
          d="M427.6,318c-4.2-0.2-6.9-2.6-7-6.7c-0.1-3.9,2.3-6.8,6.6-7.1c4-0.2,7.2,2.9,7.4,6.8c0,0.1,0,0.1,0,0.2
		C434.5,315,431.3,318,427.6,318z"
        />
        <path
          class="st5"
          d="M134.9,900.2c-4.8-0.3-7.3-2.5-7.6-6.6c-0.1-3.8,2.9-7.1,6.8-7.1c0.1,0,0.2,0,0.3,0c4.2,0.3,6.7,2.9,6.8,7
		C141.2,897.5,138.4,899.7,134.9,900.2z"
        />
        <path
          class="st5"
          d="M75.9,1439.7c4.2,0.4,6.5,2.7,6.2,7.1c-0.2,4.3-2.6,6.7-6.9,6.5s-6.8-2.8-6.7-7
		C68.7,1442.1,71.5,1439.8,75.9,1439.7z"
        />
        <path
          class="st5"
          d="M515.1,317.9c-3.8-0.3-6.4-2.4-6.5-6.7c-0.1-3.9,3.6-7.4,7.3-7c4.2,0.5,6.5,3,6.6,7.1
		C522.6,315.1,519.4,318,515.1,317.9z"
        />
        <path
          class="st5"
          d="M551.7,310.6c-0.3,4.9-2.6,7.2-6.9,7.2c-4.3,0-6.8-2.4-6.8-6.7s2.6-6.8,6.8-6.8
		C549.1,304.3,551.3,307.1,551.7,310.6z"
        />
        <path
          class="st5"
          d="M610.4,311.9c-0.3,3.7-2.7,6.1-6.9,6c-4.2-0.2-6.7-2.5-6.8-6.7c0-3.8,3-6.9,6.9-6.9c0.2,0,0.3,0,0.4,0
		C607.8,304.5,610.6,307.5,610.4,311.9z"
        />
        <path
          class="st5"
          d="M508.5,660.7c0.3-4.5,2.9-6.8,6.9-7.1c3.9-0.3,7.5,3.5,7,7.2c-0.5,3.9-3,6.6-7.2,6.5S509.1,664.5,508.5,660.7z
		"
        />
        <path
          class="st5"
          d="M662.2,318c-4.2-0.4-6.8-2.7-6.9-6.8c-0.1-3.8,2.9-6.9,6.8-7c0.2,0,0.3,0,0.6,0c4.2,0.4,6.7,3.2,6.6,7.1
		C669.2,315.2,666.3,317.9,662.2,318z"
        />
        <path
          class="st5"
          d="M721.3,318c-4.7-0.1-7-2.7-7.5-6.6c-0.4-3.7,3.3-7.3,7.1-7.1c4.1,0.4,6.8,2.7,6.8,6.9S725.2,317.6,721.3,318z"
        />
        <path
          class="st5"
          d="M222,566.3c4.3,0.4,6.9,2.5,7.2,6.7c0.2,3.7-2.6,6.8-6.2,7c-0.3,0-0.6,0-1,0c-4.1-0.4-6.8-2.6-6.7-6.9
		C215.4,568.8,218,566.7,222,566.3z"
        />
        <path
          class="st5"
          d="M551.7,718.6c-0.4,4.2-2.5,6.7-6.8,6.9c-3.7,0.2-6.8-2.7-6.9-6.4c0-0.3,0-0.5,0-0.8c0.3-4.3,3-6.5,7-6.5
		C549.3,711.9,551.5,714.6,551.7,718.6z"
        />
        <path
          class="st5"
          d="M581.1,718.6c-0.3,4.2-2.5,6.7-6.8,6.9c-3.7,0.2-6.8-2.7-6.9-6.4c0-0.3,0-0.5,0-0.8c0.3-4.3,3-6.5,7.1-6.5
		C578.6,711.9,580.8,714.6,581.1,718.6z"
        />
        <path
          class="st5"
          d="M808.3,318c-3.8-0.4-6.2-2.7-6.4-6.8c0.1-3.8,3.2-7,7.2-7c0.1,0,0.2,0,0.3,0c4.2,0.5,6.4,3.3,6.5,7.2
		C816.1,315.2,812.6,318.1,808.3,318z"
        />
        <path
          class="st5"
          d="M134.5,711.9c4.2,0.6,6.8,2.8,6.6,7.1c-0.3,4.1-2.7,6.8-7,6.6s-6.8-3-6.7-7.1
		C127.5,714.4,130.2,712,134.5,711.9z"
        />
        <path
          class="st5"
          d="M193.1,915.6c3.9,0.4,6.6,2.5,6.6,6.7c0,4.1-2.4,6.7-6.7,7c-3.9,0.3-7.6-3.3-7.3-7
		C186.2,918.4,188.6,915.9,193.1,915.6z"
        />
        <path
          class="st5"
          d="M170.4,340.5c-0.2,3.9-2.3,6.5-6.7,6.5c-4.3,0-6.9-2.2-7-6.6s2.3-6.8,6.7-6.9
		C167.6,333.5,170,335.7,170.4,340.5z"
        />
        <path
          class="st5"
          d="M193.1,333.5c3.6,0.4,6.4,2.2,6.5,6.5c0.1,4.1-2.2,6.7-6.5,7.1c-3.8,0.3-7.1-2.6-7.4-6.3c0-0.2,0-0.3,0-0.5
		C185.9,336.5,188.9,333.5,193.1,333.5z"
        />
        <path
          class="st5"
          d="M229.1,340.1c-0.4,3.8-1.9,6.8-6.5,7c-4,0.2-7.6-3-7.4-6.9c0.3-4.1,2.4-6.7,6.8-6.7
		C226.5,333.5,228.7,335.7,229.1,340.1z"
        />
        <path
          class="st5"
          d="M221.1,915.7c5.4,0,7.8,2.3,7.8,6.5c0,4-1.9,6.8-6.4,7c-4,0.2-7.5-3.1-7.3-6.9
		C215.4,918.3,217.8,915.9,221.1,915.7z"
        />
        <path
          class="st5"
          d="M199.9,573c-0.9,4.4-2.9,7.1-7.3,7c-4.4-0.1-6.8-2.7-6.6-7s2.8-6.5,7-6.5C197.7,566.5,199.3,569.4,199.9,573z"
        />
        <path
          class="st5"
          d="M309.8,929.5c-4-0.6-6.6-2.7-6.5-7s2.6-6.7,6.8-6.7c4.2,0,6.8,2.4,6.6,6.8
		C316.6,926.7,314.2,929.1,309.8,929.5z"
        />
        <path
          class="st5"
          d="M170.3,571.8c-0.1,5.4-2.3,8.1-6.6,8.2c-4.2,0.1-6.9-2.2-7.1-6.5c-0.2-4.3,2.3-6.7,6.6-7
		C167.4,566.2,169.9,568.8,170.3,571.8z"
        />
        <path
          class="st5"
          d="M463.7,340.1c-0.3,4.1-2.4,6.9-6.8,6.9c-4.2,0-7-2.2-7-6.5s2.3-6.8,6.7-6.9
		C461.1,333.5,463.3,335.8,463.7,340.1z"
        />
        <path
          class="st5"
          d="M170.3,718c-0.1,4.7-2.3,7.4-6.5,7.5s-6.9-2.1-7.1-6.5c-0.2-4.4,2.3-6.8,6.5-7
		C167.4,711.9,169.9,714.1,170.3,718z"
        />
        <path
          class="st5"
          d="M98,951.6c0.3-4.2,2.9-6.8,6.8-6.9c4-0.1,6.8,2.2,6.9,6.6c0.2,3.9-3.2,7.6-6.9,7.2
		C100.7,958.1,98.2,955.8,98,951.6z"
        />
        <path
          class="st5"
          d="M141.1,952.3c-0.6,3.6-2.9,6.2-7.1,6.1s-6.8-2.8-6.8-6.9c0-4,2.9-6.6,6.8-7
		C137.8,944.2,141.4,948.1,141.1,952.3z"
        />
        <path
          class="st5"
          d="M662.9,333.5c3.7,0.2,6.2,2.6,6.2,6.7c0,3.9-2.3,6.8-6.7,7c-4,0.1-7.3-3.1-7.4-7.1
		C655.2,336.4,658.4,333.4,662.9,333.5z"
        />
        <path
          class="st5"
          d="M215.2,951.1c0.3-3.8,2.7-6,6.8-6.4c3.8-0.4,7.3,3.4,7,7.2c-0.3,3.9-2.4,6.3-6.4,6.6
		C218.6,958.8,215.2,955.5,215.2,951.1z"
        />
        <path
          class="st5"
          d="M757.2,340.6c-0.3,3.8-2.5,6.4-6.8,6.5s-6.8-2.2-7.2-6.4c-0.4-4.2,2.7-7.2,6.9-7.1
		C754.4,333.6,756.9,335.9,757.2,340.6z"
        />
        <path
          class="st5"
          d="M134.8,376.2c-4.7-0.1-7.2-2.4-7.6-6.5c-0.2-3.8,2.9-7.1,6.7-7.2c0.1,0,0.3,0,0.3,0c4.2,0.3,6.8,2.7,6.8,6.9
		C141,373.5,138.4,375.7,134.8,376.2z"
        />
        <path
          class="st5"
          d="M170.4,369.3c-0.3,3.8-2.3,6.8-6.8,6.9c-4.2,0-6.9-2.3-7-6.6s2.6-7,6.7-7.1
		C167.3,362.3,169.9,364.9,170.4,369.3z"
        />
        <path
          class="st5"
          d="M229,370.4c-0.2,3.3-2.6,5.6-6.8,5.9c-3.9,0.3-7.2-3.3-6.9-7.3c0.2-4.3,3-6.4,7-6.6
		C226.2,362.3,229,365.5,229,370.4z"
        />
        <path
          class="st5"
          d="M281.8,943.5c1.9,2.6,4.7,4.8,5.7,7.7c1.2,3.3-3,7.5-6.8,7.3c-4.2-0.2-7.2-2.8-6.8-6.7c0.3-2.8,3.2-5.4,5-8.2
		L281.8,943.5z"
        />
        <path
          class="st5"
          d="M281.5,376.2c-4.6-0.2-7.2-2.2-7.6-6.4c-0.3-3.8,3.1-7.6,6.9-7.4c4.2,0.2,6.8,2.7,6.9,6.8
		C288,373.5,285.2,375.6,281.5,376.2z"
        />
        <path
          class="st5"
          d="M310.1,376.4c-4.6-0.8-7-3.2-6.8-7.4c0.2-4.5,3.3-6.6,7.3-6.4c4.1,0.2,6.7,3,6.4,7.3
		C316.7,374.2,313.8,376,310.1,376.4z"
        />
        <path
          class="st5"
          d="M405.1,370.2c-0.8,3.6-2.9,6.4-7.2,6c-4.1-0.4-6.8-2.7-6.6-7.1c0.2-4.5,3.1-6.8,7.1-6.6
		C402.4,362.6,405,365.3,405.1,370.2z"
        />
        <path
          class="st5"
          d="M545,537.2c4,0.4,6.7,2.6,6.8,6.7c0.1,4.1-2.3,6.7-6.5,7c-3.6,0.4-6.8-2.2-7.1-5.7c0-0.4-0.1-0.8,0-1.2
		C538.1,539.6,540.7,537.6,545,537.2z"
        />
        <path
          class="st5"
          d="M493.2,543.9c-0.3,4.2-2.4,6.8-6.8,6.8s-6.8-2.2-6.9-6.5s2.3-6.8,6.7-6.9C490.4,537.2,492.6,539.9,493.2,543.9
		z"
        />
        <path
          class="st5"
          d="M170.3,981.8c-0.1,3.3-2.7,5.5-6.8,5.6s-6.8-2.2-6.9-6.5c-0.2-3.8,2.8-7,6.6-7.1c0.2,0,0.3,0,0.5,0
		C167.7,973.9,170.3,976.6,170.3,981.8z"
        />
        <path
          class="st5"
          d="M215.2,979.7c0.9-3.7,3.1-6,7.4-5.9c3.6,0.1,6.5,3,6.5,6.6c0,0.4,0,0.7-0.1,1c-0.5,4.3-3.4,6.2-7.4,5.9
		C217.6,987,215.2,984.3,215.2,979.7z"
        />
        <path
          class="st5"
          d="M229.2,718.3c-0.3,4.2-2.3,7-6.6,7.3c-3.8,0.3-7.1-2.5-7.4-6.2c0-0.2,0-0.4,0-0.5c0.2-4.2,2.5-6.7,6.8-6.8
		S228.7,714.2,229.2,718.3z"
        />
        <path
          class="st5"
          d="M111.8,399.3c-0.3,3.2-2.4,5.9-6.7,6c-4.2,0.1-6.8-2.1-7-6.4c-0.3-3.7,2.3-6.9,6-7.1c0.3,0,0.6,0,1,0
		C108.7,391.7,111.6,394.6,111.8,399.3z"
        />
        <path
          class="st5"
          d="M134.3,405.3c-4.3-0.3-7-2.7-7-6.7s2.5-6.9,6.8-6.9c4.2,0,6.9,2.4,7,6.7C141.2,402.8,138.4,404.8,134.3,405.3z
		"
        />
        <path
          class="st5"
          d="M339.6,725.7c-4.2-0.5-6.8-2.5-6.8-6.8c-0.1-4.3,2.3-6.7,6.6-6.8s6.8,2.4,7,6.5
		C346.5,722.8,343.8,725,339.6,725.7z"
        />
        <path
          class="st5"
          d="M229.1,398.5c-0.3,4.4-2.5,6.9-6.9,6.8s-6.8-2.5-6.8-6.8c0.1-4.1,2.4-6.9,6.8-6.8
		C226.4,391.9,228.8,394.2,229.1,398.5z"
        />
        <path
          class="st5"
          d="M141.2,1009.4c-0.4,3.9-2.2,6.9-6.5,7.1c-3.9,0.3-7.6-2.9-7.4-6.8c0.2-4.1,2.5-6.8,6.8-6.9
		C138.2,1002.8,140.5,1005.4,141.2,1009.4z"
        />
        <path
          class="st5"
          d="M163.9,1003c4.2,0.5,6.7,2.8,6.4,7.1s-2.9,6.6-7.1,6.3c-4.2-0.3-6.8-2.8-6.5-7.1
		C156.9,1005,159.7,1003.2,163.9,1003z"
        />
        <path
          class="st5"
          d="M398.7,391.6c4.1,0.7,6.6,3.1,6.4,7.2s-2.7,6.5-7,6.5c-3.6,0.1-6.6-2.7-6.7-6.1c0-0.4,0-0.8,0.1-1.2
		C391.8,393.6,394.6,391.9,398.7,391.6z"
        />
        <path
          class="st5"
          d="M463.9,398.2c-0.1,4.4-3.3,7.5-7.4,7.1c-4.2-0.4-6.6-2.9-6.4-7.1c0.2-4.1,2.9-6.6,7-6.5
		C461.3,391.9,463.5,394.4,463.9,398.2z"
        />
        <path
          class="st5"
          d="M199.9,1009.5c-0.5,4-2.3,7-6.8,7.1c-4.4,0.1-6.8-2.3-7.1-6.5s2.3-6.8,6.5-7.1
		C196.8,1002.7,199.2,1005.4,199.9,1009.5z"
        />
        <path
          class="st5"
          d="M545.2,391.6c4,0.6,6.7,2.8,6.5,7.1c-0.2,4-2.5,6.6-6.8,6.7c-3.6,0.2-6.7-2.5-6.8-6c0-0.4,0-0.8,0-1.2
		C538.3,393.8,541,391.9,545.2,391.6z"
        />
        <path
          class="st5"
          d="M229.1,1008.8c-0.2,5.4-2.4,8.1-7.1,7.8c-4.2-0.2-6.8-2.7-6.7-6.9s2.6-6.7,6.8-6.7S228.8,1005.4,229.1,1008.8z
		"
        />
        <path
          class="st5"
          d="M603,391.6c4.7,0.3,7.3,2.5,7.3,6.7c0,4.1-2.2,6.8-6.7,6.9s-6.8-2.3-6.9-6.5
		C596.7,394.4,599.4,392.2,603,391.6z"
        />
        <path
          class="st5"
          d="M280.8,1016.7c-4.2-0.5-6.7-2.7-6.9-6.8c-0.1-3.8,2.9-6.9,6.7-7c0.2,0,0.4,0,0.6,0c4.1,0.5,6.8,2.7,6.6,7
		C287.5,1014.1,285.1,1016.4,280.8,1016.7z"
        />
        <path
          class="st5"
          d="M691.8,405.4c-4.3-0.5-7.1-2.4-7.1-6.8s2.4-6.8,6.7-6.9c4.2-0.1,6.8,2.4,6.9,6.7
		C698.4,402.8,695.8,404.8,691.8,405.4z"
        />
        <path
          class="st5"
          d="M250.7,434.5c-3.6-0.5-6-2.7-6-7c-0.1-3.8,3.6-7.6,7.3-7c3.9,0.6,6.6,2.9,6.4,7.3
		C258.1,431.6,255,434.6,250.7,434.5z"
        />
        <path
          class="st5"
          d="M405.1,427.5c-0.3,4.1-2.5,6.8-6.7,7s-6.8-2.3-7.1-6.4c-0.3-3.9,2.9-7.7,6.8-7.3
		C402.1,421.1,404.8,423.2,405.1,427.5z"
        />
        <path
          class="st5"
          d="M544.6,434.5c-3.6-0.3-6.1-2.3-6.6-6.5c-0.3-3.9,2.9-7.6,6.8-7.3c4,0.3,6.7,2.5,6.8,6.8
		c0.1,3.8-2.9,7.1-6.8,7.1C544.7,434.5,544.7,434.5,544.6,434.5z"
        />
        <path
          class="st5"
          d="M164,420.9c3.7,0.4,6.4,2.4,6.4,6.8s-2.6,6.8-6.8,6.8s-6.8-2.5-6.8-6.8S159.2,421.2,164,420.9z"
        />
        <path
          class="st5"
          d="M339.4,1002.9c4.3,0.5,7,2.7,6.9,7.1c-0.1,4.3-2.6,6.8-6.9,6.7s-6.7-2.6-6.8-6.9
		C332.6,1005.5,335.5,1003.4,339.4,1002.9z"
        />
        <path
          class="st5"
          d="M339.4,420.7c4.2,0.5,7,2.6,7,6.9s-2.6,6.9-6.8,6.9c-4.2,0-6.8-2.3-6.8-6.7S335.4,421.4,339.4,420.7z"
        />
        <path
          class="st5"
          d="M391.3,544.3c0.2-4.5,2.4-6.9,6.7-7.1s6.7,2.3,7.1,6.4c0.3,3.7-2.6,6.9-6.2,7.1c-0.2,0-0.4,0-0.8,0
		C393.8,550.6,391.7,548.3,391.3,544.3z"
        />
        <path
          class="st5"
          d="M251.7,537.1c4.3,0.7,6.9,3.1,6.8,7.2c-0.3,4-2.7,6.7-7.1,6.5s-6.6-2.7-6.5-7.1
		C244.9,539.2,247.9,537.7,251.7,537.1z"
        />
        <path
          class="st5"
          d="M229.1,543.7c-0.3,4.2-2.2,6.9-6.7,7.1c-4.5,0.2-6.9-2.1-7.1-6.4c-0.2-4.3,2.2-6.9,6.5-7.1
		C226.2,537.1,228.5,539.5,229.1,543.7z"
        />
        <path
          class="st5"
          d="M479.4,719.3c0.2-4.9,2.5-7.1,6.8-7.2c4.3-0.1,6.8,2.6,6.8,6.7c0.1,4.1-2.5,6.8-6.8,6.8
		C481.8,725.5,479.8,722.9,479.4,719.3z"
        />
        <path
          class="st5"
          d="M340,1061.3c3.9,0.3,6.4,2.6,6.5,6.7s-2.3,6.6-6.6,6.9c-4,0.3-7.6-3.1-7.3-7
		C333,1063.8,335.2,1061.4,340,1061.3z"
        />
        <path
          class="st5"
          d="M192.8,537.2c3.9,0.3,6.8,2.3,6.8,6.6c0.1,4.3-2.3,6.7-6.6,6.9s-7-2.1-7.1-6.3
		C185.9,540.1,188.3,537.7,192.8,537.2z"
        />
        <path
          class="st5"
          d="M229.1,457.6c-0.6,3.6-2.8,6.1-7.1,5.9c-4.2-0.3-6.8-2.6-6.7-6.8c0.3-3.9,2.5-6.7,6.8-6.9
		C226.1,449.7,229.3,453.2,229.1,457.6z"
        />
        <path
          class="st5"
          d="M258.5,457.5c0,3.3-2.5,5.8-6.6,6s-6.8-1.9-7.1-6.3c-0.3-3.7,2.3-7.1,6-7.4c0.2,0,0.4,0,0.6,0
		C255,449.7,258.3,452.9,258.5,457.5z"
        />
        <path
          class="st5"
          d="M281.5,463.5c-5-0.2-7.3-2.6-7.5-6.7c-0.1-3.8,2.9-6.9,6.7-7c0.2,0,0.3,0,0.6,0c4.2,0.4,6.7,3,6.6,7.1
		C287.6,461,285.1,463.2,281.5,463.5z"
        />
        <path
          class="st5"
          d="M229.1,1098c-0.6,3.5-2.7,6.1-7,6c-3.8,0-6.8-3.1-6.8-6.9c0-0.2,0-0.4,0-0.6c0.4-4.2,3.3-6.5,7.3-6.2
		C226.6,1090.6,229.1,1093.1,229.1,1098z"
        />
        <path
          class="st5"
          d="M111.9,543.8c-0.4,4.1-2.5,6.9-6.8,7s-6.8-2.2-7-6.5s2.4-6.8,6.7-7C109,537,111.2,539.8,111.9,543.8z"
        />
        <path
          class="st5"
          d="M134.6,653.6c4.1,0.5,6.7,2.8,6.6,7.1c-0.1,4.2-2.9,6.5-6.9,6.8c-3.8,0.3-7.5-3.7-7-7.2
		C127.8,656.1,130.3,653.8,134.6,653.6z"
        />
        <path
          class="st5"
          d="M75.4,550.8c-4.3-0.4-6.8-2.7-6.8-7s2.6-6.7,6.9-6.6s6.8,2.6,6.7,6.9C82,548.4,79.7,550.7,75.4,550.8z"
        />
        <path
          class="st5"
          d="M517.5,755c-5.9-0.7-8.7-2.7-8.8-7c-0.1-3.8,2.9-7.1,6.8-7.1c0.1,0,0.3,0,0.3,0c4.2,0.3,7.1,3.2,6.6,7
		C522,750.7,518.9,753.1,517.5,755z"
        />
        <path
          class="st5"
          d="M141.2,1126.6c-0.8,3.8-2.9,6.5-7.2,6.3s-6.7-2.7-6.8-6.8c-0.2-3.9,3.4-7.1,7.4-6.7
		C139,1119.8,140.8,1122.5,141.2,1126.6z"
        />
        <path
          class="st5"
          d="M141.2,1097.6c-0.8,3.8-2.9,6.5-7.2,6.3s-6.7-2.7-6.8-6.8c-0.2-3.9,3.4-7.1,7.4-6.7
		C139,1090.7,140.8,1093.5,141.2,1097.6z"
        />
        <path
          class="st5"
          d="M112,1097.6c-0.8,3.8-2.9,6.5-7.2,6.3s-6.7-2.7-6.8-6.8c-0.2-3.9,3.4-7.1,7.4-6.7
		C109.6,1090.7,111.5,1093.5,112,1097.6z"
        />
        <path
          class="st5"
          d="M170.3,1127.5c-0.1,3.1-2.6,5.4-6.8,5.5s-6.8-2.2-6.9-6.5c-0.2-3.8,2.8-7,6.6-7.1c0.2,0,0.3,0,0.5,0
		C167.5,1119.4,170.3,1122.3,170.3,1127.5z"
        />
        <path
          class="st5"
          d="M310.7,638.2c-4.3-0.4-7.2-2.3-7.4-6.6c-0.2-4.3,2.2-6.8,6.6-7s6.9,2.2,7.1,6.4
		C317.1,635.3,314.7,637.6,310.7,638.2z"
        />
        <path
          class="st5"
          d="M281.3,1133.1c-4.3-0.3-7.1-2.5-7.3-6.6c-0.2-4.1,2.3-6.9,6.6-7.1s6.9,2.3,7.2,6.5S285.3,1132.4,281.3,1133.1z
		"
        />
        <path
          class="st5"
          d="M310.1,1119.3c4.6,0.4,6.8,2.8,6.9,7c0.1,3.7-2.7,6.7-6.4,6.9c-0.3,0-0.6,0-1,0c-4.2-0.5-6.6-2.9-6.4-7.1
		C303.6,1121.8,306.3,1119.7,310.1,1119.3z"
        />
        <path
          class="st5"
          d="M610.4,515.3c-0.4,3.8-2.6,6.4-6.9,6.4s-6.8-2.6-6.8-6.9c0-4.3,2.8-6.9,6.8-6.9S610,510.6,610.4,515.3z"
        />
        <path
          class="st5"
          d="M163.2,1148.5c4.8,0.5,7.3,2.7,7.1,7.1c-0.2,4.3-2.8,6.7-7,6.5s-6.8-2.7-6.7-7
		C156.7,1150.9,159.7,1148.9,163.2,1148.5z"
        />
        <path
          class="st5"
          d="M199.9,1155.2c-0.4,3.9-2.4,6.7-6.8,6.9c-4.3,0.2-6.8-2.3-7.1-6.5s2.4-6.9,6.6-7.1S199.3,1150.9,199.9,1155.2z
		"
        />
        <path
          class="st5"
          d="M199.8,777.2c-0.4,3.4-2.3,6.2-6.6,6.4c-4.2,0.2-6.8-2.1-7.4-6.2c-0.5-3.8,2.9-7.2,6.8-7.3
		C196.6,770.1,199.6,772.9,199.8,777.2z"
        />
        <path
          class="st5"
          d="M221.9,783.8c-3.7-0.5-6.4-2.6-6.7-6.7c-0.3-3.9,3-7.1,7.2-6.9c4.3,0.2,6.7,2.7,6.6,6.9
		C228.9,781.3,226.6,783.7,221.9,783.8z"
        />
        <path
          class="st5"
          d="M626.1,718.2c0.4-3.9,2.9-6.2,7.2-6.1c4.3,0.1,6.7,2.9,6.5,7.1c-0.2,4.1-2.9,6.7-7.1,6.4
		C628.3,725.2,626.2,722.7,626.1,718.2z"
        />
        <path
          class="st5"
          d="M450,514.6c0.3-4,2.9-6.2,6.8-6.6c3.8-0.4,7.2,3.4,6.8,7.3c-0.4,3.9-2.6,6.2-6.5,6.5
		C453.3,522.1,450,518.8,450,514.6z"
        />
        <path
          class="st5"
          d="M317.1,777.1c-0.6,3.9-2.7,6.7-6.9,6.7s-6.7-2.5-6.9-6.7c-0.3-3.6,2.3-6.6,5.9-7c0.3,0,0.9,0,1.2,0
		C314.8,770.4,316.7,773,317.1,777.1z"
        />
        <path
          class="st5"
          d="M170.4,1214c-0.5,4.1-2.9,6.5-7.2,6.3c-4.2-0.2-6.8-2.7-6.6-7.1c0.2-4.3,2.8-6.7,7.1-6.5
		S170.2,1209.7,170.4,1214z"
        />
        <path
          class="st5"
          d="M134.5,754.7c-4.5-0.5-7.1-2.7-7.2-7c-0.1-3.8,3.7-7.3,7.4-6.8c4.2,0.5,6.7,3.1,6.5,7.2
		C140.9,752.3,138.2,754.4,134.5,754.7z"
        />
        <path
          class="st5"
          d="M111.6,1243.3c-0.5,3.2-2.3,6-6.7,6.2c-3.7,0.2-6.8-2.6-7-6.2c0-0.3,0-0.5,0-0.8c0.2-4.3,2.9-6.4,6.9-6.7
		C108.5,1235.5,111.8,1239,111.6,1243.3z"
        />
        <path
          class="st5"
          d="M339.3,580.2c-4.1-0.7-6.6-2.7-6.5-7.1c0.1-4.4,2.7-6.6,6.9-6.5c4.2,0.1,6.8,2.7,6.7,6.9
		C346.3,577.6,343.4,579.7,339.3,580.2z"
        />
        <path
          class="st5"
          d="M310.4,26.9c-4.3-0.3-7-2.4-7-6.7s2.4-6.8,6.8-6.9c4.4-0.1,6.8,2.4,6.9,6.7C317.2,24.3,314.6,26.4,310.4,26.9z
		"
        />
        <path
          class="st5"
          d="M244.7,1126.6c0.3-4.6,2.8-7.1,6.9-7.1c4.2,0.1,6.8,2.7,6.8,6.9c-0.1,4.2-2.7,6.8-6.9,6.7
		C247.2,1133,244.9,1130.6,244.7,1126.6z"
        />
        <path
          class="st5"
          d="M340,915.6c3.7,0.2,6.4,2.4,6.5,6.6c0.1,4.2-2.3,6.8-6.7,6.9c-4.4,0.1-7-2.3-7-6.4
		C332.7,918.7,334.9,915.8,340,915.6z"
        />
        <path
          class="st5"
          d="M221.7,56.1c-3.9-0.5-6.1-3-6.4-7.1c-0.1-3.6,2.7-6.6,6.2-6.7c0.3,0,0.8,0,1.1,0.1c4.2,0.4,6.6,2.9,6.2,7.1
		C228.8,53.8,226.3,55.9,221.7,56.1z"
        />
        <path
          class="st5"
          d="M434.6,660.9c-0.6,3.8-2.9,6.4-7.1,6.4s-6.6-2.7-6.9-6.8c-0.3-3.8,3.6-7.3,7.3-7
		C432,654.1,434.3,656.5,434.6,660.9z"
        />
        <path
          class="st5"
          d="M310.9,42.5c3.9,0.4,6.1,2.6,6.1,6.9s-2.6,6.5-6.8,6.7c-3.7,0.1-6.8-2.7-6.9-6.4c0-0.3,0-0.5,0-0.8
		C303.7,44.6,306.3,42.5,310.9,42.5z"
        />
        <path
          class="st5"
          d="M369,42.3c4.3,0.4,6.8,2.7,6.8,7c-0.1,4.2-2.4,6.7-6.8,6.9c-3.8,0.2-7.4-3.5-7-7.3
		C362.3,44.8,364.7,42.5,369,42.3z"
        />
        <path
          class="st5"
          d="M426.6,958.4c-3.9-0.7-6.2-3.3-5.9-7.4c0.3-4.1,3.1-6.4,7.3-6.3c3.9,0.1,7,4,6.6,7.8
		C433.9,956.2,430.4,958.9,426.6,958.4z"
        />
        <path
          class="st5"
          d="M317,78.7c-0.5,3.8-2.8,6.4-7.1,6.3c-4.3-0.1-6.7-2.7-6.7-7c0-4.4,2.9-6.8,7-6.7
		C314.3,71.5,316.8,74.2,317,78.7z"
        />
        <path
          class="st5"
          d="M375.8,79.1c-0.3,3.5-2.9,5.9-6.8,6c-4,0.2-6.8-2.5-7-6.6c-0.3-4.3,2.5-6.6,6.5-7.1S375.7,74.2,375.8,79.1z"
        />
        <path
          class="st5"
          d="M420.6,77.9c0.3-3.9,2.9-6.2,6.9-6.5c3.7-0.3,7.4,3.6,7,7.3c-0.3,4.1-3,6.3-7.2,6.5
		C423.7,85.2,420.5,81.8,420.6,77.9z"
        />
        <path
          class="st5"
          d="M281.4,987.5c-4.4-0.3-7.3-2.4-7.4-6.6c-0.1-4.2,2.3-6.9,6.6-7.1s6.9,2.4,7.2,6.5
		C288.1,984.5,285.3,986.9,281.4,987.5z"
        />
        <path
          class="st5"
          d="M309.1,114.2c-3.3-0.4-5.9-2.6-5.7-6.9s2.5-6.7,6.8-6.7s6.7,2.5,6.9,6.6C317.5,111.3,314.1,114.3,309.1,114.2z
		"
        />
        <path
          class="st5"
          d="M346.5,107c-0.4,4.2-2.4,7-6.8,7.1c-4.3,0.1-6.9-1.9-7-6.4c-0.1-4.2,2.2-6.9,6.5-7.1
		C343.6,100.4,345.8,103.1,346.5,107z"
        />
        <path
          class="st5"
          d="M486.2,100.5c4.2,0.6,6.9,2.7,6.8,7.1c-0.1,4.3-2.8,6.6-7,6.5s-6.7-2.5-6.7-6.9
		C479.4,102.8,482.2,100.9,486.2,100.5z"
        />
        <path
          class="st5"
          d="M258.5,1097.3c-0.5,3.7-2.4,6.7-6.8,6.7c-4.2,0-6.8-2.5-6.9-6.7c-0.2-4.4,2.7-6.9,6.7-7.1
		C255.4,1090.1,258,1092.8,258.5,1097.3z"
        />
        <path
          class="st5"
          d="M303.1,136.4c0.4-4.3,2.8-6.7,7.1-6.6s6.8,2.6,6.7,6.9c-0.1,4.3-3,6.8-7,6.7
		C305.9,143.3,303.4,140.7,303.1,136.4z"
        />
        <path
          class="st5"
          d="M332.7,135.8c0.4-3.7,2.8-6.1,7.1-6s6.8,2.7,6.6,7c-0.3,4.3-2.9,6.8-7,6.6S333,140.6,332.7,135.8z"
        />
        <path
          class="st5"
          d="M574.8,521.7c-5.1-0.3-7.3-2.7-7.5-6.9c-0.3-3.7,3.8-7.4,7.4-6.9c4.1,0.5,6.7,3.1,6.5,7.2
		C581.1,518.7,578.3,521.6,574.8,521.7z"
        />
        <path
          class="st5"
          d="M164.3,158.8c3.4,0.5,6,2.4,6,6.8s-2.4,6.6-6.6,6.9c-3.6,0.3-6.8-2.5-7.1-6c0-0.3,0-0.6,0-0.9
		C156.7,161.7,159.7,158.9,164.3,158.8z"
        />
        <path
          class="st5"
          d="M199.8,166.6c-0.5,3.6-2.9,5.9-7.2,5.9c-4.3-0.1-6.5-2.8-6.8-6.9c-0.3-3.8,3.6-7,7.4-6.8
		C197,159,199.9,162.3,199.8,166.6z"
        />
        <path
          class="st5"
          d="M551.7,515.5c-0.5,3.6-2.6,6.4-6.9,6.2c-4-0.2-6.9-2.4-6.8-6.9c0.1-4.5,2.9-6.9,6.9-6.9
		C549,508.1,551.6,510.6,551.7,515.5z"
        />
        <path
          class="st5"
          d="M281.3,172.4c-4.5-0.2-7.3-2.4-7.4-6.5c-0.1-3.9,1.9-7,6.4-7.1c4.6-0.2,7.2,2.5,7.4,6.8
		C287.9,169.9,285.2,172,281.3,172.4z"
        />
        <path
          class="st5"
          d="M310.4,172.4c-4.2-0.4-7-2.3-7-6.6c0-4.1,2.3-6.8,6.5-7s6.7,2.4,6.9,6.5C316.9,169.8,314.3,171.8,310.4,172.4z
		"
        />
        <path
          class="st5"
          d="M456.9,449.8c4.2,0.4,6.8,2.5,6.8,6.8s-2.4,6.7-6.8,6.8c-4.3,0.1-6.9-2.3-6.9-6.6
		C450.1,452.5,452.7,450.4,456.9,449.8z"
        />
        <path
          class="st5"
          d="M222,479c4.4,0.1,7,2.3,7,6.6c0,3.9-2,6.9-6.5,7c-4,0.2-7.5-3.1-7.3-7C215.5,481.6,217.8,479.2,222,479z"
        />
        <path
          class="st5"
          d="M222.4,1148.5c3.6,0.5,6.4,2.1,6.7,6.5c0.2,4.2-2.9,7.3-6.9,7.1s-6.6-2.6-6.8-6.7
		C215.1,1151.7,218.3,1148.6,222.4,1148.5z"
        />
        <path
          class="st5"
          d="M487,172.3c-4.8,0-8.1-3.1-7.6-7.1c0.4-4,2.9-6.5,7.2-6.4c4.2,0.1,6.6,2.8,6.7,6.9
		C493.1,170,490.3,172,487,172.3z"
        />
        <path
          class="st5"
          d="M574.1,172.4c-3.6,0.2-6.6-2.6-6.8-6c0-0.1,0-0.3,0-0.4c-0.2-3.8,2-6.8,6.5-7.1c4-0.2,7.4,2.7,7.5,6.7l0,0
		C581.4,169.1,578.3,172.3,574.1,172.4z"
        />
        <path
          class="st5"
          d="M317,1156.1c-0.3,3.8-2.8,6.2-7,6c-4.2-0.3-6.8-2.7-6.7-7c0.2-4.2,2.8-6.6,7.1-6.5
		C314.9,1148.7,316.8,1151.4,317,1156.1z"
        />
        <path
          class="st5"
          d="M164.3,201.6c-4.5-0.3-7.4-2.2-7.6-6.5c-0.3-4.3,2-6.9,6.4-7.1c4.2-0.2,6.9,1.9,7.2,6.3
		C170.5,198.8,168.1,200.8,164.3,201.6z"
        />
        <path
          class="st5"
          d="M223.6,201.5c-4.8,0.2-8.5-3.1-8.3-7c0.3-4.1,2.5-6.7,6.8-6.7s6.9,2.2,6.9,6.5
		C229,198.5,226.9,201.2,223.6,201.5z"
        />
        <path
          class="st5"
          d="M463.9,543.7c-0.3,4.1-2.3,7-6.7,7.1c-4.3,0.1-6.9-2.1-7.1-6.4c-0.2-4.3,2.3-6.9,6.6-7.1
		S463.2,539.5,463.9,543.7z"
        />
        <path
          class="st5"
          d="M317,195.6c-0.9,3.7-2.9,6.2-7.4,5.8c-4.2-0.5-6.7-3-6.2-7.3c0.3-4.3,3.1-6.4,7.4-6.1
		C315,188.2,317.1,191,317,195.6z"
        />
        <path
          class="st5"
          d="M229.1,1184.5c-0.1,4.2-2.6,6.7-6.7,6.8c-4.1,0.1-6.8-2.3-7.1-6.5c-0.3-3.8,3.2-7.6,7-7.3
		C226.3,1177.8,228.8,1180.2,229.1,1184.5z"
        />
        <path
          class="st5"
          d="M463.7,195.1c-0.6,3.9-2.7,6.5-7.1,6.3s-6.8-2.8-6.7-7c0.1-4.1,2.8-6.6,7-6.5
		C461.3,188.1,463.5,190.7,463.7,195.1z"
        />
        <path
          class="st5"
          d="M514.4,201.5c-3.3-0.5-5.9-2.9-5.8-7s2.6-6.7,6.9-6.7c4.3,0,6.8,2.6,7.1,6.6S519.3,201.7,514.4,201.5z"
        />
        <path
          class="st5"
          d="M610.5,195.2c-0.5,3.6-2.6,6.1-6.8,6.3c-3.7,0.2-6.8-2.7-7-6.4c0-0.3,0-0.5,0-0.7c0.3-4.2,2.9-6.4,7.1-6.3
		C607.9,188.2,610.2,190.6,610.5,195.2z"
        />
        <path
          class="st5"
          d="M229,224.8c-0.2,3.6-2.9,5.9-6.9,5.9c-4.2,0.1-6.7-2.6-6.8-6.8c-0.2-4.3,2.6-6.6,6.6-7
		C225.7,216.6,229,219.9,229,224.8z"
        />
        <path
          class="st5"
          d="M251.7,1191.5c-4.1-0.5-6.8-2.3-6.9-6.7c-0.3-3.6,2.3-6.8,5.9-7.1c0.3,0,0.6,0,1,0c4.2,0.3,6.6,2.7,6.8,6.9
		C258.5,1188.8,255.7,1190.8,251.7,1191.5z"
        />
        <path
          class="st5"
          d="M405.1,223.3c-0.3,4.7-2.7,7.3-6.8,7.4s-6.9-2.1-6.9-6.6c0-4.3,2.5-7,6.6-7.1
		C402.1,216.9,404.4,219.6,405.1,223.3z"
        />
        <path
          class="st5"
          d="M551.7,224.1c-0.5,3.8-2.6,6.7-6.8,6.6c-4-0.1-6.9-2.4-6.9-6.8c0-4.2,2.7-7,6.8-7
		C548.9,217,551.5,219.5,551.7,224.1z"
        />
        <path
          class="st5"
          d="M573.4,230.6c-4.2-0.7-6.5-3.3-6.1-7.4s3.2-6.3,7.4-6.3c3.8,0,7.1,4.2,6.6,7.8
		C580.5,228.4,577.2,230.9,573.4,230.6z"
        />
        <path
          class="st5"
          d="M170.3,252.8c-0.2,4.1-2.3,6.7-6.6,6.9s-6.8-2-7.1-6.3c-0.3-3.7,2.4-7,6.1-7.3c0.3,0,0.5,0,0.8,0
		C167.7,246.2,170.1,248.4,170.3,252.8z"
        />
        <path
          class="st5"
          d="M309.7,259.7c-3.4-0.2-6-2.2-6.2-6.4c-0.3-3.6,2.3-6.8,5.9-7.1c0.3,0,0.6,0,1,0c4.4,0.1,6.5,2.7,6.8,6.8
		C317.5,256.6,314.2,259.6,309.7,259.7z"
        />
        <path
          class="st5"
          d="M339.6,246.1c4.1,0.5,6.8,2.7,6.8,7c0,4.3-2.5,6.6-6.8,6.6s-6.8-2.3-6.7-6.7
		C333,248.5,335.3,246.4,339.6,246.1z"
        />
        <path
          class="st5"
          d="M281.8,1191.2c-4.8-0.1-7.4-2.3-7.9-6.4c-0.3-3.8,3.1-7.4,7-7.2c4.2,0.2,6.8,2.7,6.8,6.7
		C287.9,1188.6,285.2,1190.7,281.8,1191.2z"
        />
        <path
          class="st5"
          d="M486.5,246.1c4.1,0.7,6.8,2.9,6.7,7.1c-0.1,4.2-2.7,6.6-7,6.4s-6.8-2.6-6.6-6.9
		C479.8,248.4,482.3,246.4,486.5,246.1z"
        />
        <path
          class="st5"
          d="M603.9,259.8c-4.2-0.2-7-2.3-7.1-6.5c-0.1-4.2,2.3-6.9,6.6-7.1s7,2.2,7.1,6.4
		C610.6,256.9,608.2,259.3,603.9,259.8z"
        />
        <path
          class="st5"
          d="M639.9,252.9c-0.5,4.2-2.5,6.8-6.9,6.7c-4.4-0.1-6.8-2.3-6.8-6.6c-0.1-4.3,2.5-6.8,6.8-6.8
		S639.2,248.9,639.9,252.9z"
        />
        <path
          class="st5"
          d="M405.1,282.1c-0.3,4.2-2.7,6.7-6.8,6.8s-6.7-2.4-6.9-6.6c-0.3-3.9,3-7.6,6.9-7.2
		C402.2,275.4,404.8,277.8,405.1,282.1z"
        />
        <path
          class="st5"
          d="M111.5,749.5c-0.2,3.1-2.9,5.3-6.9,5.2s-6.7-2.5-6.5-7c0.2-4,2.3-6.4,6.1-6.7
		C108.3,740.8,111.5,744.2,111.5,749.5z"
        />
        <path
          class="st5"
          d="M551.7,281.5c-0.2,4.4-2.3,7.1-6.5,7.3c-4.2,0.3-6.8-2.2-7.2-6.3c-0.4-3.9,2.8-7.7,6.7-7.5
		C548.8,275.3,551.3,277.6,551.7,281.5z"
        />
        <path
          class="st5"
          d="M428,521.8c-5-0.3-7.4-2.9-7.4-6.9s2.8-6.6,6.8-7c3.6-0.4,7.5,3.7,7.1,7.2C434.5,518.8,431.6,521.6,428,521.8z
		"
        />
        <path
          class="st5"
          d="M838,289.1c-4-0.6-6.6-2.7-6.7-7c-0.1-3.9,3.4-7.6,7.2-7.1c4,0.5,6.6,2.9,6.5,7.2
		C845,286.6,842.2,288.6,838,289.1z"
        />
        <path
          class="st5"
          d="M310.9,566.4c4,0.5,6.2,2.8,6.1,7.1c-0.2,4.3-2.9,6.6-7.1,6.4c-4.2-0.2-6.7-2.9-6.5-7.1
		S306.4,566.5,310.9,566.4z"
        />
        <path
          class="st5"
          d="M405.2,572.9c-0.3,4.1-2.3,6.8-6.7,7.1c-3.6,0.4-6.8-2.3-7.1-5.9c0-0.4,0-0.7,0-1c0.1-4.4,2.8-6.6,6.8-6.8
		C402.4,566.1,404.5,568.9,405.2,572.9z"
        />
        <path
          class="st5"
          d="M311,275.4c3.7,0.5,6.1,2.7,6,7.1c-0.2,4.4-2.9,6.6-7.1,6.4s-6.7-2.9-6.5-7.1
		C303.5,277.4,306.3,275.5,311,275.4z"
        />
        <path
          class="st5"
          d="M479.4,281.2c0.3-3.6,2.9-6,7-5.9c4.2,0.2,6.8,2.7,6.7,7c-0.2,4.3-2.8,6.8-7,6.6
		C481.8,288.7,479.6,286.1,479.4,281.2z"
        />
        <path
          class="st5"
          d="M199.8,1243.1c-0.2,3.5-2.4,6-6.6,6.4c-3.7,0.3-7-2.5-7.3-6.1c0-0.3,0-0.5,0-0.7c0.2-4.2,2.7-6.6,6.8-6.8
		C196.7,1235.7,199.6,1238.6,199.8,1243.1z"
        />
        <path
          class="st5"
          d="M317,310.9c-0.3,4.4-2.5,6.9-6.8,7s-6.9-2.4-6.8-6.6s2.4-6.8,6.8-6.9S316.7,306.8,317,310.9z"
        />
        <path
          class="st5"
          d="M602.8,550.9c-4.1-0.7-6.5-3-6-7.3s3.2-6.5,7.4-6.2s6.6,3,6.1,7.3C610,548.9,607.2,550.9,602.8,550.9z"
        />
        <path
          class="st5"
          d="M369,318c-4.3-0.3-6.8-2.6-7.1-6.7c-0.1-3.8,2.9-7.1,6.9-7.1c0.1,0,0.2,0,0.3,0c4.2,0.3,6.6,2.8,6.7,7
		C375.8,315.3,373.1,317.7,369,318z"
        />
        <path
          class="st5"
          d="M463.7,310.2c-0.1,5-2.3,7.5-6.7,7.7c-4.3,0.2-7-2.2-7-6.5s2.3-6.8,6.7-7C461.1,304.2,463.1,306.8,463.7,310.2
		z"
        />
        <path
          class="st5"
          d="M258.5,1243.1c-1,3.4-2.5,6.7-7,6.4c-4.2-0.2-6.8-2.6-6.7-6.9s2.9-6.7,6.9-6.8
		C255.9,1235.8,258,1238.6,258.5,1243.1z"
        />
        <path
          class="st5"
          d="M141.4,1271.8c-0.8,4.3-2.9,6.9-7.3,6.7c-4.2-0.3-6.9-2.7-6.8-7c0.2-4.2,2.8-6.6,7.1-6.5
		C138.8,1265.1,140.7,1268,141.4,1271.8z"
        />
        <path
          class="st5"
          d="M757.1,311.4c-0.3,4.2-2.7,6.5-7,6.5s-6.8-2.6-6.8-6.8c0.1-4.2,2.8-6.8,7-6.7C754.6,304.5,757,307,757.1,311.4
		z"
        />
        <path
          class="st5"
          d="M779.7,318c-4.1-0.4-6.8-2.3-6.9-6.6c-0.3-3.6,2.4-6.8,6.1-7.1c0.3,0,0.6,0,1,0c4.2,0.3,6.8,2.7,6.8,6.9
		C786.6,315.5,783.9,317.6,779.7,318z"
        />
        <path
          class="st5"
          d="M221.8,1278.6c-3.9-0.5-6.5-2.6-6.4-6.9s2.6-6.8,6.8-6.7c4.2,0.1,6.8,2.6,6.8,6.9
		C229,1276.2,226.4,1278.3,221.8,1278.6z"
        />
        <path
          class="st5"
          d="M229,894.4c-0.3,3.7-2.9,6-7,5.8c-4.2-0.2-6.7-2.7-6.7-7c0.1-3.9,3.4-7,7.3-6.9c0.1,0,0.1,0,0.2,0
		C226.3,886.6,229.2,889.9,229,894.4z"
        />
        <path
          class="st5"
          d="M105.7,1294.1c3.6,0.5,5.8,2.6,5.8,6.7c0.2,3.7-2.7,6.7-6.4,6.9c-0.3,0-0.6,0-1,0c-4.3-0.5-6.5-3-6.2-7.2
		C98.3,1296.1,101.2,1294.2,105.7,1294.1z"
        />
        <path
          class="st5"
          d="M310,333.5c4.2,0.3,6.9,2.2,6.9,6.6c0,4.2-2.3,6.9-6.6,7c-4.2,0.1-6.9-2.3-7-6.6
		C303.3,336.2,305.7,333.9,310,333.5z"
        />
        <path
          class="st5"
          d="M317,515.2c-0.4,3.7-2.5,6.5-6.8,6.4c-4.2-0.1-6.9-2.4-6.9-6.7s2.8-6.9,6.8-7
		C314.1,507.8,316.8,510.5,317,515.2z"
        />
        <path
          class="st5"
          d="M111.8,1039.2c-0.3,4.2-3,6.7-7,6.6s-6.7-2.5-6.8-6.8c-0.2-3.7,2.6-6.7,6.1-7c0.3,0,0.6,0,1,0
		C109.4,1032.5,111.4,1035.1,111.8,1039.2z"
        />
        <path
          class="st5"
          d="M516.2,333.5c3.9,0.4,6.6,2.7,6.4,6.8c-0.1,3.8-2.3,6.8-6.8,6.8c-3.9,0-7.4-3.4-7.2-7.1
		C509,335.8,511.4,333.6,516.2,333.5z"
        />
        <path
          class="st5"
          d="M229.1,1300.7c-0.4,4.3-2.4,7.1-6.8,7.1s-6.8-2.6-6.9-6.7c-0.1-4.1,2.4-6.7,6.8-6.8
		C226.4,1294.1,228.5,1296.7,229.1,1300.7z"
        />
        <path
          class="st5"
          d="M603.5,333.4c4.2,0.3,6.9,2.4,6.9,6.7s-2.4,6.9-6.7,6.9c-4.2,0-6.8-2.4-7-6.7
		C596.5,336,599.4,334.1,603.5,333.4z"
        />
        <path
          class="st5"
          d="M720.8,333.4c4.1,0.4,6.8,2.4,7.1,6.5c0.3,3.8-3.2,7.3-7.1,7.1c-4.2-0.2-6.8-2.7-6.8-6.8
		C714,336.2,716.5,333.8,720.8,333.4z"
        />
        <path
          class="st5"
          d="M281.2,521.8c-4.2-0.3-6.8-2.2-7.2-6.4c-0.3-3.8,3.3-7.7,6.9-7.4c4.1,0.4,6.7,2.7,6.9,6.9
		C288.2,518.2,284.7,521.6,281.2,521.8z"
        />
        <path
          class="st5"
          d="M428,376.2c-4.8-0.4-7.2-2.7-7.4-6.9c-0.1-3.8,3.8-7.4,7.4-6.9c4.1,0.5,6.8,3.1,6.5,7.2
		C434.1,373.8,431.5,375.9,428,376.2z"
        />
        <path
          class="st5"
          d="M310,1294.1c4.6,0.3,6.8,2.6,6.8,7s-2.4,6.7-6.8,6.6c-4.2-0.2-6.8-2.6-6.8-6.9S306.1,1294.4,310,1294.1z"
        />
        <path
          class="st5"
          d="M574.7,376.2c-4.6-0.2-7.1-2.5-7.4-6.6c-0.3-3.6,3.6-7.6,7.1-7.2c4.1,0.4,6.8,2.8,6.8,7
		C581.1,373.5,578.4,375.7,574.7,376.2z"
        />
        <path
          class="st5"
          d="M721.4,376.2c-5.1-0.2-7.4-2.7-7.5-6.8c-0.1-4,2.8-6.6,6.8-7.1c3.6-0.5,7.5,3.6,7.2,7.2
		C727.5,373.7,725,375.9,721.4,376.2z"
        />
        <path
          class="st5"
          d="M133.5,1336.9c-4.2-0.9-6.7-3.6-6.1-7.7s3.4-6.4,7.6-5.9s6.5,3.6,6,7.6
		C140.6,1335.1,137.5,1336.9,133.5,1336.9z"
        />
        <path
          class="st5"
          d="M163.4,391.6c4.2,0.4,6.8,2.4,6.8,6.8s-2.3,6.8-6.7,6.9s-6.9-2.2-6.9-6.5C156.6,394.4,159.1,392.1,163.4,391.6
		z"
        />
        <path
          class="st5"
          d="M252.1,405.4c-4.6-0.5-7.3-2.4-7.4-6.8c-0.1-4.4,2.4-6.8,6.7-7c4.4-0.2,6.8,2.6,6.9,6.7
		C258.5,402.7,255.8,404.8,252.1,405.4z"
        />
        <path
          class="st5"
          d="M339.4,391.5c4.5,0.6,7.1,3,7,7.2s-2.7,6.5-7,6.4s-6.7-2.5-6.6-6.8C333,394,335.8,392.2,339.4,391.5z"
        />
        <path
          class="st5"
          d="M487.3,391.8c3.6,0.8,5.9,3.1,5.7,7.3c-0.2,4-3.8,6.8-8.1,6c-4-0.6-5.9-3.2-5.5-7.2
		C479.9,393.9,482.9,391.4,487.3,391.8z"
        />
        <path
          class="st5"
          d="M574.1,405.2c-4.5-0.3-6.8-2.9-6.8-7c0.1-4,2.8-6.8,7.1-6.5c4.2,0.2,6.8,2.7,6.7,7
		C580.9,402.9,578.3,405.1,574.1,405.2z"
        />
        <path
          class="st5"
          d="M639.9,397.8c-0.4,4.8-2.5,7.3-6.9,7.4s-6.8-2.2-6.9-6.5c-0.1-4.3,2.3-6.8,6.6-6.9
		C636.8,391.8,639.1,394.5,639.9,397.8z"
        />
        <path
          class="st5"
          d="M316.9,427.2c-0.1,4.8-2.6,7.1-6.8,7.2c-4.2,0.1-6.8-2.6-6.8-6.8s2.5-6.7,6.8-6.7
		C314.3,421,316.7,423.4,316.9,427.2z"
        />
        <path
          class="st5"
          d="M258.5,1330.2c-0.3,4.3-2.9,6.5-7,6.8s-7-3.4-6.7-7.4c0.3-4.3,3-6.5,7.1-6.3
		C255.9,1323.5,258.3,1326.1,258.5,1330.2z"
        />
        <path
          class="st5"
          d="M486.2,434.6c-4.1-0.6-6.8-2.5-6.8-6.9s2.5-6.8,6.8-6.9c4.2-0.1,6.8,2.4,6.8,6.7S490.3,434,486.2,434.6z"
        />
        <path
          class="st5"
          d="M317,1330.3c-0.3,4.2-2.9,6.8-7,6.6s-6.8-2.7-6.7-7c0.2-4.2,2.6-6.7,7-6.5
		C314.8,1323.6,316.9,1325.9,317,1330.3z"
        />
        <path
          class="st5"
          d="M633,420.8c4.2,0.5,6.8,2.7,6.8,7s-2.8,6.7-6.9,6.7c-4.2,0-6.7-2.7-6.7-7C626.2,423.2,628.8,421.2,633,420.8z"
        />
        <path
          class="st5"
          d="M170.4,456.6c-0.4,4.6-2.8,7-7.1,6.8s-6.8-2.7-6.6-6.9s2.9-6.6,7.1-6.5C168.1,450.1,170.3,452.6,170.4,456.6z"
        />
        <path
          class="st5"
          d="M199.8,456.6c-0.4,4.2-2.6,6.8-6.9,6.9c-4.2,0.1-7.4-3-7.1-7.1c0.3-4,2.9-6.5,7.1-6.5
		C197.1,449.9,199.2,452.8,199.8,456.6z"
        />
        <path
          class="st5"
          d="M310.4,463.5c-4.5-0.4-7-2.6-6.9-6.9s2.6-6.7,6.9-6.7s6.8,2.5,6.8,6.8S314.2,463.1,310.4,463.5z"
        />
        <path
          class="st5"
          d="M340.7,449.9c3.2,0.5,5.6,2.8,5.6,7c0,4.3-2.7,6.5-6.8,6.6c-3.7,0.2-6.8-2.7-7-6.3c0-0.3,0-0.5,0-0.8
		C332.6,452.8,336.2,449.7,340.7,449.9z"
        />
        <path
          class="st5"
          d="M134,1366c-4.2-0.3-6.8-2.8-6.8-6.8c0.1-3.9,2.4-6.7,6.8-6.9c4-0.2,7.5,3.2,7.2,7.1
		C140.7,1363.4,138.2,1365.7,134,1366z"
        />
        <path
          class="st5"
          d="M222.3,754.7c-4.2-0.5-6.9-2.6-7.1-6.8c-0.1-3.8,2.9-7,6.7-7.1c0.2,0,0.3,0,0.6,0c4.2,0.4,6.6,2.9,6.5,7.1
		S226.4,754.4,222.3,754.7z"
        />
        <path
          class="st5"
          d="M134.8,1395.2c-4.7-0.2-7.4-2.7-7.4-6.7c-0.1-4,2.4-6.9,6.8-7s7,2.3,7.1,6.6
		C141.2,1392.4,138.3,1394.4,134.8,1395.2z"
        />
        <path
          class="st5"
          d="M281,550.8c-4.5-0.3-7-2.7-6.9-6.9s2.7-6.7,7-6.6c4.3,0.1,6.8,2.7,6.8,6.9C287.8,548.3,285.1,550.6,281,550.8z
		"
        />
        <path
          class="st5"
          d="M258.5,1388.1c-0.5,3.9-2.4,7.1-6.8,7.1c-4.2,0-6.8-2.3-7-6.7s2.6-6.8,6.7-7
		C255.7,1381.2,257.8,1384,258.5,1388.1z"
        />
        <path
          class="st5"
          d="M375.8,1038.9c-0.2,4.2-2.6,6.7-6.7,6.9c-4.1,0.2-6.8-2.2-7.2-6.4c-0.3-3.8,3.2-7.6,6.9-7.4
		C373,1032.2,375.4,1034.7,375.8,1038.9z"
        />
        <path
          class="st5"
          d="M74.8,1410.4c4.5,0.4,7.3,2.4,7.4,6.7c0.1,4.3-2.3,6.9-6.6,7s-6.9-2.3-7.1-6.5
		C68.4,1413.4,71.2,1411.1,74.8,1410.4z"
        />
        <path
          class="st5"
          d="M581.3,661.1c-0.6,3.5-2.9,6.1-7,6.2c-4.1,0.1-6.9-2.7-7-6.7s2.6-6.6,6.7-7.1
		C577.6,653.2,581.3,656.9,581.3,661.1z"
        />
        <path
          class="st5"
          d="M111.9,1417.3c-0.4,4-2.6,6.9-6.8,6.9s-6.9-2.4-6.9-6.7s2.3-6.9,6.7-6.9S111.3,1413,111.9,1417.3z"
        />
        <path
          class="st5"
          d="M398.2,1031.9c4.1,0.6,6.8,2.5,6.9,6.9s-2.6,6.6-6.6,7.1c-3.8,0.5-7.2-3.1-7.1-7.1
		C391.4,1034.4,394.3,1032.7,398.2,1031.9z"
        />
        <path
          class="st5"
          d="M222.3,1410.4c4.4,0.5,6.8,3,6.7,7.2s-2.9,6.7-7.1,6.4s-6.8-2.7-6.5-7.1
		C215.7,1412.6,218.3,1410.7,222.3,1410.4z"
        />
        <path
          class="st5"
          d="M463.9,689.1c-0.3,4.9-2.9,7.3-7,7.3c-4.2,0-6.8-2.5-6.8-6.8c0.1-4.3,2.6-6.7,6.9-6.7S463.2,685.7,463.9,689.1
		z"
        />
        <path
          class="st5"
          d="M111.8,1446c-0.4,4.2-2.2,7.1-6.7,7.2c-4.3,0.1-6.9-2.2-7.1-6.5s2.2-6.7,6.5-7
		C109.1,1439.5,111,1442.1,111.8,1446z"
        />
        <path
          class="st5"
          d="M362,1454.6c-0.5,4-2.9,6.6-7,6.5s-6.7-2.6-6.9-6.7c-0.3-3.8,3.4-7.2,7.3-6.9
		C359.5,1447.9,361.5,1450.5,362,1454.6z"
        />
        <path
          class="st5"
          d="M332.5,1454.4c-0.3,4-2.6,6.9-6.8,6.9c-4.2,0-6.9-2.4-6.9-6.7c0-4.3,2.3-6.9,6.7-6.9
		C329.9,1447.7,332.1,1450,332.5,1454.4z"
        />
        <path
          class="st5"
          d="M332.5,1483.1c-0.4,4.2-2.3,7.1-6.7,7.2c-4.3,0.1-6.9-2.2-7.1-6.4c-0.2-4.2,2.3-6.7,6.5-7
		C329.8,1476.6,331.7,1479.2,332.5,1483.1z"
        />
        <path
          class="st5"
          d="M310.4,696.5c-4.2-0.5-7-2.3-7-6.7c0-4.2,2.3-6.9,6.7-7c4.4-0.1,6.8,2.4,7,6.6
		C317.2,693.6,314.4,695.8,310.4,696.5z"
        />
        <path
          class="st5"
          d="M170.3,1446.4c-0.3,4.2-2.5,6.8-6.8,6.8s-6.8-2.5-6.9-6.7c-0.1-4.2,2.3-6.7,6.8-6.7
		C167.8,1439.8,170,1442.1,170.3,1446.4z"
        />
        <path
          class="st5"
          d="M522.5,602.5c-0.3,3.8-2.5,6.5-6.8,6.6c-4.1,0-6.8-2.2-7.2-6.4c-0.2-3.8,2.8-7,6.6-7.1c0.2,0,0.3,0,0.5,0
		C520,595.6,522,598.2,522.5,602.5z"
        />
        <path
          class="st5"
          d="M111.8,806.4c-0.4,3.7-2.6,6.5-6.8,6.5s-6.8-2.6-6.9-6.8s2.8-6.9,6.8-7C108.7,799,111.5,801.5,111.8,806.4z"
        />
        <path
          class="st5"
          d="M493.2,690c-0.6,4.2-2.9,6.6-7.3,6.4c-4.3-0.2-6.7-2.7-6.5-7c0.2-4.2,2.8-6.6,7-6.4
		C490.8,683.2,492.8,685.8,493.2,690z"
        />
        <path
          class="st5"
          d="M229.1,807c-0.8,3.4-2.8,6.2-7.1,6c-4.2-0.3-6.7-2.7-6.7-7c0-4.2,2.7-6.5,6.8-6.9
		C225.9,798.8,229.2,802.5,229.1,807z"
        />
        <path
          class="st5"
          d="M375.8,747.9c-0.3,3.8-2.4,6.6-6.7,6.7c-4.2,0.1-6.8-2.2-7.2-6.4c-0.2-3.8,2.7-7.1,6.6-7.3c0.2,0,0.3,0,0.4,0
		C373.1,741.1,375.3,743.6,375.8,747.9z"
        />
        <path
          class="st5"
          d="M405.1,805.9c-0.4,4.1-2.5,7-6.8,7s-6.8-2.4-6.9-6.7s2.7-6.9,6.7-7.1C402.1,799,404.7,801.7,405.1,805.9z"
        />
        <path
          class="st5"
          d="M610.5,486.1c-0.3,3.8-2.6,6.5-6.9,6.5s-6.9-2.5-6.9-6.8s2.6-6.7,6.8-6.7C607.7,479.1,610.1,481.5,610.5,486.1
		z"
        />
        <path
          class="st5"
          d="M229,1476.3c-0.3,3.8-2.9,6.2-7,6.1s-6.7-2.7-6.8-6.9c-0.1-3.8,3.6-7.4,7.4-6.9
		C226.6,1469.3,229,1471.5,229,1476.3z"
        />
        <path
          class="st5"
          d="M551.7,806.2c-0.4,3.8-2.5,6.7-6.8,6.7c-4.2,0-6.9-2.4-7-6.7s2.7-6.9,6.8-7.1
		C548.7,799,551.4,801.6,551.7,806.2z"
        />
        <path
          class="st5"
          d="M229.1,1330.2c-0.3,4.1-2.8,6.6-6.8,6.6s-7-2.5-6.8-6.8c0.1-4.1,2.6-7,6.8-6.9
		C226.5,1323.3,228.8,1325.7,229.1,1330.2z"
        />
        <path
          class="st5"
          d="M193.8,1468.9c3.3,0.1,5.7,2.4,5.9,6.6s-2.5,6.6-6.6,7.1c-3.7,0.4-7.6-3.4-7.3-7.1
		C186.1,1471.5,188.5,1469,193.8,1468.9z"
        />
        <path
          class="st5"
          d="M258.5,603.1c-0.3,3.5-2.9,6-6.8,6c-3.9,0.1-6.8-2.2-6.8-6.7c-0.1-4.2,2.9-7.2,7-7.1
		C255.4,595.5,258.5,598.7,258.5,603.1z"
        />
        <path
          class="st5"
          d="M199.9,835.1c-0.5,4.1-2.6,6.8-6.9,6.8s-6.8-2.4-6.8-6.7s2.4-6.8,6.8-6.8C197.2,828.4,199.2,831.2,199.9,835.1
		z"
        />
        <path
          class="st5"
          d="M229,834.9c-0.2,4.3-2.3,7-6.7,7.1s-6.9-2.2-7-6.6c-0.1-4.4,2.4-6.8,6.7-7C226.2,828.2,228.7,830.6,229,834.9z
		"
        />
        <path
          class="st5"
          d="M229.1,602.3c-0.3,4.1-2.6,6.7-6.7,6.9c-4.1,0.2-6.8-2.3-7.2-6.4c-0.3-3.8,2.7-7.1,6.5-7.3c0.2,0,0.3,0,0.5,0
		C226.5,595.5,228.8,598,229.1,602.3z"
        />
        <path
          class="st5"
          d="M317.1,835c-0.5,4.4-2.9,7.1-7.1,6.9c-4.2-0.2-6.8-2.6-6.7-6.9c0.2-4.3,2.7-6.7,6.9-6.7
		C314.9,828.4,316.7,831.2,317.1,835z"
        />
        <path
          class="st5"
          d="M346.5,835c-0.5,4.1-2.4,6.9-6.9,6.9c-4.3,0-6.8-2.2-6.8-6.6c0-4.4,2.4-6.8,6.7-6.9
		C343.7,828.4,345.9,831,346.5,835z"
        />
        <path
          class="st5"
          d="M581.3,689.1c-0.3,4.7-2.7,7.1-6.9,7.2c-4.2,0.1-7-2.6-6.9-6.7c0.1-4.1,2.4-6.9,6.8-6.9
		C578.4,682.9,580.8,685.4,581.3,689.1z"
        />
        <path
          class="st5"
          d="M486.1,842c-4.2-0.5-6.8-2.5-6.7-6.9c0.1-4.4,2.6-6.7,6.8-6.7c4.2,0,6.8,2.7,6.8,6.9
		C493,839.4,490.4,841.8,486.1,842z"
        />
        <path
          class="st5"
          d="M522.5,835.1c-0.3,3.9-2.3,6.6-6.6,6.8c-4.2,0.2-6.8-1.9-7.3-6.2c-0.3-3.8,2.5-7,6.1-7.3c0.2,0,0.4,0,0.6,0
		C519.7,828.4,522,830.8,522.5,835.1z"
        />
        <path
          class="st5"
          d="M573.2,479c4.8-0.2,8.2,2.9,8,6.9c-0.3,4.2-2.9,6.5-7,6.7c-4.3,0.2-6.8-2.7-7-6.6c-0.3-3.5,2.3-6.6,5.8-7
		C573.1,479,573.1,479,573.2,479z"
        />
        <path
          class="st5"
          d="M610.5,834.8c-0.3,4.6-2.7,7.1-6.9,7.1s-6.9-2.3-6.8-6.7c0.1-4.2,2.6-6.7,6.8-6.9
		C607.8,828.2,610,831,610.5,834.8z"
        />
        <path
          class="st5"
          d="M170.3,1533.9c-0.3,4-2.5,6.7-6.8,6.7s-6.8-2.7-6.8-6.8c0-4.1,2.6-6.9,6.8-6.8
		C167.7,1527.1,170.1,1529.3,170.3,1533.9z"
        />
        <path
          class="st5"
          d="M434.6,748.5c-0.5,3.5-2.7,6.1-6.8,6.1s-6.9-2.5-7-6.6s2.3-6.7,6.5-7.1C430.9,740.6,434.5,744,434.6,748.5z"
        />
        <path
          class="st5"
          d="M186,1533.6c0.3-3.8,2.4-6.3,6.7-6.5c3.6-0.4,6.8,2.2,7.1,5.7c0,0.4,0.1,0.8,0,1.2c-0.2,4.2-2.9,6.4-6.9,6.7
		C189.3,1541,186,1537.7,186,1533.6z"
        />
        <path
          class="st5"
          d="M317,1068.6c-0.4,3.9-2.9,6.4-7.1,6.2c-4.2-0.2-6.8-2.7-6.6-7c0.2-4.3,2.8-6.6,7-6.6
		C314.7,1061.3,316.8,1064.1,317,1068.6z"
        />
        <path
          class="st5"
          d="M134.3,857.4c3.9,0.5,6.7,2.6,6.8,6.8c0.2,3.7-2.8,6.8-6.5,7c-0.3,0-0.4,0-0.8,0c-4.2-0.3-6.7-3-6.7-7
		S129.9,857.8,134.3,857.4z"
        />
        <path
          class="st5"
          d="M199.8,864.2c-0.5,4-2.5,6.9-6.8,6.9s-6.8-2.5-6.9-6.7c-0.2-4.3,2.4-6.7,6.7-6.8
		C197,857.5,199.3,859.9,199.8,864.2z"
        />
        <path
          class="st5"
          d="M258.4,1622c-0.2,3.5-2.7,5.7-6.8,6s-6.9-3-6.8-7.2c0.1-4.6,3-6.2,6.9-6.6
		C255.2,1613.9,258.5,1617.6,258.4,1622z"
        />
        <path
          class="st5"
          d="M457.4,566.4c3.6,0.5,6.2,2.4,6.1,6.8c-0.1,4.4-2.7,6.7-6.8,6.7c-4.2,0-6.8-2.6-6.8-6.8
		C450,568.9,452.7,566.6,457.4,566.4z"
        />
        <path
          class="st5"
          d="M463.6,864.5c-0.3,4-2.5,6.6-6.8,6.5c-4.2-0.1-6.8-2.6-6.8-6.9c0.1-4.3,2.6-6.7,6.9-6.6
		S463.4,860.1,463.6,864.5z"
        />
        <path
          class="st5"
          d="M369.3,958.6c-4.5-0.2-7.8-3.6-7.4-7.5c0.4-4.2,3.2-6.5,7.3-6.3c4.2,0.2,6.7,3.1,6.6,7.1
		c0.1,3.6-2.7,6.5-6.1,6.7C369.5,958.5,369.5,958.5,369.3,958.6z"
        />
        <path
          class="st5"
          d="M199.9,719.3c-1,4.1-3.3,6.7-7.6,6.2s-6.5-3.1-6.1-7.4c0.3-4.4,3.2-6.3,7.4-6
		C198,712.3,199.6,715.2,199.9,719.3z"
        />
        <path
          class="st5"
          d="M346.5,1650.8c-0.3,3.5-2.4,6-6.8,6.1s-6.8-2.3-7.3-6.3c-0.4-3.8,3-7.1,7-7.2
		C343.3,1643.4,346.4,1646.4,346.5,1650.8z"
        />
        <path
          class="st5"
          d="M310.4,492.7c-4.4-0.5-7-2.7-7-7s2.5-6.7,6.8-6.7s6.8,2.4,6.8,6.8C316.8,490.2,314.2,492.2,310.4,492.7z"
        />
        <path
          class="st5"
          d="M573.8,347.1c-3.7-0.3-6.4-2.7-6.5-6.7s2.3-6.7,6.7-7c3.8-0.3,7.1,2.6,7.4,6.3c0,0.2,0,0.4,0,0.5
		C581.3,344,578,347.1,573.8,347.1z"
        />
        <path
          class="st5"
          d="M193.3,987.4c-4.4-0.2-7.2-2.1-7.3-6.5s2.3-6.9,6.6-7.1s6.8,2.5,7.1,6.5C200,984.4,197.5,987.1,193.3,987.4z"
        />
        <path
          class="st5"
          d="M163.3,550.9c-4.2-0.5-6.8-2.6-6.7-7c0.2-4.2,2.9-6.7,7-6.6c4.3,0,6.7,2.7,6.6,7
		C170.3,548.6,167.5,550.6,163.3,550.9z"
        />
        <path
          class="st5"
          d="M229.1,1126.1c-0.3,4.4-2.5,6.9-6.8,6.9s-6.8-2.4-6.8-6.6s2.6-6.8,6.8-6.8
		C226.3,1119.6,228.8,1122,229.1,1126.1z"
        />
        <path
          class="st5"
          d="M375.8,20.4c-0.4,4.3-3,6.6-7.2,6.5s-6.7-2.7-6.7-6.9c0-3.8,3.2-6.9,7-6.9c0.1,0,0.2,0,0.3,0
		C373.8,13.5,375.4,16.5,375.8,20.4z"
        />
        <path
          class="st5"
          d="M427.9,26.9c-4.6-0.2-7.2-2.7-7.3-6.7s2.3-6.8,6.6-7c3.9-0.2,7.1,2.7,7.4,6.5c0,0.2,0,0.3,0,0.5
		C434.4,24.4,431.7,26.4,427.9,26.9z"
        />
        <path
          class="st5"
          d="M426.6,783.7c-3.6-0.2-6.2-3.3-6.1-6.8l0,0c0.1-3.9,2.5-6.6,6.8-6.7c3.8-0.2,7,2.7,7.1,6.6c0,0.2,0,0.4,0,0.5
		C434.3,780.9,431,783.8,426.6,783.7z"
        />
        <path
          class="st5"
          d="M426.5,56c-3.6-0.3-6.1-3.3-5.9-6.9c0-4,2.5-6.7,6.8-6.7s6.7,2.5,7.1,6.5C434.9,52.7,431.2,56,426.5,56z"
        />
        <path
          class="st5"
          d="M457.2,85.2c-4.3-0.4-6.8-2.5-7.2-6.7c-0.3-4.2,2.6-6.5,8-6.5c4.2,0,5.7,2.9,5.8,6.2
		C463.7,82.5,461.2,84.8,457.2,85.2z"
        />
        <path
          class="st5"
          d="M192.5,129.7c4.2,0.3,7.1,2.3,7.2,6.6c0.1,4.3-2.5,6.9-6.7,7.1s-6.8-2.2-7-6.5S188.5,130.3,192.5,129.7z"
        />
        <path
          class="st5"
          d="M457.1,129.7c3.7,0.4,6.4,2.3,6.6,6.7s-2.5,6.9-6.6,7s-6.8-2.5-7-6.7C449.9,132.4,452.3,130,457.1,129.7z"
        />
        <path
          class="st5"
          d="M105,1323.1c4.2,0.6,6.7,2.9,6.7,7.1c0,4.4-2.9,6.4-6.8,6.8s-7-3.3-6.8-7.3
		C98.3,1325.3,101.1,1323.5,105,1323.1z"
        />
        <path
          class="st5"
          d="M375.8,165.7c-0.3,4.2-2.7,6.7-6.8,6.7s-6.8-2.5-7-6.6c-0.1-3.8,3-7.1,6.9-7.1c0.1,0,0.2,0,0.3,0
		C373.3,159.1,375.5,161.6,375.8,165.7z"
        />
        <path
          class="st5"
          d="M428.1,172.4c-4.6-0.1-7.3-2.5-7.4-6.5c-0.2-4,2.2-6.8,6.4-7.1c3.9-0.3,7.2,2.6,7.4,6.4c0,0.1,0,0.3,0,0.4
		C434.5,169.8,431.9,171.9,428.1,172.4z"
        />
        <path
          class="st5"
          d="M456.8,172.4c-4.3-0.5-6.9-2.7-6.8-7c0.2-4.3,2.7-6.7,7-6.6s6.7,2.6,6.7,6.9
		C463.7,170,460.9,172.2,456.8,172.4z"
        />
        <path
          class="st5"
          d="M610.5,165.9c-0.4,4.1-2.8,6.6-7.2,6.4c-4.4-0.2-6.8-2.7-6.6-7s2.9-6.7,7.1-6.5
		C608,159.1,610.2,161.6,610.5,165.9z"
        />
        <path
          class="st5"
          d="M199.9,1271.5c-0.4,4.2-2.4,6.9-6.8,7s-6.9-2.4-7-6.5s2.3-6.9,6.7-7C197,1264.9,199.1,1267.6,199.9,1271.5z"
        />
        <path
          class="st5"
          d="M258.5,514.8c-0.3,4.1-2.4,7-6.7,7c-4,0-6.9-2.1-7-6.6s2.7-6.9,6.6-7.1C255.2,507.7,257.8,510.5,258.5,514.8z"
        />
        <path
          class="st5"
          d="M258.5,223.7c-0.3,4.1-2.5,6.9-6.7,7s-6.9-2.2-7-6.6s2.6-7,6.7-7.1C255.5,216.9,257.9,219.3,258.5,223.7z"
        />
        <path
          class="st5"
          d="M456.6,259.7c-3.8-0.3-6.4-2.3-6.6-6.6c-0.2-3.7,2.7-6.9,6.4-7.1c0.3,0,0.5,0,0.8,0c4.3,0.2,6.8,2.8,6.7,6.9
		C463.7,257,461.4,259.6,456.6,259.7z"
        />
        <path
          class="st5"
          d="M456.9,289c-4.1-0.5-6.8-2.7-6.8-6.9c-0.1-4.2,2.6-6.8,6.8-6.9c4.2-0.1,6.9,2.3,6.8,6.6
		C463.6,286.1,461.2,288.6,456.9,289z"
        />
        <path
          class="st5"
          d="M596.6,281.9c0.3-4.1,2.9-7.1,7.2-6.3c2.6,0.5,5.5,3.6,6.5,6.1c1.2,3.7-2.8,7.2-6.7,7.2
		C599.7,288.8,596.6,285.7,596.6,281.9z"
        />
        <path
          class="st5"
          d="M493.2,457.1c-0.5,4-2.8,6.4-7.1,6.4s-6.6-2.6-6.8-6.7c-0.1-3.8,2.9-6.8,6.7-6.9c0.2,0,0.4,0,0.6,0
		C490.9,450.2,492.8,452.9,493.2,457.1z"
        />
        <path
          class="st5"
          d="M369.1,725.7c-4.2-0.6-7-2.5-7.1-6.9s2.5-6.8,6.7-6.9c4.2-0.1,6.8,2.2,6.9,6.6
		C375.7,722.9,373.1,725,369.1,725.7z"
        />
        <path
          class="st5"
          d="M340,987.5c-4.4-0.3-7.1-2.2-7.2-6.6c-0.1-4.4,2.3-6.8,6.6-7c4.2-0.2,6.8,2.5,7,6.6
		C346.5,984.7,343.9,987.1,340,987.5z"
        />
        <path
          class="st5"
          d="M340.1,304.3c3.4,0.4,6.1,2.4,6.2,6.6c0.2,4.2-2.3,6.7-6.4,7.1c-3.7,0.4-7-2.3-7.4-6c0-0.3,0-0.5,0-0.7
		C332.4,307.6,335.7,304.4,340.1,304.3z"
        />
        <path
          class="st5"
          d="M486.7,304.2c4,0.7,6.5,3,6.4,7.1s-2.8,6.4-6.9,6.6c-3.7,0.2-6.8-2.7-6.9-6.3c0-0.4,0-0.6,0-1
		C479.8,306.5,482.5,304.6,486.7,304.2z"
        />
        <path
          class="st5"
          d="M633.2,318c-4.1,0.1-7.4-3-7.1-7.1c0.3-4,2.9-6.6,7-6.5c4.2,0.1,6.6,2.6,6.8,6.7c0.2,3.6-2.5,6.7-6.1,6.9
		C633.4,318,633.3,318,633.2,318z"
        />
        <path
          class="st5"
          d="M287.8,1097.4c-0.4,3.7-2.5,6.4-6.8,6.5c-4.2,0.1-6.8-2.4-7.2-6.4c-0.4-3.5,3.6-7.5,7-7.4
		C284.2,1090.2,287.6,1093.3,287.8,1097.4z"
        />
        <path
          class="st5"
          d="M251.6,886.4c4.2,0.6,6.7,2.7,6.8,7c0.1,4.2-2.7,6.5-6.7,7s-7-3-7-7.1C244.9,888.7,247.7,887.1,251.6,886.4z"
        />
        <path
          class="st5"
          d="M282.3,1323.3c3.2,0.2,5.5,2.9,5.5,6.9c0,4.1-2.8,6.7-6.9,6.6s-6.9-2.6-6.8-6.9
		C274.2,1325.8,276.6,1323.4,282.3,1323.3z"
        />
        <path
          class="st5"
          d="M375.7,340.4c-0.4,3.6-2,6.5-6.5,6.8c-3.9,0.2-7.5-3-7.4-6.9c0.2-4.1,2.5-6.6,6.8-6.7
		C372.7,333.3,375.7,336.2,375.7,340.4z"
        />
        <path
          class="st5"
          d="M426.5,347.1c-3.6-0.4-6-2.8-5.9-6.9c0.1-4,2.6-6.7,6.9-6.7c4.2,0,6.8,2.5,7,6.5
		C434.8,343.9,431.1,347.3,426.5,347.1z"
        />
        <path
          class="st5"
          d="M281.3,26.8c-4.5-0.2-7.3-2.5-7.3-6.5c-0.1-3.8,2-7,6.5-7.1c4.6-0.2,7.1,2.6,7.3,6.9
		C288,24.4,285.2,26.4,281.3,26.8z"
        />
        <path
          class="st5"
          d="M574.2,550.9c-4.4-0.3-6.9-2.7-6.8-6.9c0.1-4.2,2.6-6.8,7-6.7c4.2,0.2,6.8,2.7,6.8,6.9
		S578.4,550.6,574.2,550.9z"
        />
        <path
          class="st5"
          d="M375.8,457.2c-0.4,3.7-2.5,6.2-6.8,6.3c-4.2,0-6.8-2.3-7.1-6.4c-0.3-3.8,3.3-7.3,7.1-7.2
		C372.6,449.8,375.7,453,375.8,457.2z"
        />
        <path
          class="st5"
          d="M133.3,1206.8c5.4,0,7.6,2.7,7.9,6.6c0.2,3.8-2.3,6.7-6.6,7c-3.9,0.3-7.9-3.3-7.4-6.9
		C127.7,1209.5,129.8,1207,133.3,1206.8z"
        />
        <path
          class="st5"
          d="M463.5,426.3c0,5.3-2.2,8-6.5,8.2s-6.9-2.3-7-6.5s2.2-6.9,6.5-7S463.2,423,463.5,426.3z"
        />
        <path
          class="st5"
          d="M141.1,1243.2c-0.5,3.7-2.8,6.1-7,6.3c-3.9,0.1-7.4-3.6-6.9-7.3c0.5-4,3.1-6.6,7.3-6.3
		C138.7,1236.2,141,1238.6,141.1,1243.2z"
        />
        <path
          class="st5"
          d="M581.3,631.8c-0.3,3.5-2.6,6-6.8,6.3s-7-2.3-7.3-6.2s2-6.8,6.4-7.2C577.7,624.3,581.1,627.2,581.3,631.8z"
        />
        <path
          class="st5"
          d="M391.3,660.5c0.3-4.4,2.9-6.9,6.9-6.9s6.8,2.6,6.8,7c-0.1,4.1-2.5,6.9-6.8,6.8
		C394,667.3,391.5,664.8,391.3,660.5z"
        />
        <path
          class="st5"
          d="M428.1,638.2c-4.6-0.1-7.4-2.6-7.5-6.5c-0.2-3.9,2.2-6.8,6.5-7.1c4.1-0.4,7.8,2.8,7.4,6.6
		C434.3,635.4,431.9,637.7,428.1,638.2z"
        />
        <path
          class="st5"
          d="M134.7,696.5c-4.8-0.5-7.4-2.8-7.3-7c0.1-4.1,2.6-6.8,6.9-6.7s6.8,2.7,6.8,6.9
		C141.1,693.9,138.1,695.9,134.7,696.5z"
        />
        <path
          class="st5"
          d="M170.5,1359c-0.3,4.5-2.8,7-7,7s-6.9-2.4-6.8-6.8c0.2-4.1,2.6-6.7,6.9-6.7S170,1355.1,170.5,1359z"
        />
        <path
          class="st5"
          d="M281.4,638.2c-4.8-0.4-7.5-2.7-7.4-6.9c0.1-3.9,2.5-6.8,6.9-6.7s6.9,2.7,6.9,6.8S285.1,637.8,281.4,638.2z"
        />
        <path
          class="st5"
          d="M251.5,1031.9c4.2,0.6,6.7,2.8,6.8,7c0.2,4.4-2.7,6.5-6.6,7c-3.9,0.5-7-3.1-7-7.1
		C244.9,1034.2,247.7,1032.7,251.5,1031.9z"
        />
        <path
          class="st5"
          d="M280.6,1074.9c-4.2-0.5-6.8-2.9-6.8-7c0.1-4,2.5-6.6,6.8-6.7c3.8-0.1,7.5,3.6,7.1,7.1
		C287.3,1072.4,284.8,1074.7,280.6,1074.9z"
        />
        <path
          class="st5"
          d="M280.6,783.8c-3.8-0.1-6.6-2.5-6.8-6.4s2.2-6.8,6.4-7.1c4-0.3,7.4,2.7,7.6,6.6c0,0,0,0,0,0.1
		C288.1,780.3,284.7,783.7,280.6,783.8z"
        />
        <path
          class="st5"
          d="M192.5,696.4c-3.9-0.5-6.4-2.6-6.2-6.9c0.1-4.3,2.6-6.7,6.8-6.6s6.8,2.7,6.8,6.9
		C199.7,694,197.1,696.2,192.5,696.4z"
        />
        <path
          class="st5"
          d="M134.6,638.2c-4.5-0.3-7.2-2.7-7.3-6.7c-0.1-3.9,2.4-6.8,6.8-6.9c4.4-0.1,7,2.4,7,6.5
		C141.1,635.3,138.6,637.8,134.6,638.2z"
        />
        <path
          class="st5"
          d="M457.9,712c3.6,0.4,5.9,2.7,5.7,7.1c-0.2,4.3-2.8,6.7-7,6.5s-6.7-2.9-6.6-7.1C450.1,714.2,452.8,712,457.9,712
		z"
        />
        <path
          class="st5"
          d="M280.4,1366c-3.8-0.1-6.4-2.6-6.6-6.5s2.2-6.8,6.5-7.1c4-0.2,7.3,2.8,7.4,6.8l0,0
		C288.1,1362.6,284.7,1365.9,280.4,1366z"
        />
        <path
          class="st5"
          d="M375.8,602.7c-0.3,3.7-2.5,6.3-6.8,6.4c-4.2,0.1-6.8-2.2-7.2-6.4c-0.3-3.8,3-7.2,7-7.3
		C372.5,595.4,375.7,598.5,375.8,602.7z"
        />
        <path
          class="st5"
          d="M427.6,799.1c4.1,0.5,6.8,2.6,6.9,6.7c0.2,4.1-2.5,6.6-6.6,7.1c-3.9,0.5-7.6-3.2-7.3-6.9
		C420.9,802,423.3,799.5,427.6,799.1z"
        />
        <path
          class="st5"
          d="M456.9,799.1c4,0.5,6.8,2.6,6.9,6.7c0.2,4.1-2.5,6.6-6.6,7.1c-3.9,0.5-7.6-3.2-7.3-6.9
		C450.2,802,452.6,799.5,456.9,799.1z"
        />
        <path
          class="st5"
          d="M486.4,799.1c4,0.5,6.8,2.6,6.9,6.7c0.2,4.1-2.5,6.6-6.6,7.1c-3.8,0.5-7.6-3.2-7.3-6.9
		C479.8,802,482,799.5,486.4,799.1z"
        />
        <path
          class="st5"
          d="M486.4,769.5c4,0.5,6.8,2.6,6.9,6.7c0.2,4.1-2.5,6.6-6.6,7.1c-3.8,0.5-7.6-3.2-7.3-7
		C479.8,772.2,482,769.7,486.4,769.5z"
        />
        <path
          class="st5"
          d="M303.1,1651.1c0.3-4.6,2.4-7.4,6.8-7.6c4.3-0.2,6.9,2.1,7.1,6.4s-2.3,6.9-6.5,7.1
		C306.4,1657.3,304,1654.6,303.1,1651.1z"
        />
        <path
          class="st5"
          d="M426.8,492.6c-3.6-0.4-6-2.7-6.2-6.8c-0.2-3.8,3.6-7.1,7.4-6.7c4.2,0.4,6.5,2.8,6.5,7.1
		C434.6,489.8,431,492.7,426.8,492.6z"
        />
        <path
          class="st5"
          d="M199.8,601.9c-0.3,4.3-2.3,6.9-6.7,7.1c-3.6,0.4-6.8-2.3-7.2-5.9c0-0.4,0-0.6,0-1c0.3-4.1,2.6-6.6,6.8-6.7
		C197.1,595.4,199.1,598.2,199.8,601.9z"
        />
        <path
          class="st5"
          d="M405.1,952.6c-1,3.3-2.9,6.3-7.3,5.9c-4.1-0.4-6.7-3-6.4-7.3c0.3-4.3,3.3-6.7,7.3-6.4
		C402.7,945,405.1,947.8,405.1,952.6z"
        />
        <path
          class="st5"
          d="M639.9,834.8c-0.4,4.5-2.5,7.1-6.9,7.1c-4.2,0-7-2.1-6.9-6.6c0.1-4.2,2.3-6.9,6.8-6.9
		C637.2,828.4,639.1,831.2,639.9,834.8z"
        />
        <path
          class="st5"
          d="M82.5,1039.3c-0.4,4-2.9,6.6-7,6.4c-4.2-0.2-6.8-2.6-6.8-6.8c-0.1-3.8,3.6-7.3,7.3-7
		C79.9,1032.5,82.3,1035,82.5,1039.3z"
        />
        <path
          class="st5"
          d="M281.7,492.6c-5.1,0-7.5-2.6-7.8-6.4s2.2-6.7,6.5-7.1c4-0.4,7.8,2.9,7.4,6.7
		C287.5,489.8,285.3,492.2,281.7,492.6z"
        />
        <path
          class="st5"
          d="M610.4,573c-0.3,4.5-2.7,6.9-6.8,7c-4.2,0.1-6.8-2.6-6.8-6.8s2.5-6.7,6.8-6.7S610,569,610.4,573z"
        />
        <path
          class="st5"
          d="M310.7,857.6c3.9,0.3,6.2,2.5,6.1,6.9s-2.5,6.8-6.8,6.7s-6.8-2.6-6.8-6.9C303.4,859.9,305.8,857.7,310.7,857.6
		z"
        />
        <path
          class="st5"
          d="M111.8,893.7c-0.4,4.3-3.1,6.7-7,6.6c-3.9-0.1-6.8-2.4-6.8-6.8c-0.1-3.7,2.8-6.8,6.5-6.9c0.3,0,0.5,0,0.8,0
		C109.5,887,111.5,889.5,111.8,893.7z"
        />
        <path
          class="st5"
          d="M280.4,929.3c-3.6,0-6.5-2.8-6.5-6.4c0-0.1,0-0.1,0-0.2c-0.1-3.9,2.3-6.7,6.6-7c4-0.1,7.2,2.9,7.4,6.9
		C288,926,284.5,929.3,280.4,929.3z"
        />
        <path
          class="st5"
          d="M134.1,479c4.2,0.3,6.9,2.5,7,6.6s-2.3,6.7-6.6,7.1c-3.8,0.4-7.8-3.2-7.4-6.9
		C127.5,481.8,129.7,479.3,134.1,479z"
        />
        <path
          class="st5"
          d="M340.2,682.7c4.1,0.9,6.7,3.2,6.1,7.5c-0.4,4.3-3.1,6.4-7.4,6c-4.2-0.4-6.4-3.1-6.1-7.3
		C333.2,684.6,336.2,683,340.2,682.7z"
        />
        <path
          class="st5"
          d="M186,1126.6c0.3-4.4,2.5-7.1,6.8-7.1c4.2,0,6.8,2.6,6.9,6.7c0.1,4.1-2.3,6.7-6.8,6.8
		C188.5,1133.1,186.5,1130.6,186,1126.6z"
        />
        <path
          class="st5"
          d="M339.5,1133c-4.3-0.4-6.8-2.5-6.8-6.9s2.6-6.7,6.8-6.7c4.2,0,6.8,2.7,6.8,6.9
		C346.4,1130.4,343.8,1132.9,339.5,1133z"
        />
        <path
          class="st5"
          d="M170.4,1504.5c-0.2,4.2-2.5,6.8-6.7,7s-7-2.3-7.1-6.5s2.3-6.9,6.7-7.1C167.6,1497.7,169.9,1500.2,170.4,1504.5
		z"
        />
        <path
          class="st5"
          d="M317.1,717.8c-0.4,5-2.5,7.8-6.8,7.7c-4.2,0-6.8-2.5-6.9-6.8s2.3-6.7,6.8-6.8S316.5,714.6,317.1,717.8z"
        />
        <path
          class="st5"
          d="M405.2,515.3c-0.8,3.7-2.8,6.7-7.1,6.4c-4.1-0.3-6.8-2.6-6.8-7c0.1-4.4,2.9-6.9,7-6.7S404.9,510.8,405.2,515.3
		z"
        />
        <path
          class="st5"
          d="M258.5,952c-0.5,3.6-2.5,6.6-6.8,6.5c-4.2-0.1-6.8-2.6-6.8-6.9c-0.1-4.3,2.8-6.9,6.8-6.9
		C255.5,944.8,258.1,947.1,258.5,952z"
        />
        <path
          class="st5"
          d="M632.5,449.7c4.3,0.4,6.9,2.5,7.2,6.6s-2.2,6.7-6.4,7.1c-3.6,0.5-6.9-2.1-7.4-5.7c0-0.4-0.1-0.6,0-1
		C626,452.5,628.7,450.5,632.5,449.7z"
        />
        <path
          class="st5"
          d="M82.3,516.3c-0.1,3-2.7,5.4-6.8,5.4s-6.8-2.4-6.9-6.6c-0.1-4.2,2.6-6.6,6.6-7.1
		C79.1,507.4,82.3,510.9,82.3,516.3z"
        />
        <path
          class="st5"
          d="M193.1,1410.5c4,0.5,6.7,2.7,6.7,6.9c0,4.2-2.6,6.7-6.8,6.8c-4.2,0.1-6.8-2.5-6.8-6.7
		C186.1,1413.2,188.6,1410.8,193.1,1410.5z"
        />
        <path
          class="st5"
          d="M317,1010c-0.4,4.1-2.6,6.8-6.9,6.6s-6.8-2.6-6.8-6.9c0.1-4.3,2.7-6.6,6.9-6.6
		C314.6,1003.1,316.8,1005.8,317,1010z"
        />
        <path
          class="st5"
          d="M258.5,660.8c-0.8,3.8-2.7,6.8-7.1,6.6c-4.2-0.2-6.8-2.7-6.7-7s3-6.7,7-6.7
		C255.7,653.7,258.1,656.4,258.5,660.8z"
        />
        <path
          class="st5"
          d="M279.2,55.9c-3.3-0.9-5.7-3.5-5.1-7.6s3.3-6.3,7.8-5.8c4,0.5,6.1,2.9,6,7C287.9,53.6,283.9,56.4,279.2,55.9z"
        />
        <path
          class="st5"
          d="M222.3,158.7c3.9,0.2,6.8,3.4,6.8,7.2c0,0.1,0,0.2,0,0.4c-0.4,4.2-3.2,6.3-7.4,6.1c-4-0.2-6.1-2.7-6.5-6.5
		C215,162.1,218.6,158.6,222.3,158.7z"
        />
        <path
          class="st5"
          d="M279.9,201.5c-3.4-0.3-5.9-2.8-5.9-6.9s2.7-6.7,7-6.7c4,0,6.5,2.3,6.8,6.1C288.2,198,284.8,201.5,279.9,201.5z
		"
        />
        <path
          class="st5"
          d="M426.3,201.5c-3.6-0.4-6.1-3.5-5.7-7c0.2-4,2.8-6.6,7-6.5s6.7,2.6,6.9,6.7C434.8,198.4,431,201.7,426.3,201.5z
		"
        />
        <path
          class="st5"
          d="M340.2,550.9c-4.8-0.2-7.1-2.5-7.3-6.7c-0.2-4.5,2.4-6.7,6.6-6.7s6.8,2.3,6.9,6.6
		C346.5,548.4,343.6,550.2,340.2,550.9z"
        />
        <path
          class="st5"
          d="M751,275.3c3.3,0.5,6,2.5,6,6.9s-2.7,6.8-6.8,6.8s-6.8-2.6-6.8-6.9S745.9,275.5,751,275.3z"
        />
        <path
          class="st5"
          d="M134.5,1074.9c-4.8-0.5-7.4-3-7.3-7.1c0.2-3.8,2.7-6.7,7.1-6.6s6.8,2.9,6.7,7
		C140.8,1072.2,138.2,1074.5,134.5,1074.9z"
        />
        <path
          class="st5"
          d="M426.6,929.2c-3.6-0.3-6.1-3.2-5.9-6.8v-0.1c0.1-3.9,2.6-6.7,6.9-6.7s6.7,2.5,7,6.6
		C434.8,926.2,431.2,929.4,426.6,929.2z"
        />
        <path
          class="st5"
          d="M281.2,347.2c-4.6-0.4-7.2-2.8-7.2-6.9c0-4,2.6-6.8,6.9-6.8s6.9,2.6,6.9,6.7
		C287.8,344.3,285.1,346.7,281.2,347.2z"
        />
        <path
          class="st5"
          d="M258.5,369.5c-0.6,4-2.8,6.8-7,6.6c-4.1-0.2-6.8-2.5-6.8-7c0.1-4.5,2.9-6.9,6.9-6.8
		C255.8,362.5,258.3,365.2,258.5,369.5z"
        />
        <path
          class="st5"
          d="M134.5,929.4c-4.5-0.3-7.2-2.7-7.2-6.7s2.3-6.7,6.7-7c4-0.2,7.6,3,7.3,6.9C140.7,926.5,138.4,929,134.5,929.4z
		"
        />
        <path
          class="st5"
          d="M375.7,893.7c-0.3,4.3-2.9,6.5-7.2,6.2c-4-0.2-6.4-2.5-6.6-6.4s3.6-7.5,7.3-7.1
		C373.4,886.9,375.6,889.5,375.7,893.7z"
        />
        <path
          class="st5"
          d="M134,770.2c4.8,0.2,7,2.7,6.9,6.9c-0.1,4.1-2.6,6.7-7,6.6s-6.9-2.8-6.8-6.9C127.1,772.8,129.9,770.3,134,770.2
		z"
        />
        <path
          class="st5"
          d="M222.3,638.3c-4.1-0.7-7-2.8-7-7.1s2.5-6.6,6.9-6.7s6.8,2.5,6.8,6.7C229,635.5,226.4,637.8,222.3,638.3z"
        />
        <path
          class="st5"
          d="M281,1278.7c-4.4-0.5-7-2.9-6.8-7.1c0.2-4.1,2.6-6.8,7-6.5c4.2,0.2,6.8,2.7,6.8,7
		C287.8,1276.3,284.8,1278.3,281,1278.7z"
        />
        <path
          class="st5"
          d="M551.7,661.9c-1.1,3.3-3.1,6-7.5,5.3c-4.2-0.6-6.6-3.3-6-7.5s3.6-6.5,7.6-6
		C549.9,654.2,551.9,657.1,551.7,661.9z"
        />
        <path
          class="st5"
          d="M111.8,514.9c-0.3,3.9-2.3,6.6-6.6,7c-3.7,0.3-6.9-2.4-7.2-6c0-0.3,0-0.5,0-0.8c0.1-4.3,2.8-6.8,6.8-6.9
		C108.7,508,111.3,510.5,111.8,514.9z"
        />
        <path
          class="st5"
          d="M538,369.2c0.3-4.1,3-6.6,6.9-6.5s6.9,2.5,6.8,6.9c-0.2,4.1-2.6,6.9-6.8,6.7C540.8,376,538.1,373.7,538,369.2z
		"
        />
        <path
          class="st5"
          d="M610.4,426.8c-0.2,5.2-2.8,7.5-6.9,7.6c-4.2,0.1-6.8-2.7-6.8-6.8c-0.1-4.1,2.5-6.7,6.8-6.7
		S609.9,423.5,610.4,426.8z"
        />
        <path
          class="st5"
          d="M229,1038.9c-0.1,4.3-2.6,6.8-6.8,6.9c-3.8,0.1-6.7-2-6.8-6.1s1.8-7.6,6.6-7.6
		C226.3,1032,228.7,1034.7,229,1038.9z"
        />
        <path
          class="st5"
          d="M610.4,719c-0.4,4-2.6,6.7-6.9,6.6s-6.8-2.7-6.7-7c0-2.7,0.8-5.6,4.6-4.9C604.9,714.2,610.8,711.3,610.4,719z"
        />
        <path
          class="st5"
          d="M520,773.7c-0.3,6-2.7,8.9-6.7,8.6c-1.7-0.1-4.4-3.5-4.6-5.6c-0.3-4.1,2.6-6.8,6.8-6.3
		C517.5,770.6,519.1,772.9,520,773.7z"
        />
        <path
          class="st5"
          d="M536.8,747.1c0.4-3.9,2.9-6.2,7.2-6.1c4.3,0.2,6.7,2.9,6.5,7.1c-0.2,4.1-2.9,6.7-7.1,6.4
		C539.2,754.2,536.8,751.7,536.8,747.1z"
        />
        <path
          class="st5"
          d="M463.6,835c-0.3,4.2-2.5,6.7-6.8,6.9c-3.7,0.1-6.8-2.7-6.9-6.4c0-0.3,0-0.5,0-0.7c0.3-4.3,3-6.5,7-6.5
		C461.2,828.3,463.3,831,463.6,835z"
        />
      </g>
      <path
        class="st6"
        d="M309.1,319.3L309.1,319.3L309.1,319.3c0.8,0.5,1.5,1.1,2,1.9C310.6,320.4,309.9,319.8,309.1,319.3L309.1,319.3z
	M308.5,322.3c0.2,0.2,0.3,0.3,0.4,0.5l0,0c0.4,1,0.5,2,0.4,3c0.1-1-0.1-2-0.4-3l0,0C308.7,322.6,308.6,322.4,308.5,322.3
	M312.1,325.6c0,0.1,0,0.1,0,0.2C312,325.7,312,325.7,312.1,325.6"
      />
      <path
        class="st7"
        d="M551.6,467.3c0,0.1,0,0.2,0,0.3C551.6,467.5,551.6,467.5,551.6,467.3L551.6,467.3
	C551.6,467.2,551.6,467.3,551.6,467.3z"
      />
      <path
        class="st7"
        d="M261.4,558.6c0-0.1,0-0.1,0-0.2V558C261.4,558.2,261.4,558.4,261.4,558.6z"
      />
      <path
        class="st8"
        d="M580.9,771.9c-44.1-48.8,9.1-101.8,9.1-101.8s-57.6-11.1-98.7,4.2"
      />
      <path class="st8" d="M587,667.6c0,0,4-55.9-80.5-98.2" />
      <path
        class="st8"
        d="M576.3,666.7c-33.3-12.1-128.6-51.8-192.8-153.9c-40.6-64.6-51.6-127.1-55.1-162.7"
      />
      <path
        class="st8"
        d="M493,564.7c20.2-19.7,76.5-79.9,89.6-175.2c6.2-45.9,1.6-92.6-13.4-136.4c8.6,0.8,155,15.2,165.2,70.8
	C745.3,383,595,452.2,571.6,463c-68.3,31.5-130.2,42.4-170.8,46.6"
      />
      <path
        class="st8"
        d="M220.3,492.6c76.1-53.1,158.6,4.9,158.6,4.9s-94.6,17.6-65.9,99.6"
      />
      <path class="st8" d="M329.6,319.1c66.3-10.7,63.2-97.4,2.1-94.2" />
      <path class="st8" d="M337.9,315.4c278.6,26.3,261,349,261,349" />
      <line class="st0" x1="550.2" y1="716.8" x2="548.9" y2="717.1" />
      <line class="st1" x1="545.5" y1="717.6" x2="542.2" y2="718.2" />
      <polyline class="st0" points="540.6,718.5 539.3,718.7 539,717.4 " />
      <line class="st1" x1="538.4" y1="714.1" x2="537.9" y2="710.8" />
      <line class="st0" x1="537.6" y1="709.1" x2="537.3" y2="707.8" />
      <path class="st0" d="M584.6,669.3l-1.2,0.5" />
      <path class="st9" d="M578.7,671.9c-17.8,8.8-31.4,24.4-37.9,43.2" />
      <path class="st0" d="M540,717.6c-0.1,0.4-0.3,0.8-0.4,1.3" />
      <line class="st0" x1="314.6" y1="643.2" x2="315.7" y2="644" />
      <line class="st1" x1="318.3" y1="646.1" x2="321" y2="648.2" />
      <polyline class="st0" points="322.3,649.2 323.4,650 324.2,649 " />
      <line class="st1" x1="326.2" y1="646.3" x2="328.3" y2="643.7" />
      <line class="st0" x1="329.4" y1="642.3" x2="330.2" y2="641.3" />
      <path class="st0" d="M308.3,597l0.7,1.1" />
      <path class="st10" d="M311.8,602.7c7,13,10.7,28.8,11.3,43.8" />
      <path class="st0" d="M323.1,649.2c0,0.4,0,0.9,0,1.3" />
      <line class="st0" x1="339.9" y1="546.6" x2="339.6" y2="545.3" />
      <line class="st1" x1="338.9" y1="542" x2="338.2" y2="538.7" />
      <polyline class="st0" points="337.9,537.1 337.6,535.8 336.3,536 " />
      <line class="st1" x1="333" y1="536.7" x2="329.7" y2="537.4" />
      <line class="st0" x1="328.1" y1="537.8" x2="326.8" y2="538" />
      <path class="st0" d="M313.5,577.1l0.4-1.3" />
      <path class="st11" d="M315.7,570.8c4.9-13.2,10.8-23.4,18.8-32" />
      <path class="st0" d="M336.4,536.9l0.9-0.9" />
      <line class="st0" x1="252.9" y1="535.9" x2="254.2" y2="535.7" />
      <line class="st1" x1="257.5" y1="535.2" x2="260.9" y2="534.6" />
      <polyline class="st0" points="262.5,534.4 263.8,534.2 263.6,532.9 " />
      <line class="st1" x1="263.1" y1="529.5" x2="262.6" y2="526.2" />
      <line class="st0" x1="262.3" y1="524.5" x2="262.1" y2="523.2" />
      <path class="st0" d="M223.7,508.1l1.2,0.5" />
      <path class="st11" d="M229.9,510.6c12.9,5.6,22.9,12,31,20.4" />
      <path class="st0" d="M262.7,532.9c0.3,0.3,0.6,0.7,0.9,1" />
      <line class="st1" x1="163.7" y1="537.3" x2="160.3" y2="537" />
      <polyline class="st0" points="158.6,536.9 157.3,536.8 157.4,535.4 " />
      <path class="st0" d="M195.5,508l-1.2,0.6" />
      <path class="st8" d="M189.5,510.9c-24.5,18.5-43.1,38.8-57.9,64.5" />
      <path class="st0" d="M158.3,535.4l-0.8,1" />
      <line class="st0" x1="182.4" y1="443.5" x2="181.1" y2="443.2" />
      <line class="st1" x1="177.8" y1="442.4" x2="174.5" y2="441.7" />
      <polyline class="st0" points="172.9,441.3 171.6,441 171.3,442.3 " />
      <line class="st1" x1="170.5" y1="445.6" x2="169.8" y2="448.9" />
      <line class="st0" x1="169.4" y1="450.5" x2="169.1" y2="451.8" />
      <path class="st0" d="M199,480.2l-1-0.9" />
      <path class="st11" d="M194.2,475.6c-9.9-10-16.7-19.7-21.1-30.5" />
      <path class="st0" d="M172.1,442.6c-0.2-0.4-0.3-0.8-0.4-1.2" />
      <line class="st0" x1="378.6" y1="457.4" x2="377.6" y2="456.6" />
      <line class="st1" x1="374.8" y1="454.7" x2="372.1" y2="452.7" />
      <polyline class="st0" points="370.7,451.7 369.7,450.9 368.9,452 " />
      <line class="st1" x1="366.9" y1="454.7" x2="364.9" y2="457.4" />
      <line class="st0" x1="363.9" y1="458.8" x2="363.2" y2="459.9" />
      <path class="st0" d="M379.6,497.7l-0.5-1.2" />
      <path class="st11" d="M377,491.6c-5.2-13.1-7.7-24.6-7.5-36.4" />
      <path class="st0" d="M369.6,452.6c0-0.4,0-0.9,0.1-1.3" />
      <line class="st0" x1="424.4" y1="484.8" x2="424.9" y2="483.6" />
      <line class="st1" x1="426" y1="480.4" x2="427.2" y2="477.2" />
      <polyline class="st0" points="427.7,475.6 428.2,474.4 427,474 " />
      <line class="st1" x1="423.8" y1="472.8" x2="420.6" y2="471.6" />
      <line class="st0" x1="419" y1="471.1" x2="417.8" y2="470.6" />
      <path class="st0" d="M386,496.9l1-0.8" />
      <path class="st11" d="M391.1,492.7c11.1-8.6,21.6-14.2,32.9-17.3" />
      <path class="st0" d="M426.6,474.8c0.4-0.1,0.9-0.2,1.3-0.3" />
      <line class="st0" x1="527.8" y1="620.9" x2="529.1" y2="620.9" />
      <line class="st1" x1="532.5" y1="620.8" x2="535.9" y2="620.8" />
      <polyline class="st0" points="537.6,620.7 538.9,620.7 538.9,622 " />
      <line class="st1" x1="539" y1="625.4" x2="539" y2="628.8" />
      <line class="st0" x1="539.1" y1="630.5" x2="539.1" y2="631.8" />
      <path class="st0" d="M487.1,660.4l1.2-0.5" />
      <path class="st12" d="M493.3,657.7c17-7.8,32.6-18.9,43.4-33.3" />
      <path class="st0" d="M538.3,622.2l0.8-1.1" />
      <line class="st0" x1="387.7" y1="625.1" x2="387" y2="626.2" />
      <line class="st1" x1="385.2" y1="629.1" x2="383.4" y2="631.9" />
      <polyline class="st0" points="382.5,633.4 381.8,634.5 380.7,633.8 " />
      <line class="st1" x1="377.8" y1="632" x2="375" y2="630.2" />
      <line class="st0" x1="373.5" y1="629.3" x2="372.4" y2="628.6" />
      <path class="st0" d="M375.8,498.1c-0.1,0.4-0.2,0.8-0.4,1.3" />
      <path class="st3" d="M374.2,504.6c-5.6,27.7-8.5,90.4,5.6,126.1" />
      <path class="st0" d="M380.9,633.2c0.2,0.4,0.4,0.8,0.5,1.2" />
      <line class="st0" x1="333.4" y1="780.5" x2="332.1" y2="780.6" />
      <line class="st1" x1="328.7" y1="780.8" x2="325.4" y2="781.1" />
      <polyline class="st0" points="323.7,781.2 322.4,781.3 322.3,780 " />
      <line class="st1" x1="322" y1="776.6" x2="321.8" y2="773.2" />
      <line class="st0" x1="321.7" y1="771.6" x2="321.6" y2="770.2" />
      <path class="st0" d="M472.7,688.6c-0.4,0-0.9,0-1.3,0" />
      <path class="st13" d="M466.1,689c-33.4,4.1-108.8,37.2-141.9,88.6" />
      <path class="st0" d="M322.9,779.8c-0.2,0.4-0.5,0.8-0.7,1.1" />
      <line class="st0" x1="183.1" y1="826.1" x2="181.8" y2="826.5" />
      <line class="st1" x1="178.6" y1="827.4" x2="175.3" y2="828.3" />
      <polyline class="st0" points="173.7,828.8 172.4,829.1 172.1,827.9 " />
      <line class="st1" x1="171.2" y1="824.6" x2="170.2" y2="821.4" />
      <line class="st0" x1="169.8" y1="819.7" x2="169.4" y2="818.5" />
      <path class="st0" d="M476.6,681.9l-1.3-0.1" />
      <path class="st8" d="M470.1,681.6c-93.6-1.2-256.4,85.6-295.8,143.8" />
      <path class="st0" d="M172.9,827.7c-0.2,0.4-0.5,0.7-0.7,1.1" />
      <line class="st0" x1="420.3" y1="417.4" x2="421.6" y2="417.6" />
      <line class="st1" x1="425" y1="418" x2="428.3" y2="418.5" />
      <polyline class="st0" points="430,418.7 431.3,418.9 431.5,417.6 " />
      <line class="st1" x1="431.9" y1="414.3" x2="432.4" y2="410.9" />
      <line class="st0" x1="432.6" y1="409.3" x2="432.8" y2="408" />
      <path class="st0" d="M330.6,326.6l1.2,0.5" />
      <path class="st3" d="M336.7,329.7c24.8,13.5,74.7,51.6,93,85.3" />
      <path class="st0" d="M430.9,417.4c0.2,0.4,0.4,0.8,0.6,1.2" />
      <line class="st0" x1="558" y1="278.4" x2="559.1" y2="277.7" />
      <line class="st1" x1="561.9" y1="275.7" x2="564.6" y2="273.8" />
      <polyline class="st0" points="566,272.8 567.1,272 566.3,271 " />
      <line class="st1" x1="564.3" y1="268.2" x2="562.4" y2="265.5" />
      <line class="st0" x1="561.4" y1="264.1" x2="560.6" y2="263" />
      <path class="st0" d="M343.2,316.9c0.3-0.3,0.6-0.7,0.9-1" />
      <path class="st14" d="M347.7,312.2c41.2-38.8,166.1-54.1,213.7-40.8" />
      <path class="st0" d="M563.9,272.1l1.3,0.4" />
      <line class="st0" x1="420.7" y1="172.6" x2="420.9" y2="171.3" />
      <line class="st1" x1="421.6" y1="168" x2="422.3" y2="164.7" />
      <polyline class="st0" points="422.7,163 423,161.7 421.7,161.5 " />
      <line class="st1" x1="418.4" y1="160.8" x2="415.1" y2="160.1" />
      <line class="st0" x1="413.4" y1="159.7" x2="412.1" y2="159.5" />
      <path class="st0" d="M323.9,255.7c0.2-0.4,0.4-0.8,0.6-1.1" />
      <path class="st3" d="M327.3,249.9c15.2-23.8,56.7-70.9,91.6-86.8" />
      <path class="st0" d="M421.4,162l1.2-0.5" />
      <line class="st0" x1="301" y1="480.8" x2="300" y2="481.6" />
      <line class="st1" x1="297.5" y1="483.9" x2="295" y2="486.1" />
      <polyline class="st0" points="293.7,487.2 292.7,488.1 291.8,487.1 " />
      <line class="st1" x1="289.6" y1="484.6" x2="287.4" y2="482" />
      <line class="st0" x1="286.2" y1="480.8" x2="285.4" y2="479.8" />
      <path class="st0" d="M333.5,329.9c-0.2,0.4-0.5,0.7-0.7,1.1" />
      <path class="st15" d="M330.3,335.6c-14.2,27.8-41.4,108.6-38.4,148.4" />
      <path class="st0" d="M292.2,486.6c0.1,0.4,0.1,0.9,0.2,1.3" />
      <line class="st0" x1="241.9" y1="429.9" x2="240.6" y2="430.2" />
      <line class="st1" x1="237.3" y1="430.9" x2="234" y2="431.6" />
      <polyline class="st0" points="232.4,431.9 231.1,432.2 230.8,430.9 " />
      <line class="st1" x1="230.1" y1="427.6" x2="229.4" y2="424.3" />
      <line class="st0" x1="229" y1="422.7" x2="228.8" y2="421.4" />
      <path class="st0" d="M325,333.1l-1.1,0.6" />
      <path class="st3" d="M319.2,336.6c-23.8,15.2-70.9,56.7-86.8,91.6" />
      <path class="st0" d="M231.3,430.6c-0.2,0.4-0.3,0.8-0.5,1.2" />
      <line class="st0" x1="514" y1="311.8" x2="513.5" y2="310.6" />
      <line class="st1" x1="512" y1="307.5" x2="510.5" y2="304.5" />
      <polyline class="st0" points="509.8,303 509.2,301.8 508,302.4 " />
      <line class="st1" x1="505" y1="303.9" x2="501.9" y2="305.3" />
      <line class="st0" x1="500.4" y1="306.1" x2="499.2" y2="306.7" />
      <path class="st0" d="M493.6,556.4c-0.2-0.4-0.4-0.8-0.5-1.2" />
      <path class="st16" d="M491.4,550.3c-13.3-45.3-7.2-206.2,15.2-245.2" />
      <path class="st0" d="M508,302.9c0.3-0.4,0.5-0.7,0.8-1.1" />
      <line class="st0" x1="279.4" y1="168.5" x2="278.5" y2="167.6" />
      <line class="st1" x1="276" y1="165.2" x2="273.6" y2="162.9" />
      <polyline class="st0" points="272.4,161.7 271.5,160.8 270.5,161.7 " />
      <line class="st1" x1="268.2" y1="164.2" x2="265.9" y2="166.6" />
      <line class="st0" x1="264.7" y1="167.8" x2="263.8" y2="168.8" />
      <path class="st0" d="M330.3,324.2l-1.1-0.7" />
      <path class="st0" d="M270.8,162.3c0.1-0.4,0.1-0.9,0.2-1.3" />
      <path class="st1" d="M226.5,591.9" />
      <line class="st1" x1="226.2" y1="586.4" x2="227.9" y2="583.4" />
      <path class="st0" d="M314,595.9l-1.2-0.5" />
      <path class="st8" d="M308,621.5c-66.9-54.7-108.3-39.7-147.7-17.9" />
      <line class="st0" x1="204.2" y1="617.3" x2="203.5" y2="618.4" />
      <line class="st1" x1="201.6" y1="621.2" x2="199.7" y2="624" />
      <polyline class="st0" points="198.8,625.4 198,626.5 196.9,625.8 " />
      <line class="st1" x1="194.1" y1="623.9" x2="191.3" y2="622" />
      <line class="st0" x1="189.9" y1="621" x2="188.8" y2="620.3" />
      <path class="st0" d="M201.3,511.9l-0.6,1.2" />
      <path class="st17" d="M198.6,517.8c-15.8,37.3-6.6,80.4-1.8,104.3" />
      <path class="st0" d="M197.3,624.6c0.1,0.4,0.2,0.9,0.3,1.3" />
      <line class="st0" x1="258.4" y1="676.5" x2="258.4" y2="677.8" />
      <line class="st1" x1="258.3" y1="681.2" x2="258.2" y2="684.6" />
      <polyline class="st0" points="258.2,686.2 258.2,687.6 259.5,687.6 " />
      <line class="st1" x1="262.9" y1="687.6" x2="266.3" y2="687.7" />
      <line class="st0" x1="268" y1="687.7" x2="269.3" y2="687.8" />
      <path class="st0" d="M301.6,592.7c-0.1,0.4-0.1,0.9-0.2,1.3" />
      <path class="st18" d="M300.5,599.4c-6.8,39.2-22.9,68.4-38.7,85.1" />
      <path class="st0" d="M259.9,686.4l-0.9,0.9" />
      <line class="st0" x1="207.7" y1="699.1" x2="206.6" y2="699.8" />
      <line class="st1" x1="203.7" y1="701.6" x2="200.9" y2="703.4" />
      <polyline class="st0" points="199.5,704.3 198.3,705 197.6,703.9 " />
      <line class="st1" x1="195.9" y1="701" x2="194.1" y2="698.1" />
      <line class="st0" x1="193.2" y1="696.7" x2="192.5" y2="695.6" />
      <path class="st0" d="M309.8,588.8l-1.3,0.4" />
      <path class="st19" d="M303.6,590.9c-61.2,22.4-96.4,83.6-104.4,109.5" />
      <path class="st0" d="M198.5,703c-0.1,0.4-0.2,0.9-0.3,1.3" />
      <line class="st0" x1="255.6" y1="292.2" x2="254.3" y2="292.3" />
      <line class="st1" x1="250.9" y1="292.7" x2="247.6" y2="293" />
      <polyline class="st0" points="245.9,293.2 244.6,293.3 244.7,294.6 " />
      <line class="st1" x1="245.1" y1="298" x2="245.4" y2="301.4" />
      <line class="st0" x1="245.6" y1="303" x2="245.7" y2="304.3" />
      <path class="st0" d="M321,327.8l-1.3,0.2" />
      <path class="st20" d="M314.4,328.6c-26.5,1.8-52-10.3-67.2-32.1" />
      <path class="st0" d="M245.7,294.3c-0.2-0.4-0.5-0.7-0.7-1.1" />
      <line class="st0" x1="245.4" y1="339.5" x2="244.6" y2="340.6" />
      <line class="st1" x1="242.7" y1="343.4" x2="240.9" y2="346.2" />
      <polyline class="st0" points="239.9,347.6 239.2,348.7 240.3,349.4 " />
      <line class="st1" x1="243.1" y1="351.3" x2="245.9" y2="353.2" />
      <line class="st0" x1="247.3" y1="354.1" x2="248.4" y2="354.9" />
      <path class="st0" d="M325.3,328.4l-1.2,0.6" />
      <path class="st21" d="M319.2,331.2c-20.7,9.4-48.9,20-75.9,17.5" />
      <path class="st0" d="M240.6,348.5l-1.3-0.2" />
      <line class="st0" x1="417.7" y1="591.6" x2="416.9" y2="592.6" />
      <line class="st1" x1="414.9" y1="595.3" x2="413" y2="598.1" />
      <polyline class="st0" points="412,599.4 411.2,600.5 412.2,601.3 " />
      <line class="st1" x1="415" y1="603.3" x2="417.7" y2="605.3" />
      <line class="st0" x1="419.1" y1="606.2" x2="420.1" y2="607" />
      <path class="st0" d="M488.8,574.6c-0.3,0.3-0.6,0.6-0.9,0.9" />
      <path class="st22" d="M484.1,579c-17.2,14-46.7,21.6-69,21.3" />
      <path class="st0" d="M412.6,600.2l-1.3-0.1" />
      <line class="st0" x1="353.4" y1="402.5" x2="354.4" y2="403.2" />
      <line class="st1" x1="357.2" y1="405.1" x2="360" y2="407" />
      <polyline class="st0" points="361.4,407.9 362.5,408.7 363.3,407.6 " />
      <line class="st1" x1="365.1" y1="404.8" x2="367" y2="402" />
      <line class="st0" x1="368" y1="400.6" x2="368.7" y2="399.5" />
      <path class="st0" d="M334,332c0.3,0.3,0.6,0.6,1,0.9" />
      <path class="st22" d="M338.5,336.6c14.6,16.7,23.2,45.9,23.7,68.2" />
      <path class="st0" d="M362.2,407.3c0,0.5,0,0.9,0,1.3" />
      <line class="st0" x1="396.4" y1="574.3" x2="397.4" y2="575.1" />
      <line class="st1" x1="400" y1="577.3" x2="402.6" y2="579.4" />
      <polyline class="st0" points="403.9,580.5 404.9,581.3 405.7,580.3 " />
      <line class="st1" x1="407.9" y1="577.7" x2="410" y2="575.1" />
      <line class="st0" x1="411.1" y1="573.8" x2="412" y2="572.8" />
      <path class="st0" d="M381.7,492.3c0.2,0.4,0.5,0.8,0.7,1.1" />
      <path class="st23" d="M385,497.9c12.1,21.6,20.1,47.5,19.6,79.4" />
      <path class="st0" d="M404.6,579.9c0,0.4,0,0.9,0,1.3" />
      <line class="st0" x1="590.1" y1="713.2" x2="591.2" y2="714" />
      <line class="st1" x1="593.9" y1="716" x2="596.6" y2="718" />
      <polyline class="st0" points="598,719 599,719.8 599.8,718.7 " />
      <line class="st1" x1="601.8" y1="716" x2="603.8" y2="713.3" />
      <line class="st0" x1="604.8" y1="711.9" x2="605.6" y2="710.9" />
      <path class="st0" d="M586,678c0.2,0.4,0.5,0.7,0.7,1.1" />
      <path class="st24" d="M589.1,683.3c5.4,10,8.5,21,9,32.3" />
      <path class="st0" d="M598.3,718.1c0,0.4,0,0.9,0,1.3" />
      <line class="st0" x1="523.7" y1="610.1" x2="523.5" y2="608.7" />
      <line class="st1" x1="523.2" y1="605.4" x2="522.8" y2="602" />
      <polyline class="st0" points="522.7,600.4 522.5,599 523.8,598.9 " />
      <line class="st1" x1="527.2" y1="598.6" x2="530.6" y2="598.2" />
      <line class="st0" x1="532.2" y1="598" x2="533.5" y2="597.9" />
      <path class="st0" d="M557,625.2l-0.8-1" />
      <path class="st24" d="M553.1,620.4c-7.5-8.5-16.8-15.2-27.3-19.6" />
      <path class="st0" d="M523.6,599.9l-1.2-0.5" />
      <path class="st0" d="M502.8,799.6" />
      <path class="st1" d="M500.3,805.9" />
      <line class="st1" x1="503.6" y1="810.4" x2="506.8" y2="811.6" />
      <path class="st0" d="M508.4,812.2" />
      <path class="st0" d="M575.4,780.7c-0.3,0.3-0.7,0.5-1.1,0.8" />
      <path
        class="st8"
        d="M202.8,1474.6c3.9,25.5,12.2,43.9,20.2,56.8c6.5,10.6,8,9.8,16.8,23.9c10,16,15.8,29.5,25.6,52.3
	c3.1,7.2,7.4,17.6,12.3,30.3"
      />
      <path
        class="st8"
        d="M571.5,782.8c-42.7,24.8-92.7,28.8-141.7,42.8c-13,3-25,7-37,12c-5,1-9,3-14,4c-29,9-52,27-73,48
	c-8,9-14,19-21,29c-14,22-21,48-13,73c5,12,11,23,22,30c12,7,24,12,33,24c5,8,4,17,0,25c-13,26-41,38-62,58c-44,42-57,102-61,160
	c-5,62,1,124-1,186c0,5-2,9-3.3,13.7"
      />
      <path class="st0" d="M500.6,808.1" />
      <line class="st0" x1="533.4" y1="758.9" x2="532.2" y2="759.5" />
      <line class="st1" x1="529.2" y1="761" x2="526.1" y2="762.5" />
      <polyline class="st0" points="524.6,763.2 523.4,763.8 524,765 " />
      <line class="st1" x1="525.5" y1="768" x2="527" y2="771" />
      <line class="st0" x1="527.7" y1="772.5" x2="528.3" y2="773.7" />
      <path class="st0" d="M575,780.1l-1.3-0.1" />
      <path class="st14" d="M568.6,779.4c-13-2-30.7-6.8-41.7-13.7" />
      <path class="st0" d="M524.7,764.3c-0.4-0.3-0.7-0.5-1.1-0.8" />
      <line class="st0" x1="388" y1="295.2" x2="389.3" y2="295.2" />
      <line class="st1" x1="392.7" y1="295.2" x2="396.1" y2="295.2" />
      <polyline class="st0" points="397.8,295.2 399.1,295.2 399.1,296.5 " />
      <line class="st1" x1="399.1" y1="299.9" x2="399.1" y2="303.3" />
      <line class="st0" x1="399.1" y1="305" x2="399.1" y2="306.3" />
      <path class="st0" d="M325.3,323.2l1.3,0.1" />
      <path class="st25" d="M332,323.5c21.2-0.1,48-9.7,63.8-24.9" />
      <path class="st0" d="M397.7,296.7c0.3-0.3,0.6-0.6,0.9-1" />
      <line class="st0" x1="298.4" y1="159.1" x2="298.4" y2="157.8" />
      <line class="st1" x1="298.2" y1="154.4" x2="298.1" y2="151" />
      <polyline class="st0" points="298.1,149.4 298,148 299.3,148 " />
      <line class="st1" x1="302.7" y1="147.9" x2="306.1" y2="147.7" />
      <line class="st0" x1="307.8" y1="147.7" x2="309.1" y2="147.6" />
      <path class="st0" d="M327.4,243.6c0-0.4,0-0.9,0.1-1.3" />
      <path class="st2" d="M327.5,237.1c-0.5-24.4-10.2-68.6-26.1-85.9" />
      <path class="st0" d="M299.6,149.4c-0.3-0.3-0.6-0.6-1-0.9" />
      <line class="st0" x1="436.6" y1="327.8" x2="437.6" y2="327" />
      <line class="st1" x1="440.3" y1="324.9" x2="443" y2="322.9" />
      <polyline class="st0" points="444.3,321.9 445.4,321.1 444.6,320 " />
      <line class="st1" x1="442.5" y1="317.3" x2="440.5" y2="314.6" />
      <line class="st0" x1="439.5" y1="313.3" x2="438.7" y2="312.3" />
      <path class="st0" d="M341.7,336.2l1.2-0.6" />
      <path class="st26" d="M347.9,333.5c22.7-9.2,66.4-18.1,93.4-12.9" />
      <path class="st0" d="M443.9,321.1l1.3,0.3" />
      <line class="st0" x1="462.9" y1="249.5" x2="464.1" y2="248.9" />
      <line class="st1" x1="467.2" y1="247.6" x2="470.3" y2="246.2" />
      <polyline class="st0" points="471.8,245.5 473.1,245 472.5,243.8 " />
      <line class="st1" x1="471.1" y1="240.7" x2="469.8" y2="237.6" />
      <line class="st0" x1="469.1" y1="236.1" x2="468.5" y2="234.9" />
      <path class="st0" d="M338.5,245.6l1.3-0.3" />
      <path class="st27" d="M345,244.3c27.9-5.2,96.8-11.3,124.1-0.7" />
      <path class="st0" d="M471.6,244.7c0.4,0.2,0.8,0.4,1.2,0.6" />
      <line class="st0" x1="515" y1="234.1" x2="515.9" y2="233.2" />
      <line class="st1" x1="518.3" y1="230.8" x2="520.6" y2="228.3" />
      <polyline class="st0" points="521.8,227.1 522.7,226.2 521.8,225.2 " />
      <line class="st1" x1="519.3" y1="222.9" x2="516.9" y2="220.6" />
      <line class="st0" x1="515.7" y1="219.4" x2="514.7" y2="218.5" />
      <path class="st0" d="M348.6,231.9c0.4-0.2,0.7-0.5,1.1-0.7" />
      <path class="st28" d="M354.6,229.1c31.8-11.6,129.3-8.9,163.1-4" />
      <path class="st0" d="M520.3,225.5l1.3,0.2" />
      <line class="st0" x1="243.1" y1="240.1" x2="242.2" y2="239.1" />
      <line class="st1" x1="239.9" y1="236.7" x2="237.5" y2="234.3" />
      <polyline class="st0" points="236.4,233.1 235.5,232.1 236.4,231.2 " />
      <line class="st1" x1="238.8" y1="228.9" x2="241.2" y2="226.5" />
      <line class="st0" x1="242.5" y1="225.4" x2="243.4" y2="224.4" />
      <path class="st0" d="M329.5,249.8l-1-0.8" />
      <path class="st3" d="M324,245.9c-25.2-15.8-65-15.4-84.4-13.8" />
      <path class="st0" d="M236.9,232.4l-1.3,0.1" />
      <line class="st0" x1="467.4" y1="624.1" x2="466.1" y2="624.3" />
      <line class="st1" x1="462.7" y1="624.8" x2="459.4" y2="625.4" />
      <polyline class="st0" points="457.7,625.6 456.4,625.8 456.2,624.5 " />
      <line class="st1" x1="455.7" y1="621.2" x2="455.2" y2="617.9" />
      <line class="st0" x1="454.9" y1="616.2" x2="454.7" y2="614.9" />
      <path class="st0" d="M504.3,576.6c-0.4,0.1-0.8,0.3-1.2,0.5" />
      <path class="st29" d="M498.2,579c-18.7,8.4-33.1,24-40.1,43.2" />
      <path class="st0" d="M457.2,624.7c-0.1,0.4-0.3,0.8-0.4,1.3" />
      <line class="st0" x1="344.1" y1="281.5" x2="345.4" y2="281.3" />
      <line class="st1" x1="348.7" y1="280.7" x2="352" y2="280.1" />
      <polyline class="st0" points="353.7,279.8 355,279.6 355.2,280.9 " />
      <line class="st1" x1="355.8" y1="284.2" x2="356.4" y2="287.5" />
      <line class="st0" x1="356.7" y1="289.2" x2="356.9" y2="290.5" />
      <path class="st0" d="M327.5,324.7l0.9-1" />
      <path class="st30" d="M331.8,320c9.5-11.1,17.4-24.5,21.6-36.8" />
      <path class="st0" d="M354.2,280.8c0.1-0.4,0.3-0.8,0.4-1.3" />
      <line class="st0" x1="95.2" y1="1329.4" x2="95.7" y2="1330.6" />
      <line class="st31" x1="97.4" y1="1335" x2="99.1" y2="1339.4" />
      <path class="st0" d="M100,1341.7l0.5,1.2c0.2-0.4,0.4-0.8,0.6-1.2" />
      <path class="st13" d="M103.4,1337C284.3,967.6,537,693.9,565.8,663.3" />
      <path class="st0" d="M567.6,661.3l0.9-1" />
      <line class="st0" x1="100.1" y1="1344.9" x2="101.2" y2="1344.2" />
      <line class="st32" x1="105.7" y1="1341.2" x2="110.1" y2="1338.3" />
      <line class="st0" x1="112.3" y1="1336.8" x2="113.4" y2="1336.1" />
      <line class="st0" x1="541.7" y1="380.4" x2="542.7" y2="379.6" />
      <line class="st33" x1="545.5" y1="377.1" x2="548.3" y2="374.7" />
      <polyline class="st0" points="549.7,373.5 550.7,372.6 551.6,373.6 " />
      <line class="st34" x1="554" y1="376.4" x2="556.5" y2="379.2" />
      <line class="st0" x1="557.7" y1="380.6" x2="558.5" y2="381.6" />
      <path class="st0" d="M498.8,549.8l0.7-1.1" />
      <path class="st23" d="M502.1,544.2c16.2-30.1,52.2-124.1,49.4-167.5" />
      <path class="st0" d="M551.3,374.1c0-0.4-0.1-0.9-0.1-1.3" />
      <line class="st0" x1="620" y1="362.5" x2="621.2" y2="362" />
      <line class="st33" x1="624.5" y1="360.4" x2="627.9" y2="358.7" />
      <polyline class="st0" points="629.5,357.9 630.7,357.4 631.3,358.6 " />
      <line class="st34" x1="632.9" y1="361.9" x2="634.5" y2="365.2" />
      <line class="st0" x1="635.3" y1="366.9" x2="635.9" y2="368.1" />
      <path class="st0" d="M507.2,547.9c0.3-0.3,0.6-0.6,1-0.9" />
      <path class="st35" d="M511.8,543.2c25.2-28.4,99.5-140.8,117.7-181.9" />
      <path class="st0" d="M630.6,358.9l0.5-1.2" />
      <line class="st0" x1="567.7" y1="554.7" x2="568.8" y2="555.5" />
      <line class="st33" x1="571.9" y1="557.6" x2="574.9" y2="559.7" />
      <polyline class="st0" points="576.4,560.8 577.5,561.5 576.7,562.6 " />
      <line class="st34" x1="574.6" y1="565.7" x2="572.5" y2="568.7" />
      <line class="st0" x1="571.4" y1="570.2" x2="570.7" y2="571.3" />
      <path class="st0" d="M503.6,563.9l1.2,0.5" />
      <path class="st36" d="M509.6,566.4c21,7.8,44.2,6.5,64.3-3.4" />
      <path class="st0" d="M576.1,561.8c0.4-0.2,0.8-0.4,1.2-0.6" />
      <line class="st0" x1="100.3" y1="1095.2" x2="101" y2="1096.4" />
      <line class="st37" x1="103.4" y1="1100.2" x2="105.9" y2="1104.1" />
      <path class="st0" d="M107,1106l0.7,1.1l0.4-1.3" />
      <path class="st38" d="M109.9,1100.9C197.3,849.5,542,688.5,581,670.9" />
      <path class="st0" d="M583.4,669.8l1.2-0.5" />
      <line class="st0" x1="110.1" y1="1106.7" x2="111.2" y2="1106" />
      <line class="st32" x1="115.6" y1="1103" x2="120.1" y2="1100.1" />
      <line class="st0" x1="122.3" y1="1098.6" x2="123.4" y2="1097.9" />
      <path
        class="st6"
        d="M596.2,617.4c0-10.3-8.4-18.7-18.7-18.7s-18.7,8.4-18.7,18.7c0,8.7,6,16.3,14.4,18.2l4.3,7.4l4.3-7.4
	C590.2,633.7,596.2,626.1,596.2,617.4z"
  style="cursor:pointer;  " @click="irAlPunto('PIEDRA')" 
      />
      <circle class="st39" cx="577.5" cy="617.4" r="15.2" style="cursor:pointer;  " @click="irAlPunto('PIEDRA')"   />
      <path
        class="st6"
        d="M623.2,632.7c0-10.3-8.4-18.7-18.7-18.7s-18.7,8.4-18.7,18.7c0,8.7,6,16.3,14.4,18.2l4.3,7.4l4.3-7.4
	C617.2,648.9,623.2,641.4,623.2,632.7z"
      />
      <circle class="st39" cx="604.5" cy="632.7" r="15.2" style="cursor:pointer; " @click="irAlPunto('RAMOS')" />
      <path
        class="st40"
        d="M610.9,654.3c0-16.6-13.4-30.1-30-30.1s-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.9,11.9l6.9-11.9
	C601.2,680.4,610.8,668.3,610.9,654.3z"
      />
      <path
        class="st41"
        d="M604.9,654.3c0,13.3-10.8,24.1-24.1,24.1s-24.1-10.8-24.1-24.1s10.8-24.1,24.1-24.1l0,0
	C594.1,630.2,604.9,641,604.9,654.3z"
   style="cursor:pointer;" @click="irAlPunto('TOM')" 
      />
      <path
        class="st6"
        d="M519.5,678.2c0-16.6-13.4-30.1-30-30.1s-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.9,11.9l6.9-11.9
	C509.8,704.3,519.5,692.2,519.5,678.2z"
      />
      <path
        class="st6"
        d="M622.7,775.4c0-16.6-13.4-30.1-30-30.1s-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.8,11.9l6.9-11.9
	C613.1,801.5,622.7,789.3,622.7,775.4z"
      />
      <path
        class="st6"
        d="M528.9,560.9c0-16.6-13.4-30.1-30-30.1c-16.6,0-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.9,11.9l6.9-11.9
	C519.3,587,528.9,574.9,528.9,560.9z"
      />
      <path
        class="st6"
        d="M358.4,225.9c0-16.6-13.4-30.1-30-30.1s-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.9,11.9l6.9-11.9
	C348.8,252,358.5,239.9,358.4,225.9z"
      />
      <path
        class="st6"
        d="M358.3,321.9c0-16.6-13.4-30.1-30-30.1s-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.9,11.9l6.9-11.9
	C348.6,348,358.2,335.9,358.3,321.9z"
      />
      <path
        class="st6"
        d="M409.7,505.1c0-16.6-13.4-30.1-30-30.1s-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.8,11.8l6.9-11.9
	C400,531.1,409.6,519,409.7,505.1z"
      />
      <path
        class="st6"
        d="M337.5,590.5c0-16.6-13.4-30.1-30-30.1s-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.9,11.9l6.9-11.9
	C327.9,616.5,337.5,604.4,337.5,590.5z"
      />
      <path
        class="st6"
        d="M241.3,489.7c0-16.6-13.4-30.1-30-30.1s-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.9,11.9l6.9-11.9
	C231.7,515.8,241.3,503.7,241.3,489.7z"
      />
      <circle class="st39" cx="379.4" cy="505.4" r="23.7" style="cursor:pointer; " @click="irAlPunto('CORDOBA')" />
      <circle class="st39" cx="211" cy="488.8" r="23.7" style="cursor:pointer;" @click="irAlPunto('SAN JUAN')"/>
      <circle class="st39" cx="328.2" cy="321.9" r="23.7"  style="cursor:pointer;" @click="irAlPunto('TUCUMAN')" />
      <circle class="st39" cx="328.4" cy="225.8" r="23.7" style="cursor:pointer" @click="irAlPunto('SALTA')" />
      <circle class="st39" cx="307.2" cy="590.1" r="23.7" style="cursor:pointer" @click="irAlPunto('SAN LUIS')"/>
      <ellipse
        transform="matrix(0.3162 -0.9487 0.9487 0.3162 -191.4844 856.7496)"
        class="st39"
        cx="498.6"
        cy="561.2"
        rx="23.7"
        ry="23.7"
        style="cursor:pointer; " @click="irAlPunto('ROSARIO')" 
      />
      <circle class="st39" cx="489.2" cy="678.6" r="23.7" style="cursor:pointer; " @click="irAlPunto('JUNIN')" />
      <circle class="st39" cx="592.5" cy="775.9" r="23.7" style="cursor:pointer; " @click="irAlPunto('MDQ')" />
      <path
        class="st6"
        d="M613.6,245.2c0-16.6-13.4-30.1-30-30.1s-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.9,11.9l6.9-11.9
	C604,271.3,613.6,259.2,613.6,245.2z"
  
      />
      <circle class="st39" cx="583.5" cy="245.1" r="23.7" style="cursor:pointer; " @click="irAlPunto('CHACO')" />
        <g class="punto" v-if="Marcador == 'TOM'">
      <path
        class="st42"
        d="M591.4,647.5c2.4-3,5.5-7.2,9.3-12.6c3.5-4.8,6.3-10.1,8.4-15.6c1.8-4.7,2.7-9.7,2.8-14.7
	c-0.6-16.8-14.8-30-31.6-29.4c-16,0.6-28.8,13.4-29.4,29.4c0.1,5,1,10,2.8,14.7c2.1,5.5,4.9,10.8,8.3,15.6c3.7,5.3,6.8,9.5,9.3,12.6
	s5.7,6.9,9.8,11.6h0.2c0.1,0.1,0.2,0.2,0.2,0.4l0.4-0.4C585.7,654.4,589,650.5,591.4,647.5z"
      />
      <path
        class="st43"
        d="M617.9,603.6c0,5.2-1,10.4-2.8,15.2c-2.1,5.6-4.8,10.9-8.3,15.8c-3.7,5.3-7,9.8-9.8,13.4s-6.5,7.8-10.8,12.6
	c-1.1,1.5-2.9,2.4-4.8,2.4H581c-1.9,0-3.7-0.9-4.8-2.4c-4.3-4.8-7.9-9-10.7-12.6s-6.1-8-9.8-13.4c-3.5-4.9-6.2-10.2-8.3-15.8
	c-1.8-4.9-2.7-10-2.8-15.2c-0.2-9.6,3.7-18.7,10.7-25.3c14.5-13.9,37.4-13.9,51.9,0C614.2,584.9,618.1,594.1,617.9,603.6z
	M591.4,645.5c2.4-3,5.5-7.1,9.3-12.3c3.5-4.7,6.3-9.8,8.4-15.2c1.8-4.6,2.7-9.4,2.8-14.3c0.1-8-3.2-15.6-9-21
	c-12-11.6-31.1-11.6-43.1,0c-5.8,5.4-9.1,13-9,21c0.1,4.9,1.1,9.8,2.9,14.3c2.1,5.4,4.9,10.5,8.3,15.2c3.7,5.2,6.8,9.3,9.3,12.3
	s5.8,6.8,9.8,11.3h0.2c0.1,0.1,0.2,0.2,0.2,0.4l0.4-0.4C585.8,652.2,589,648.4,591.4,645.5L591.4,645.5z"
      />
      <circle class="st44" cx="581.3" cy="603.8" r="18.3" />
      </g>
       <g class="punto" v-if="Marcador == 'MDQ'">
      <path
        class="st42"
        d="M596.7,771.7c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.3-19.2
	c-10.6,0-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9c1.6,2,3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C593.1,776,595.2,773.6,596.7,771.7z"
      />
      <path
        class="st43"
        d="M613.4,744c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4s-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8s-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C611,732.2,613.5,738,613.4,744zM596.7,770.4
	c1.5-1.9,3.5-4.4,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.7-13.2c-7.6-7.3-19.6-7.3-27.2,0
	c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7s3.6,4.3,6.2,7.2h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C593.1,774.7,595.2,772.3,596.7,770.4L596.7,770.4z"
      />
      <circle class="st44" cx="590.3" cy="744.1" r="11.5" />
      </g>
      <g class="punto" v-if="Marcador == 'JUNIN'">
      <path
        class="st42"
        d="M495.7,676.7c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.2-19.2
	s-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9c1.6,2,3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C492.1,681,494.2,678.6,495.7,676.7z"
      />
      <path
        class="st43"
        d="M512.4,649c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4c-1.8,2.3-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8s-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C510,637.2,512.5,643,512.4,649zM495.7,675.4
	c1.5-1.9,3.5-4.4,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.6-13.2c-7.6-7.3-19.6-7.3-27.2,0
	c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7s3.6,4.3,6.2,7.2h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C492.1,679.7,494.2,677.3,495.7,675.4L495.7,675.4z"
      />
      <circle class="st44" cx="489.3" cy="649.1" r="11.5" />
      </g>
         <g class="punto" v-if="Marcador == 'ROSARIO'">
      <path
        class="st42"
        d="M504.7,560.7c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.2-19.2
	s-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9c1.6,2,3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C501.1,565,503.2,562.6,504.7,560.7z"
      />
      <path
        class="st43"
        d="M521.4,533c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4c-1.8,2.3-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8s-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C519,521.2,521.5,527,521.4,533zM504.7,559.4
	c1.5-1.9,3.5-4.4,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.6-13.2c-7.6-7.3-19.6-7.3-27.2,0
	c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7s3.6,4.3,6.2,7.2h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C501.1,563.7,503.2,561.3,504.7,559.4L504.7,559.4z"
      />
      <circle class="st44" cx="498.3" cy="533.1" r="11.5" />
      </g>
      <g class="punto" v-if="Marcador == 'SAN LUIS'">
      <path
        class="st42"
        d="M313.7,588.7c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.2-19.2
	s-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9c1.6,2,3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C310.1,593,312.2,590.6,313.7,588.7z"
      />
      <path
        class="st43"
        d="M330.4,561c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4c-1.8,2.3-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8s-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C328,549.2,330.5,555,330.4,561zM313.7,587.4
	c1.5-1.9,3.5-4.4,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.6-13.2c-7.6-7.3-19.6-7.3-27.2,0
	c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7s3.6,4.3,6.2,7.2h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C310.1,591.7,312.2,589.3,313.7,587.4L313.7,587.4z"
      />
      <circle class="st44" cx="307.3" cy="561.1" r="11.5" />
      </g>
      <g class="punto" v-if="Marcador == 'CORDOBA'">
      <path
        class="st42"
        d="M384.7,503.7c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.2-19.2
	s-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9c1.6,2,3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C381.1,508,383.2,505.6,384.7,503.7z"
      />
      <path
        class="st43"
        d="M401.4,476c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4c-1.8,2.3-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8s-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C399,464.2,401.5,470,401.4,476zM384.7,502.4
	c1.5-1.9,3.5-4.4,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.6-13.2c-7.6-7.3-19.6-7.3-27.2,0
	c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7s3.6,4.3,6.2,7.2h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C381.1,506.7,383.2,504.3,384.7,502.4L384.7,502.4z"
      />
      <circle class="st44" cx="378.3" cy="476.1" r="11.5" />
      </g>
       <g class="punto" v-if="Marcador == 'SAN JUAN'">
      <path
        class="st42"
        d="M217.7,484.7c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.2-19.2
	c-10.6,0-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9c1.6,2,3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C214.1,489,216.2,486.6,217.7,484.7z"
      />
      <path
        class="st43"
        d="M234.4,457c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4s-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8s-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C232,445.2,234.5,451,234.4,457zM217.7,483.4
	c1.5-1.9,3.5-4.4,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.6-13.2c-7.6-7.3-19.6-7.3-27.2,0
	c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7c1.6,1.9,3.6,4.3,6.2,7.2h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C214.1,487.7,216.2,485.3,217.7,483.4L217.7,483.4z"
      />
      <circle class="st44" cx="211.3" cy="457.1" r="11.5" />
      </g>
      <g class="punto" v-if="Marcador == 'TUCUMAN'">
      <path
        class="st42"
        d="M334.7,320.7c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.2-19.2
	s-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9c1.6,2,3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C331.1,325,333.2,322.6,334.7,320.7z"
      />
      <path 
        class="st43"
        d="M351.4,293c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4c-1.8,2.3-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8s-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C349,281.2,351.5,287,351.4,293zM334.7,319.4
	c1.5-1.9,3.5-4.5,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.6-13.2c-7.6-7.3-19.6-7.3-27.2,0
	c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7c1.6,1.9,3.6,4.3,6.2,7.1h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C331.1,323.7,333.2,321.3,334.7,319.4L334.7,319.4z"
      />
      <circle class="st44" cx="328.3" cy="293.1" r="11.5" />
      </g>
	<g class="punto" v-if="Marcador == 'SALTA'">
      <path
        class="st42"
        d="M334.7,222.7c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.2-19.2
			s-19.2,8.6-19.2,19.2l0,0c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9s3.6,4.4,6.2,7.3h0.1
			c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C331.1,227,333.2,224.6,334.7,222.7z"
      />
      <path
        class="st43"
        d="M351.4,195c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4c-1.8,2.3-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8s-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C349,183.2,351.5,189,351.4,195zM334.7,221.4
	c1.5-1.9,3.5-4.5,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.6-13.2c-7.6-7.3-19.6-7.3-27.2,0
	c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7c1.6,1.9,3.6,4.3,6.2,7.1h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C331.1,225.7,333.2,223.3,334.7,221.4L334.7,221.4z"
      />
      <circle class="st44" cx="328.3" cy="195.1" r="11.5" />
	</g>
	<g class="punto" v-if="Marcador == 'CHACO'">
      <path
        class="st42"
        d="M589.7,241.7c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.3-19.2
	c-10.6,0-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9s3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C586.1,246,588.2,243.6,589.7,241.7z"
      />
      <path
        class="st43"
        d="M606.4,214c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4s-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8s-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C604,202.2,606.5,208,606.4,214zM589.7,240.4
	c1.5-1.9,3.5-4.5,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.7-13.2c-7.6-7.3-19.6-7.3-27.2,0
	c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7c1.6,1.9,3.6,4.3,6.2,7.1h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C586.1,244.7,588.2,242.3,589.7,240.4L589.7,240.4z"
      />
      <circle class="st44" cx="583.3" cy="214.1" r="11.5" />
	</g>
	<g class="punto" v-if="Marcador == 'PIEDRA'">
      <path
        class="st42"
        d="M583.7,608.7c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.3-19.2
	c-10.6,0-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9c1.6,2,3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C580.1,613,582.2,610.6,583.7,608.7z"
      />
      <path
        class="st43"
        d="M600.4,581c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4s-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8s-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C598,569.2,600.5,575,600.4,581zM583.7,607.4
	c1.5-1.9,3.5-4.4,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.7-13.2c-7.6-7.3-19.6-7.3-27.2,0
	c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7s3.6,4.3,6.2,7.2h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C580.1,611.7,582.2,609.3,583.7,607.4L583.7,607.4z"
      />
      <circle class="st44" cx="577.3" cy="581.1" r="11.5"  />
	</g>
	<g class="punto" v-if="Marcador == 'RAMOS'">
      <path
        class="st42"
        d="M615.7,621.7c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.3-19.2
	c-10.6,0-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9c1.6,2,3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C612.1,626,614.2,623.6,615.7,621.7z"
      />
      <path
        class="st43"
        d="M632.4,594c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4s-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8s-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C630,582.2,632.5,588,632.4,594zM615.7,620.4
	c1.5-1.9,3.5-4.4,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.7-13.2c-7.6-7.3-19.6-7.3-27.2,0
	c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7s3.6,4.3,6.2,7.2h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C612.1,624.7,614.2,622.3,615.7,620.4L615.7,620.4z"
      />
      <circle class="st44" cx="609.3" cy="594.1" r="11.5" />
	</g>  
      <path
        class="st45"
        d="M491.9,805.4c0-16.6-13.4-30.1-30-30.1s-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.9,11.9l6.9-11.9
	C482.3,831.5,491.9,819.4,491.9,805.4z"
      />
      <circle class="st46" cx="461.8" cy="805.4" r="23.7" style="cursor:pointer; " @click="irAlPunto('BAHIA')"  />
	<g class="punto" v-if="Marcador == 'BAHIA'">
      <path
        class="st47"
        d="M468,801.9c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.3-19.2
	c-10.6,0-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9c1.6,2,3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C464.4,806.3,466.5,803.8,468,801.9z"
      />
      <path
        class="st48"
        d="M484.7,774.3c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4s-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8s-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C482.3,762.5,484.8,768.2,484.7,774.3zM468,800.6
	c1.5-1.9,3.5-4.5,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.7-13.2c-7.6-7.3-19.6-7.3-27.2,0
	c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7c1.6,1.9,3.6,4.3,6.2,7.2h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C464.4,804.9,466.5,802.5,468,800.6L468,800.6z"
      />
      <circle class="st49" cx="461.6" cy="774.3" r="11.5"  />
	</g> 
      <path
        class="st45"
        d="M176.9,586.2c0-16.6-13.4-30.1-30-30.1s-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.9,11.9l6.9-11.9
	C167.3,612.3,176.9,600.1,176.9,586.2z"
  
  
      />
      <ellipse
        transform="matrix(0.9929 -0.1189 0.1189 0.9929 -68.6456 21.6119)"
        class="st46"
        cx="146.8"
        cy="586.1"
        rx="23.7"
        ry="23.7"
         style="cursor:pointer; " @click="irAlPunto('MENDOZA')" 
      />
	<g class="punto" v-if="Marcador == 'MENDOZA'">
      <path
        class="st47"
        d="M153,582.6c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.3-19.2
	c-10.6,0-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9c1.6,2,3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C149.4,587,151.5,584.6,153,582.6z"
      />
      <path
        class="st48"
        d="M169.7,555c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4c-1.8,2.3-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8s-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C167.3,543.2,169.8,549,169.7,555zM153,581.4
	c1.5-1.9,3.5-4.5,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.7-13.2c-7.6-7.3-19.6-7.3-27.2,0
	c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7c1.6,1.9,3.6,4.3,6.2,7.2h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C149.4,585.7,151.5,583.3,153,581.4L153,581.4z"
      />
      <circle class="st49" cx="146.6" cy="555.1" r="11.5" />
	</g>   
      <path
        class="st45"
        d="M200.7,814.4c0-16.6-13.4-30.1-30-30.1s-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.9,11.9l6.9-11.9
	C191.1,840.4,200.7,828.3,200.7,814.4z"
      />
      <circle class="st46" cx="170.6" cy="814.3" r="23.7" style="cursor:pointer; " @click="irAlPunto('NEUQUEN')"  />
	<g class="punto" v-if="Marcador == 'NEUQUEN'">
      <path
        class="st47"
        d="M176.8,810.8c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.3-19.2
	c-10.6,0-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9c1.6,2,3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C173.2,815.2,175.3,812.8,176.8,810.8z"
      />
      <path
        class="st48"
        d="M193.5,783.2c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4s-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8s-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C191.2,771.4,193.6,777.2,193.5,783.2zM176.8,809.6
	c1.5-1.9,3.5-4.5,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.7-13.2c-7.6-7.3-19.6-7.3-27.2,0
	c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7c1.6,1.9,3.6,4.3,6.2,7.2h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C173.2,813.8,175.3,811.4,176.8,809.6L176.8,809.6z"
      />
      <circle class="st49" cx="170.4" cy="783.3" r="11.5" />
	</g>    
      <path
        class="st6"
        d="M357.1,1052.2c0-16.6-13.4-30.1-30-30.1s-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.9,11.9l6.9-11.9
	C347.5,1078.3,357.1,1066.2,357.1,1052.2z"
      />
      <circle class="st39" cx="327" cy="1052.1" r="23.7" style="cursor:pointer; " @click="irAlPunto('MADRYN')"  />
	<g class="punto" v-if="Marcador == 'MADRYN'">
      <path
        class="st42"
        d="M333.2,1048.7c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.3-19.2
	c-10.6,0-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9c1.5,1.9,3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C329.6,1053,331.7,1050.6,333.2,1048.7z"
      />
      <path
        class="st43"
        d="M349.9,1021c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4s-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8s-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C347.5,1009.2,350,1015,349.9,1021zM333.2,1047.4
	c1.5-1.9,3.5-4.5,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.7-13.2c-7.6-7.3-19.6-7.3-27.2,0
	c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7c1.6,1.9,3.6,4.3,6.2,7.1h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C329.6,1051.7,331.7,1049.3,333.2,1047.4z"
      />
      <circle class="st44" cx="326.8" cy="1021.1" r="11.5" />
	</g>  
      <path
        class="st6"
        d="M765,280.5c0-16.6-13.4-30.1-30-30.1s-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.9,11.9l6.9-11.9
	C755.4,306.6,765,294.5,765,280.5z"
      />
      <circle class="st39" cx="734.9" cy="280.4" r="23.7" style="cursor:pointer; " @click="irAlPunto('POSADAS')"  />
      <g class="punto" v-if="Marcador == 'POSADAS'">
      <path
        class="st42"
        d="M741.1,277c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.3-19.2
	c-10.6,0-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9s3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C737.5,281.3,739.6,278.9,741.1,277z"
      />
      <path
        class="st43"
        d="M757.8,249.3c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4s-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8c-1.8-2.3-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C755.4,237.5,757.9,243.3,757.8,249.3zM741.1,275.7
	c1.5-1.9,3.5-4.5,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.7-13.2c-7.6-7.3-19.6-7.3-27.2,0
	c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7c1.6,1.9,3.6,4.3,6.2,7.1h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C737.5,280,739.6,277.6,741.1,275.7L741.1,275.7z"
      />
      <circle class="st44" cx="734.7" cy="249.4" r="11.5" />
      </g>
      <path
        class="st6"
        d="M270,1161.1c0-16.6-13.4-30.1-30-30.1s-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.9,11.9l6.9-11.9
	C260.4,1187.2,270,1175.1,270,1161.1z"
      />
      <circle class="st39" cx="239.9" cy="1161" r="23.7" style="cursor:pointer; " @click="irAlPunto('COMODORO')" />
	<g class="punto" v-if="Marcador == 'COMODORO'">
      <path
        class="st42"
        d="M246.1,1157.6c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.3-19.2
	c-10.6,0-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9c1.5,1.9,3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C242.5,1161.9,244.6,1159.5,246.1,1157.6z"
      />
      <path
        class="st43"
        d="M262.8,1129.9c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4c-1.8,2.3-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8s-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C260.4,1118.1,262.9,1123.9,262.8,1129.9z
	M246.1,1156.3c1.5-1.9,3.5-4.5,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.7-13.2
	c-7.6-7.3-19.6-7.3-27.2,0c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7
	c1.6,1.9,3.6,4.3,6.2,7.1h0.1c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C242.5,1160.6,244.6,1158.2,246.1,1156.3L246.1,1156.3z"
      />
      <circle class="st44" cx="239.7" cy="1130" r="11.5" />
	</g>  
      <path
        class="st6"
        d="M230.9,1455.9c0-16.6-13.4-30.1-30-30.1s-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.9,11.9l6.9-11.9
	C221.4,1482,230.9,1469.9,230.9,1455.9z"
      />
      <circle class="st39" cx="200.9" cy="1455.8" r="23.7" style="cursor:pointer; " @click="irAlPunto('GALLEGOS')" />
	<g class="punto" v-if="Marcador == 'GALLEGOS'">
      <path
        class="st42"
        d="M207.1,1452.4c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.3-19.2
	c-10.6,0-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9s3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C203.4,1456.7,205.6,1454.3,207.1,1452.4z"
      />
      <path
        class="st43"
        d="M223.8,1424.7c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4c-1.8,2.3-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8c-1.8-2.3-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C221.4,1412.9,223.9,1418.7,223.8,1424.7z
	M207.1,1451.1c1.5-1.9,3.5-4.5,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.7-13.2
	c-7.6-7.3-19.6-7.3-27.2,0c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7
	c1.6,1.9,3.6,4.3,6.2,7.1h0.1c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C203.4,1455.4,205.6,1453,207.1,1451.1L207.1,1451.1z"
      />
      <circle class="st44" cx="200.6" cy="1424.8" r="11.5" />
	</g>  
      <path
        class="st6"
        d="M305.9,1598.4c0-16.6-13.4-30.1-30-30.1s-30.1,13.4-30.1,30c0,14,9.6,26.1,23.2,29.3l6.9,11.9l6.9-11.9
	C296.3,1624.5,305.9,1612.4,305.9,1598.4z"
      />
      <circle class="st39" cx="275.8" cy="1598.3" r="23.7" style="cursor:pointer; " @click="irAlPunto('TDF')" />
	<g class="punto" v-if="Marcador == 'TDF'">
      <path
        class="st42"
        d="M282,1594.9c1.5-1.9,3.5-4.6,5.8-7.9c2.2-3,4-6.4,5.3-9.9c1.1-3,1.7-6.1,1.7-9.3c0-10.6-8.6-19.2-19.3-19.2
	c-10.6,0-19.2,8.6-19.2,19.2c0,3.2,0.6,6.3,1.8,9.3c1.3,3.5,3.1,6.8,5.2,9.9c2.3,3.3,4.3,6,5.8,7.9c1.5,1.9,3.6,4.4,6.2,7.3h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C278.4,1599.2,280.5,1596.8,282,1594.9z"
      />
      <path
        class="st43"
        d="M298.7,1567.2c0,3.3-0.6,6.5-1.7,9.6c-1.3,3.6-3.1,6.9-5.2,10c-2.3,3.3-4.4,6.1-6.2,8.4c-1.8,2.3-4.1,5-6.8,8
	c-0.7,1-1.8,1.5-3,1.5h-0.2c-1.2,0-2.3-0.6-3-1.5c-2.7-3-5-5.7-6.8-8c-1.8-2.3-3.9-5.1-6.2-8.4c-2.2-3.1-3.9-6.4-5.2-10
	c-1.1-3.1-1.7-6.3-1.7-9.6c-0.1-6,2.3-11.8,6.7-15.9c9.1-8.8,23.6-8.8,32.7,0C296.3,1555.4,298.8,1561.2,298.7,1567.2zM282,1593.6
	c1.5-1.9,3.5-4.5,5.8-7.7c2.2-2.9,4-6.2,5.3-9.6c1.1-2.9,1.7-5.9,1.7-9c0.1-5-2-9.8-5.7-13.2c-7.6-7.3-19.6-7.3-27.2,0
	c-3.7,3.4-5.7,8.2-5.7,13.2c0,3.1,0.6,6.1,1.8,9c1.3,3.4,3.1,6.6,5.2,9.6c2.3,3.3,4.3,5.9,5.8,7.7c1.6,1.9,3.6,4.3,6.2,7.1h0.1
	c0.1,0.1,0.1,0.1,0.1,0.2l0.2-0.2C278.4,1597.9,280.5,1595.5,282,1593.6L282,1593.6z"
      />
      <circle class="st44" cx="275.6" cy="1567.3" r="11.5" />
	</g>    
      <path class="st13" d="M462.1,821.9" />
      <path class="st39" d="M1167.9,1111.3" />
    </svg>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
     Marcador: "TORTUGUITA2S",
      activeIndex: undefined,
      puntoActivo:  undefined,
    };
  },
  created() {
    EventBus.$on("ir-al-punto", (puntos) => {
      // this.marcador ='TOM';
      puntos.map((punto, index) => {
        if (punto.activo) {
          this.Marcador = punto.boton;
          this.activeIndex = index;
        }
      });
    });
  },
  
 methods: {
    irAlPunto(index) {

      let puntos = this.$t("mapa.puntos").map((punto, i) => {
      
        if (punto.boton == index) {
          punto.activo = true;
          this.puntoActivo = punto;
          // this.Marcador = punto.boton;
        } else {
          punto.activo = false;
        }

        return punto;
      });
      setTimeout(() => {
        //  console.log(puntos);
        EventBus.$emit("ir-al-punto", puntos);
        EventBus.$emit("ir-al-punto-activo", this.puntoActivo);
      }, 100);
    },
  },
};
</script>

<style type="text/css">
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #mapaSvg {
    width: 600px;
    height: 900px;
  }

  @media screen and (max-width: 1440px) {
    #mapaSvg {
      width: 600px;
      height: 900px;
    }
  }
}

.st0 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
}

.st1 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 1.69, 3.37;
}

.st2 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.6, 5.2;
}

.st3 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.71, 5.42;
}

.st4 {
  opacity: 0.7;
}

.st5 {
  fill: #a0a0a0;
}

.st6 {
  fill: #f4a480;
}

.st7 {
  fill: #5e76b5;
}

.st8 {
  fill: none;
  stroke: #094068;
  stroke-width: 5.28;
  stroke-miterlimit: 10;
}

.st9 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.56, 5.13;
}

.st10 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.68, 5.36;
}

.st11 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.67, 5.34;
}

.st12 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.76, 5.52;
}

.st13 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.64, 5.27;
}

.st14 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.59, 5.19;
}

.st15 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.62, 5.23;
}

.st16 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.61, 5.22;
}

.st17 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.58, 5.17;
}

.st18 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.71, 5.41;
}

.st19 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.64, 5.28;
}

.st20 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.69, 5.37;
}

.st21 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.72, 5.43;
}

.st22 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.53, 5.06;
}

.st23 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.59, 5.17;
}

.st24 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.42, 4.84;
}

.st25 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.69, 5.38;
}

.st26 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.72, 5.44;
}

.st27 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.66, 5.32;
}

.st28 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.64, 5.29;
}

.st29 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.63, 5.26;
}

.st30 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.53, 5.05;
}

.st31 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.38, 4.75;
}

.st32 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.66, 5.33;
}

.st33 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 1.85, 3.71;
}

.st34 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 1.86, 3.71;
}

.st35 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.61, 5.23;
}

.st36 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.55, 5.09;
}

.st37 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.27, 4.54;
}

.st38 {
  fill: none;
  stroke: #e5590b;
  stroke-width: 2.38;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.63, 5.27;
}

.st39 {
  fill: #fac13d;
}

.st40 {
  fill: #e5590b;
  stroke: #fac13d;
  stroke-miterlimit: 10;
}

.st41 {
  fill: #e5590b;
  stroke: #fac13d;
  stroke-width: 3;
  stroke-miterlimit: 10;
}

.st42 {
  fill: #f9c03c;
}

.st43 {
  fill: #094068;
}

.st44 {
  fill: #ffffff;
  stroke: #094068;
  stroke-width: 2.82;
  stroke-miterlimit: 10;
}

.st45 {
  fill: #f4a380;
}

.st46 {
  fill: #f8bf3c;
}

.st47 {
  fill: #f8be3b;
}

.st48 {
  fill: #064067;
}

.st49 {
  fill: #ffffff;
  stroke: #064067;
  stroke-width: 2.82;
  stroke-miterlimit: 10;
}
</style>