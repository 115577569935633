<template>
  <div id="app">
    <log-menu-header />
    <router-view />
    
  </div>
</template>

<script>
import LogMenuHeader from './components/logMenuHeader.vue'

export default {
  name: 'app',
  components: {
    LogMenuHeader
  }
}
</script>

<style lang="scss">
@import "@/styles/log-in.scss";
#app {
  width: 100%;
  height: 100%;
 
}
body{
    padding:0px !important;
    width: 100%;
}
.grecaptcha-badge {
    display: none !important;
}
</style>
