<template>
  <div class="nosotros" >
     <div class="container" >
         <div class="nosotros-cabecera">
             <div class="nosotros-rectangulo-naranja">
                <h3>{{$t('nosotros.titulo')}}</h3> 
             </div>
             <div class="nosotros-linea"></div>
         </div>
         <h1 class="nosotros-intro">{{$t('nosotros.intro')}}</h1>
         <el-row >
             <el-col :md="{span:4,offset:2}" :sm="{span:4,offset:1}" :xs="12">
                 <div class="nosotros-caracteristica "  >
                      <img src="@/assets/SVG/m2.svg" alt="nosotros" class="Logística Farmacéutica">
                      <h2>
                          <strong>
+160.000 m<span class='supidc'>2</span>
                          </strong>
                          
                      </h2>
                      <div class="nosotros-item" v-for="item in $t('nosotros.m2.descripcion')" :key="item">
                          {{item}}
                      </div>
                 </div>
             </el-col>
              <el-col :md="4" :sm="4" :xs="12">
                 <div class="nosotros-caracteristica " >
                      <img src="@/assets/SVG/climatizacion.svg" alt="Especialización farmacéutica" class="" >
                      <h2>{{$t('nosotros.climatizacion.titulo')}}</h2>
                      <div class="nosotros-item" v-for="item in $t('nosotros.climatizacion.descripcion')" :key="item" v-html="item">
                          <!-- {{item}} -->
                      </div>
                 </div>
             </el-col>
              <el-col :md="5" :sm="5" :xs="12">
                 <div class="nosotros-caracteristica " >
                      <img src="@/assets/SVG/acondicionamiento.svg" alt="Especialización farmacéutica" class="" >
                      <h2>{{$t('nosotros.acondicionamiento.titulo')}}</h2>
                      <div class="nosotros-item" v-for="item in $t('nosotros.acondicionamiento.descripcion')" :key="item">
                          {{item}}
                      </div>
                 </div>
             </el-col>
              <el-col :md="4" :sm="4" :xs="12">
                 <div class="nosotros-caracteristica " >
                      <img src="@/assets/SVG/tecnologia.svg" alt="Especialización farmacéutica" class="" >
                      <h2>{{$t('nosotros.tecnologia.titulo')}}</h2>
                      <div class="nosotros-item" v-for="item in $t('nosotros.tecnologia.descripcion')" :key="item">
                          {{item}}
                      </div>
                 </div>
             </el-col>
                <el-col :md="4" :sm="4" :xs="12">
                 <div class="nosotros-caracteristica " >
                      <img src="@/assets/SVG/soluciones.svg" alt="Especialización farmacéutica" class="" >
                      <h2>{{$t('nosotros.soluciones.titulo')}}</h2>
                      <div class="nosotros-item" v-for="item in $t('nosotros.soluciones.descripcion')" :key="item">
                          {{item}}
                      </div>
                 </div>
             </el-col>
         </el-row>
     </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.supidc { 
    vertical-align:super;            /*superindice*/ 
    font-size:12px;
} 
.nosotros{
    height: 100vh;
     
}
.nosotros-cabecera{
  margin-bottom: 100px;
}
.nosotros-caracteristica br {
    display: none;
}
.nosotros-rectangulo-naranja{
    background: $naranja;
    height: 100px;
    width: 28%;
     -webkit-transform:    skewX(-15deg);
    transform:    skewX(-15deg);
    display: inline-block;

}
.nosotros-rectangulo-naranja h3{
  color:white;
  text-align: center;
  font-size: 30px;
}
.nosotros-linea{
  margin: 0 auto;
  display: block;  
  width: 10%;
  border-bottom: 3px solid $naranja;
      margin-top:-5px;
}
.nosotros-intro{
   
    font-weight: 100;
    color: $azul;
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    font-size: 30px;
    text-align: justify;
}
.nosotros-caracteristica{
    margin-top: 100px;
    transition: all 0.3s;
    cursor: pointer;
    padding: 0px;
}

.nosotros-caracteristica img:hover{
    transform: scale(1.2);
}
.nosotros-caracteristica img{
    // width: 90%;
    height: 90px;
    margin:0 auto;
    display: block;
    transition: all 0.2s;
}
.nosotros-caracteristica h2{
    font-size: 20px;
    color: $naranja;
    text-align: center;
    margin-bottom: 0;
    font-weight: 900;
    padding: 0px 20px;
}
.nosotros-item{
    color: $azul;
    font-size: 14px;
    text-align: center;
    padding: 0px 10px;
}
.slide-in-elliptic-top-fwd {
	-webkit-animation: slide-in-elliptic-top-fwd 1.7s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	animation: slide-in-elliptic-top-fwd 1.7s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}
 
@-webkit-keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
            transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
            transform-origin: 50% 1400px;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
            transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
            transform-origin: 50% 1400px;
    opacity: 1;
  }
}
@media screen and (max-width: 1440px) {
        .nosotros-caracteristica br {
    display: block;
}
    .nosotros-cabecera{
  margin-bottom: 80px;
}
    .nosotros-intro{
   
   
    padding: 20px;
    font-size: 24px;
  
}
  .nosotros-item{
    font-size: 14px;
}  

.nosotros-caracteristica{
    margin-top: 80px;
    // margin-bottom: 80px;
    
}
.nosotros-caracteristica h2{
    font-size: 18px;
}
.nosotros-caracteristica img{
    // width: 90%;
    height: 90px;

}
.nosotros-caracteristica{
    margin-top: 90px;
}
.nosotros{
    height: auto;
}
}
@media screen and (max-width: 1024px) {
    .nosotros-caracteristica br {
    display: block;
}
    .nosotros-cabecera{
  margin-bottom: 60px;
}
    .nosotros-intro{
   
   
    padding: 10px;
    font-size: 20px;
  
}
  .nosotros-item{
    font-size: 12px;
}  
.nosotros-caracteristica h2{
    font-size: 16px;
}
.nosotros-caracteristica img{
    // width: 90%;
    height: 90px;

}
.nosotros-caracteristica{
    margin-top: 70px;
}
}

@media screen and (max-width: 768px) {
    .nosotros{
    height: 100%;
     
}

.nosotros-rectangulo-naranja{
   
    height: 80px;
    width: 80%;
   

}
.nosotros-rectangulo-naranja h3{
  color:white;
  text-align: center;
  font-size: 25px;
}
    .nosotros-cabecera{
  margin-bottom: 60px;
}
    .nosotros-intro{
   
   padding: 10px;
    font-size: 16px;
    width: 90%;
  
}
  .nosotros-item{
    font-size: 12px;
}  
.nosotros-caracteristica h2{
    font-size: 16px;
}
.nosotros-caracteristica img{
    // width: 90%;
    height: 90px;

}
.nosotros-caracteristica{
        margin-top: 15px;
    border: 1px solid rgba(250, 192, 61, 0.1);
    height: 240px;
    padding: 10px;
}
}

@media screen and (max-width:1025px) {
    .nosotros {
    height: auto;
}
    
}
</style>