import { render, staticRenderFns } from "./SvgTransition.vue?vue&type=template&id=2299332b"
import script from "./SvgTransition.vue?vue&type=script&lang=js"
export * from "./SvgTransition.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports