<template>
    <div class="log-parallax">
    <div class="parallax hidden-xs-only"></div> 
     <img src="@/assets/fotos/parallax.jpg" style="width:100%" class=" hidden-sm-and-up" alt="Operaciones Logísticas"> 


    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.log-parallax{
    margin-top: 720px;

    position: relative;
    //  height: 100vh; 
    // height: 750px;
    //   -webkit-perspective: 1;
    //       perspective: 1;
}
@media screen and (max-width:1440px) {
    .log-parallax{
    margin-top: 750px;
}
}
@media	only screen and (min-resolution: 120dpi) {

    .log-parallax{
    margin-top: 780px;
}


}
@media (min-width:768px) and (max-height:670px) {
    .log-parallax{
    margin-top: 900px;
}
}
@media screen and (max-width:768px) {
    .log-parallax{
    margin-top: 0vh;
    // height: 750px;
}
}
.parallax { 
  /* The image used */
  background-image: url("~@/assets/fotos/parallax.jpg");

  /* Set a specific height */
  height: 60vh; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

</style>